
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Editor } from '@toast-ui/vue-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import fontSize from "tui-editor-plugin-font-size";
import "tui-editor-plugin-font-size/dist/tui-editor-plugin-font-size.css";

@Component({
  components: {
    Editor,
  },
})
export default class RichEditor extends Vue {
    @Prop(String) public text!: string;
    private key = 0;
    private innitialValue = '';
    private editorOptions = {
        minHeight: '0',
        language: 'en-US',
        useCommandShortcut: false,
        usageStatistics: false,
        hideModeSwitch: true,
        toolbarItems: [
          ['bold', 'italic', 'strike', 'link'],
        ],
        plugins: [colorSyntax, fontSize],
        linkAttributes: {
          target: '_blank'
        }
    };
    private created() {
      this.innitialValue = this.text;
    }
    private onChange() {
        // @ts-ignore
        this.$emit('update:text', this.$refs.toastuiEditor.invoke('getHTML'));
    }
    @Watch('text')
    private watchText() {
      // reload component when user clicks "Discard changes"
      if (this.innitialValue === this.text) {
          this.key += 1;
      }
    }
}
