var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.visible)?_c('div',{class:'toolbar-circle float-'+_vm.animation,style:({
        left: _vm.positionX+'%',
        bottom: _vm.positionY+'%',
        width: _vm.size+'px',
        height: _vm.size+'px',
        backgroundColor: _vm.colors[_vm.color],
    })}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }