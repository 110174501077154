import { Vue } from 'vue-property-decorator';
import i18n from '../plugins/i18n';
import { Helpers } from '../helpers/helpers';
import { ErrorHandler } from '../helpers/errorHandler';

export abstract class BaseClass<T> extends Vue {
    private cacheObject?: T;
    public helpers = new Helpers();
    public errorHandler = new  ErrorHandler();
    public i18n = i18n;

    public loading: boolean = true;
    public actionLoading: boolean = false;
    public hasChanged: boolean = false;
    public showCancelChangesDialog: boolean = false;
    public cancelChangesDialogResponse: boolean = false;
    public newPath: any = '';

    public setCacheObject(objectForCache: T) {
        this.cacheObject = objectForCache;
    }

    public getCacheObject() {
        return this.cacheObject;
    }

    public hasChanges(value: T): void {
        if (this.helpers.isEqual(value, this.cacheObject)) {
            this.hasChanged = false;
        } else {
            this.hasChanged = true;
        }
    }

    get disableSaveAction() {
        return !this.hasChanged;
    }

    get disableCancelAction() {
        return this.actionLoading || !this.hasChanged;
    }
}