
import {
    Component,
    Vue,
    Prop,
} from 'vue-property-decorator';
import {
    Bar,
} from 'vue-chartjs';
interface IStats {
    createdDate: string;
    collect: number;
    spin: number;
    show: number;
    reject: number;
    deviceType: string;
}
interface IStatsPie {
    desktop: number;
    mobile: number;
    tablet: number;
}
// @ts-ignore
@Component({
    // @ts-ignore
    extends: Bar, // this is important to add the functionality to your component
})
export default class PieChart extends Vue<Bar> {
    @Prop(Object) public stats!: IStatsPie;
    @Prop(String) public title!: string;
    private devices: string[] = ['Desktop', 'Mobile', 'Tablet'];
    private mounted() {
        if (this.stats.desktop > 0 || this.stats.mobile > 0 || this.stats.tablet > 0 ) {
            this.renderChart({
                // scaleStartValue: 0,
                labels: this.devices,
                datasets: [{
                    data: [
                        this.stats.desktop,
                        this.stats.mobile,
                        this.stats.tablet,
                    ],
                    backgroundColor: [
                        '#4465C1',
                        '#2DA6A6',
                        '#D97791',
                    ],
                    label: 'Dataset 1',
                }],
            },
            {
                responsive: true,
                maintainAspectRatio: false,
                title: {
                    display: true,
                    text: this.title,
                },
                legend: {
                    display: false
                },
                tooltips: {
                    callbacks: {
                        label: (tooltipItem, data) => {
                            // @ts-ignore
                            return data[`labels`][tooltipItem[`index`]] + ': ' + data[`datasets`][0][`data`][tooltipItem[`index`]] + '%';
                        }
                    }
                },
                scales: {
                    yAxes: [
                    {
                        ticks: {
                        min: 0,
                        max: 100,
                            callback: (value) => {
                                return value + '%';
                            },
                        },
                    }]
                 },
            });
        }
    }
}
