
import Vue from 'vue';
import Component from 'vue-class-component';
import { ScheduleType, AppType } from '../helpers/enums';
import { ErrorHandler } from '../helpers/errorHandler';
import { ICampaign } from '../models/Campaign';
import { Helpers } from '../helpers/helpers';
import Campaign from './Campaign.vue';
import CampaignCard from '@/components/CampaignCard.vue';
@Component({
    components: {
        CampaignCard
    }
})
export default class CampaignList extends Vue {
    private loading: boolean = false;
    private actionLoading: boolean = false;
    private actionNewLoading: boolean = false;
    private dialog: boolean = false;
    private showConfirmDialog: boolean = false;
    private campaignList: ICampaign[] = [];
    private helpers = new Helpers();
    private statsFilter: string = '1';
    private deleteId: string = '';
    private campaign: ICampaign = {
        id: '',
        name: '',
        active: false,
        scheduleType: ScheduleType.Ongoing,
        allowEmailDuplicates: false,
        allowIpDuplicates: false,
        integrationsEnabled: false,
        injectCoupon: false,
        timeZone: 'Europe/London'
    };

    private appTypes: string[] = [];

    private async created() {       

        // send crisp event once per session
        if (!sessionStorage.getItem('openDashboard')) {
            sessionStorage.setItem('openDashboard', 'true');
            this.$crispUserEvent('user:openDashboard');
        }

        for (const item in AppType) {
            if (isNaN(Number(item))) {
                this.appTypes.push(AppType[item]);
            }
        }
        await this.getList(this.statsFilter);
    }

    private async getList(days: string) {
        this.loading = true;
        try {
            const response = await this.axios.get(`/api/campaign/getcampaigns/${parseInt(days, 0)}`);
            this.campaignList = response.data.data.campaigns;
            if (response.data.data.hasShopPay) {                
                Vue.prototype.$setHasShopPay(true);
            } else {
                Vue.prototype.$setHasShopPay(false);
            }
            // send welcome flow to first time users
            if(this.campaignList.length === 0) {
                this.$crispUserEvent('user:welcome');
                // @ts-ignore
                window.$crisp.push(['set', 'user:created', this.$store.state.shopStats.createdDate]);
            }
        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.loading = false;
        }
    }

    private editCampaign(id: string) {
        this.$router.push({
            name: 'campaign',
            params: {
                id,
            },
        });
    }

    private newCampaign() {
        this.campaign.name = '';
        this.campaign.active = false;
        this.dialog = true;
    }

    private async save() {
        this.loading = true;
        this.actionNewLoading = true;
        this.close();
        try {
            const response = await this.axios.post('/api/campaign/createcampaign', this.campaign);
            this.loading = false;
            this.editCampaign(response.data.data.id);
        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.actionNewLoading = false;
        }
    }

    private close() {
        this.dialog = false;
    }

    private prepareNewCampaign(type) {
        if (this.getDisableFeatures) {
            this.dialog = false;
            this.$store.state.newChargeDialog.showing = true;
            return;
        }
        this.campaign.active = false;
        this.campaign.appType = type;
        let counter: number = 1;
        let counterString: string = '';
        this.campaignList.forEach((el) => {
            if (el.appType === type) {
                counter++;
            }
        });
        if (counter > 1) {
            counterString = ' ' + counter;
        }
        if (type === 'Wheelio' || type === 'Wheelio2') {
            this.campaign.name = type;
            this.$crispUserEvent('user:newCampaign-wheelio');
        } else if (type === 'Rollie') {
            this.campaign.name = 'They see me Rollin\'';
            this.$crispUserEvent('user:newCampaign-rollie');
        } else if (type === 'Scratchy') {
            this.campaign.name = 'Scratchy';
            this.$crispUserEvent('user:newCampaign-scratchy');
        }

        this.campaign.name = this.campaign.name + counterString;
        this.campaign.id = undefined;
        this.save();
    }
    get getDisableFeatures() {
        return this.$store.state.newChargeDialog.disableFeatures;
    }

    get allowShopPay(): boolean {      
        return this.$isShopify && this.$hasShopPay;       
    }

    get chipColor(): string {       
        return this.allowShopPay ? '#5433eb': 'red';
    }
}
