import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
// import { counter } from './counter/index';

Vue.use(Vuex);

// Vuex structure based on https://codeburst.io/vuex-and-typescript-3427ba78cfa8

const store: StoreOptions<RootState> = {
  state: {
    version: '1.0.0', // a simple property
    snackbar: {showing: false, text: '', color: '', timeout: 6000},
    newChargeDialog: {showing: false, disableFeatures: false},
    shopStats: {
      id: '',
      name: '',
      email: '',
      createdDate: new Date(),
      domain: '',
      show: 0,
      spin: 0,
      collect: 0,
      reject: 0,
      conversionRate: 0,
      revenue: 0,
      price: 0,
      currentShow: 0,
      currency: '',
      moneyWithCurrencyFormat: '',
      type: '',
      planName: '',
      hasShopPay: false
     },
  },

  mutations: {
    showSuccessSnackbar(state, text) {
      state.snackbar.text = text;
      state.snackbar.color = 'success'
      state.snackbar.showing = true;
      state.snackbar.timeout = 6000;
    },
    showErrorSnackbar(state, text) {
      state.snackbar.text = text;
      state.snackbar.color = 'error'
      state.snackbar.showing = true;
      state.snackbar.timeout = 6000;
    },
    showSessionSnackbar(state, text) {
      state.snackbar.text = text;
      state.snackbar.color = 'error'
      state.snackbar.showing = true;
      state.snackbar.timeout = 0;
    },
    showWarningSnackbar(state, text) {
      state.snackbar.text = text;
      state.snackbar.color = 'warning'
      state.snackbar.showing = true;
      state.snackbar.timeout = 6000;
    },
    showServiceWorkerSnackbar(state, options) {
      state.snackbar.showing = true;
      state.snackbar.text = options.text;
      state.snackbar.onClickText = options.onClickText;
      state.snackbar.onClick = options.onClick;
      state.snackbar.color = 'warning'
      state.snackbar.timeout = 0;
    }
  },

  getters: {
    getSnackbar(state): object {
      return state.snackbar;
    }
  }
};

export default new Vuex.Store<RootState>(store);
