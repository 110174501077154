
import { Component, Vue, Prop, Watch  } from 'vue-property-decorator';
import i18n from '../plugins/i18n';
import { ICampaign } from '../models/Campaign';
import { Theme, ThemeImagesPosition, Color, Images } from '../models/App';
import ProgressBar from '@/components/ProgressBar.vue';
import Recart from '@/components/integrations/Recart.vue';
import '../assets/wheelio2.css';
import { TemplatesHelpers } from '../helpers/templatesHelpers';
import store from '../store/index';

interface ISlice {
    id: number;
    gravity: number;
    text: string;
    code: string;
    email: string;
    fill: string;
}

@Component({
    components: {
        ProgressBar,
        Recart
    }
})
export default class Wheelio2Template extends Vue {
    @Prop(Object) public campaign!: ICampaign;
    @Prop(Object) public themeImages!: Images;
    @Prop(Number) public swapView!: number;
    @Prop(Number) public mobilePreview!: number;
    

    private selectedTheme: Theme = { text: '', value: '', selected: false, images: { background: '', top: '', bottom: '', pulltab: '', wheel: '' }};
    private selectedColors: Color = {
        theme: '',
        backgroundColor: { hex: '' },
        wheelFontColor: { hex: '' },
        fontColor: { hex: '' },
        buttonColor: { hex: '' },
        sliceColorSet1: { hex: '' },
        sliceColorSet2: { hex: '' },
        sliceColorSet3: { hex: '' },
        sliceColorSet4: { hex: '' },
        pointerColor: { hex: ''},
        buttonFontColor: { hex: ''}
    };
    private visible = true;
    private previewText = i18n.t('label.hide');
    private gdprCheckbox = false;
    private WheelioContainer = document.getElementsByClassName('wlo-holder')[0] as HTMLElement;

   private created() {
      this.$nextTick(function() {
         if (this.isPhoneView) {
            // @ts-ignore
            this.WheelioContainer = document.getElementById('phone-simulator')?.contentWindow.document.body as HTMLElement;
         } else {
            this.WheelioContainer = document.getElementsByClassName('wlo-holder')[0] as HTMLElement;
         }

         this.applyTheme();
      })
   }

   private StartSpin() {
      const temlatesHelpers = new TemplatesHelpers(this.WheelioContainer ?? new HTMLElement(), this.campaign);
      temlatesHelpers.StartSpin(this.gdprCheckbox);
   }

   private Collect() {
        // @ts-ignore
        store.commit('showSuccessSnackbar', this.campaign.app.texts.copiedMessage);
    }

   get invertColor() {
        const hex = this.selectedColors.backgroundColor.hex.replace('#', '');
        const C_R = parseInt(hex.substr(0, 2), 16);
        const C_G = parseInt(hex.substr(2, 2), 16);
        const C_B = parseInt(hex.substr(4, 2), 16);
        const brightness = ((C_R * 299) + (C_G * 587) + (C_B * 114)) / 1000;
        return brightness < 200;
    }

   @Watch('themeImages', { deep: true })
   private onwheelioSelectedImagesChanged() {
      this.applyTheme();
   }

   private applyTheme() {
      // @ts-ignore
      this.selectedTheme = this.campaign.app.themes.findWith('selected', true);
      const selectedThemeValue = this.selectedTheme.origin != null && this.selectedTheme.origin !== '' ? this.selectedTheme.origin : this.selectedTheme.value;
      // @ts-ignore
      this.selectedColors = this.campaign.app.colors.findWith('theme', this.selectedTheme.value);
      const wheelio = this.WheelioContainer.querySelector('.wlo-panel');

      if (wheelio !== null) {
         // remove existing elements
         // @ts-ignore
         const themeItems = Array.from(this.WheelioContainer.querySelectorAll('.theme-item'));
         themeItems.forEach((el) => {
               el.remove();
         });

         // add new elements
         const theme: ThemeImagesPosition[] = [
               { type: 'bg', value: this.themeImages.background },
               { type: 'bottom', value: this.themeImages.bottom },
               { type: 'top', value: this.themeImages.top },
         ];
         const themeHolder = this.WheelioContainer.querySelector('.wlo-theme-holder') || document.createElement('div');
         themeHolder.classList.add('wlo-theme-holder');
         // @ts-ignore
         themeHolder.style.height = (this.WheelioContainer.querySelector('.wlo-wheel-holder').clientHeight*0.8) + this.WheelioContainer.querySelector('.wlo-content-holder').clientHeight + 'px';
         // @ts-ignore
         this.WheelioContainer.querySelector('.wlo-panel').prepend(themeHolder);
         theme.forEach(el => {
            if (el.value !== '' && el.value != null) {
               const div = document.createElement('img');
               div.setAttribute('src', el.value);
               switch (el.type) {
                  case 'bg': {
                        const bg = document.createElement('div');
                        bg.classList.add('wlo-theme-background', 'theme-item');
                        bg.setAttribute('style', `background-image: url('${el.value}');`);
                        themeHolder.prepend(bg);
                        break;
                  }
                  case 'bottom': {
                        div.classList.add('wlo-theme-bottom', 'theme-item');
                        themeHolder.prepend(div);
                        break;
                  }
                  case 'top': {
                        div.classList.add('wlo-theme-top', 'theme-item');
                        themeHolder.prepend(div);
                        break;
                  }
                  default: {
                        break;
                  }
               }
            }
         });
      }
   }
   get isPhoneView() {
      return this.mobilePreview === 1;
   }

   get gdprEnable() {
      return this.campaign.app?.gdprEnable;
   }

   get pullTabSyles(){
      let pullTabStyle = {
         backgroundColor: this.selectedColors.backgroundColor.hex,
         width: '90px',
         height: '90px'
      }
      
      let pullTabSize = this.isPhoneView
         ? this.campaign.triggers?.mobilePullTabSize
         : this.campaign.triggers?.desktopPullTabSize;      

      if (pullTabSize === 2) {
         pullTabStyle.width = pullTabStyle.height = '60px';         
      } else if(pullTabSize === 3) {
         pullTabStyle.width = pullTabStyle.height = '30px';         
      }
      return pullTabStyle;
   }

   get pullTabImageSyles() {
      let pullTabImageStyle = {
         backgroundImage: `url('${this.campaign.triggers?.desktopPullTabImg}')`,
         backgroundSize: '50px'        
      }

      if (this.campaign.triggers?.desktopPullTabSize === 2) {
         pullTabImageStyle.backgroundSize = '25px';         
      } else if(this.campaign.triggers?.desktopPullTabSize === 3) {
         pullTabImageStyle.backgroundSize = '15px';           
      }
      return pullTabImageStyle;
   }

   get closeButtonPosition() {
      const closePosition = this.campaign.triggers?.mobileCloseButtonbPosition;
      let position = {
         bottom: '0px',
         top: 'unset',
         left: 'unset',
         right: '10px',
         borderRadius: '40px 40px 0 0'
      };
      
      if (closePosition === 'bottom_left') {
         position.left = '10px';
         position.right = 'unset';
      } else if (closePosition === 'bottom_right') {
         position.left = 'unset';
         position.right = '10px';         
      } else if (closePosition === 'top_left') {
         position.bottom = 'unset';         
         position.borderRadius = '0 0 40px 40px';
         position.left = '10px';
         position.right = 'unset';
      } else if (closePosition === 'top_right') {         
         position.bottom = 'unset';
         position.borderRadius = '0 0 40px 40px';
         position.left = 'unset';
         position.right = '10px';
      }

      return position;
   }
}
