
import { Component, Vue } from 'vue-property-decorator';
import { Transaction } from '../../models/Transaction';
import { ErrorHandler } from '../../helpers/errorHandler';
import { defaultFormatUtc } from 'moment';
import { PricingPlans, IPricigPlan } from '../../helpers/pricingPlan';
import i18n from '../../plugins/i18n';

@Component({})
export default class ActivateSubscription2 extends Vue {
    private show: boolean = true;
    private loading: boolean = true;
    private showChangeCardForm: boolean = false;
    private actionLoading: boolean = false;
    private paymentStatus: string = '';
    private token: string = '';
    private title: any = i18n.t('dialogs.register_credit_card.title');
    private numberToken = '';

    private errorCreditCardNumber: boolean = false;
    private errorCardholderName: boolean = false;
    private errorExpirationDate: boolean = false;
    private errorCvv: boolean = false;
    private errorMessageCreditCardNumber: string = '';
    private errorMessageCardholderName: string = '';
    private errorMessageExpirationDate: string = '';
    private errorMessageCvv: string = '';

    private error: boolean = false;
    private rules: any = {
        required: (value) => !!value || i18n.t('validation.required'),
    };

    private clientInstance: object = {};
    private braintreeObj: object = {};
    private pricingPlans = new PricingPlans();
    private currencyPlan: IPricigPlan = {
        name:  this.pricingPlans.Basic.name,
        type: this.pricingPlans.Basic.type,
        price: this.pricingPlans.Basic.price,
        currency: this.pricingPlans.Basic.currency,
        limit: this.pricingPlans.Basic.limit
    };

    private transaction: Transaction = {
        cardholderName: '',
        pricingPlan: this.pricingPlans.Basic.type.toLowerCase(),
        paymentMethodNonce: '',
        creditCardNumber: '',
        expirationDate: '',
        cvv: '',
        newCard: true,
        imageUrl: '',
        trialDuration: 0
    };

    private logout() {
        sessionStorage.clear();
        this.$router.push({
            name: 'login',
        });
    }

    private toPage() {
        this.$router.push({
            name: 'campaignlist',
        });
    }

    private async created() {
        try {
            this.loading = true;
            // @ts-ignore
            const response = await this.axios.get(`/api/braintree/getdefaultpaymentMethod`);
            const data = response.data.data;
            if (data.maskedNumber != null) {
                this.transaction.creditCardNumber = data.maskedNumber;
                this.transaction.expirationDate = data.expirationDate;
                this.transaction.imageUrl = data.imageUrl;
                this.numberToken = data.token;
                this.transaction.newCard = false;

                if (data.trialDuration <= 7 && data.trialDuration > 0) {
                    this.title = i18n.t('dialogs.register_credit_card.enter_credit_card_to_continue', { msg: data.trialDuration });
                } else {
                    this.title = i18n.t('dialogs.register_credit_card.enter_credit_card_to_activate', { msg: data.pricingPlan });
                }
            } else {
                this.changeCard();
            }

            this.transaction.trialDuration = data.trialDuration;

            if (data.currency === 'EUR') {
                this.currencyPlan = this.pricingPlans.Basic;
            } else if ((data.currency === 'USD')) {
                this.currencyPlan = this.pricingPlans.BasicUSD;
            }

            this.transaction.pricingPlan =  this.currencyPlan.type;

            await this.generateToken();
        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.loading = false;
        }
    }

    private async generateToken() {
        try {
            const response = await this.axios.get(`/api/braintree/GenerateToken`);
            this.token = response.data.data;
            this.createBraintreeInstance();

        } catch (error: any) {
            new ErrorHandler().Server(error);
        }
    }

    private changeCard() {
        this.transaction.creditCardNumber = '';
        this.transaction.expirationDate = '';
        this.transaction.imageUrl = '';
        this.transaction.newCard = true;
        this.showChangeCardForm = true;
        this.loading = false;
    }

    private createBraintreeInstance() {
        // @ts-ignore
        const braintreeObj = require('braintree-web');
        braintreeObj.client.create({
                authorization: this.token,
            })
            .then((instance: object) => {
                this.clientInstance = instance;
                this.loading = false;
                return braintreeObj.dataCollector.create({
                    client: instance
                }).then((dataCollectorInstance: object) => {
                    // @ts-ignore
                    this.transaction.deviceData = dataCollectorInstance.deviceData;
                });
            })
            .catch((error: any) => {
                alert(error);
            });
    }

    private createPaymentRequest() {
        this.resetErrors();
        // @ts-ignore
        if (this.$refs.form.validate() === false) {
            return;
        }

        this.actionLoading = true;
        let expirationDate = this.transaction.expirationDate;

        if (this.transaction.expirationDate.indexOf('/') === -1) {
            expirationDate = this.transaction.expirationDate.substring(0, 2) + '/'
                + this.transaction.expirationDate.substring(2, 4);
        }

        let cardNumber = this.transaction.creditCardNumber;
        if (this.transaction.newCard === false) {
            const token = this.numberToken.replace(/\$/g, '');
            cardNumber = this.transaction.creditCardNumber.replace('******', token);
        }

        const data = {
            creditCard: {
                number: cardNumber,
                expirationDate,
                cvv: this.transaction.cvv,
            },
        };


        // @ts-ignore
        this.clientInstance.request({
            endpoint: 'payment_methods/credit_cards',
            method: 'post',
            data,
        })
        .then((payload: any) => {
            this.createPayment(payload);
        })
        .catch((error: any) => {
            this.actionLoading = false;
            let errorMessage = '';
            error.details.originalError.fieldErrors.forEach((err: any) => {
                err.fieldErrors.forEach((fieldError: any) => {
                    errorMessage += 'Code: ' + fieldError.code + '<br /> Message: ' + fieldError.message + '<br />';
                    switch (fieldError.field) {
                        case 'number': {
                            this.errorCreditCardNumber = true;
                            this.errorMessageCreditCardNumber = fieldError.message;
                            break;
                        }
                        case 'expirationMonth': {
                            this.errorExpirationDate = true;
                            this.errorMessageExpirationDate = fieldError.message;
                            break;
                        }
                        case 'cvv': {
                            this.errorCvv = true;
                            this.errorMessageCvv = fieldError.message;
                            break;
                        }
                    }
                });
            });
        });
    }

    private async createPayment(payload: any) {
        const payment: object = {
            paymentMethodNonce:  payload.creditCards[0].nonce,
            newCard: this.transaction.newCard,
            pricingPlan: this.transaction.pricingPlan,
            cardholderName: this.transaction.cardholderName,
            creditCardNumber: this.transaction.creditCardNumber,
            deviceData: this.transaction.deviceData
        };

        try {
            await this.axios.post<Transaction>(`/api/braintree/createpayment`, payment);

            // @ts-ignore
            this.clientInstance.teardown();

            setTimeout(() => {
                this.actionLoading = false;
                this.toPage();
            }, 3000);

        } catch (error: any) {
            new ErrorHandler().Server(error);
            this.errorCreditCardNumber = true;
            this.errorMessageCreditCardNumber = new ErrorHandler().errorMessage;
            this.actionLoading = false;
        }
    }

    private resetErrors() {
        this.errorCreditCardNumber = false;
        this.errorCardholderName = false;
        this.errorExpirationDate = false;
        this.errorCvv = false;
        this.errorMessageCreditCardNumber = '';
        this.errorMessageCardholderName = '';
        this.errorMessageExpirationDate = '';
        this.errorMessageCvv = '';
    }
}

