
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ScheduleType, AppType } from '../helpers/enums';
import { ErrorHandler } from '../helpers/errorHandler';
import { ICampaign } from '../models/Campaign';
import { Helpers } from '../helpers/helpers';
import Campaign from './Campaign.vue';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';
import { Trans } from '../plugins/translation';

@Component({
    components: {
        ConfirmDialog,
    }
})
export default class CampaignCard extends Vue {
    @Prop(Array) campaignList!: ICampaign[];
    @Prop(Object) campaign!: ICampaign;
    @Prop(String) statsFilter!: string;
    @Prop(Boolean) reviewDialogActive!: boolean;
    private helpers = new Helpers();
    private actionLoading: boolean = false;
    private showConfirmDialog: boolean = false;
    private editCampaign(id: string) {
         this.$router.push({
            name: 'campaign',
            params: {
                id,
            },
        });
    }

    private async archive() {
        this.actionLoading = true;
        try {
            await this.axios.delete(`/api/campaign/deletecampaign/${this.campaign.id}`);
        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.actionLoading = false;
            this.showConfirmDialog = false;
            const campaignList = this.campaignList.filterWithDiff('id', this.campaign.id);
            this.$emit('update:campaignList', campaignList);
        }
    }

    private async changeActive() {
        this.actionLoading = true;
        try {
            await this.axios.patch(`/api/campaign/PatchCampaign/${this.campaign.id}`, this.campaign);
        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.actionLoading = false;
        }
    }

    private async copyCampaign() {
        this.actionLoading = true;
        try {
            await this.axios.post(`/api/campaign/copycampaign/${this.campaign.id}`);
            // @ts-ignore
            await this.$parent.getList(this.statsFilter);
        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.actionLoading = false;
        }
    }

    private actionDisabled(campain: ICampaign) {
        return !campain.active || this.actionLoading;
    }

    get statsTooltip() {
        return this.$t('campaign.stats_tooltip', { days: `${this.statsFilter}` })
    }
}
