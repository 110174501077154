import { AppType } from '@/helpers/enums';
import { Component, Vue, Watch } from 'vue-property-decorator';

const phones = {
    'iphone-6-white': {
        name: 'iPhone 6 (white)',
        filepath: 'iphone-6-white.png',
        imageDimensions: [482, 982],
        screen: {
            left: 34,
            top: 126,
            width: 414,
            height: 736
        }
    },
    'google-pixel': {
        name: 'Google Pixel',
        filepath: 'google-pixel.png',
        imageDimensions: [470, 964],
        screen: {
            left: 27,
            top: 109,
            width: 411,
            height: 731
        }
    },
    'iphone12': {
        name: 'iPhone 11',
        filepath: 'iphone-11.png',
        imageDimensions: [470, 964],
        screen: {
            left: 100,
            top: 23,
            width: 345,
            height: 740,
        }
    }
};

@Component({
    props: {
        scale: { type: Number, required: false },
        phone: { type: String, required: false },
        appType: { type: String, required: false },
        swapView: { type: Number, required: false }
    },
    render(this: PhoneSimulator): Vue.VNode {
        return (
            <div style={this.phoneSimulatorStyles}>
                <div class='phone' style={this.phoneStyles} >
                    <iframe id='phone-simulator' onLoad={this.onLoad} style={this.phoneScreenStyles} seamless='true' ></iframe>
                </div>
            </div>
        )
    },
    beforeUpdate() {
        // @ts-ignore
        this.iApp.children = Object.freeze(this.$slots.default);
    }
})

export class PhoneSimulator extends Vue {
    private iApp: object = {};
    private scale: number = this.$props.scale;
    private phone: string = this.$props.phone;
    private appType: string = this.$props.appType;
    private swapView: number = this.$props.swapView;

    get phoneStyles() {
        let style = '';
        if (this.activePhone) {
            const imageDimensions = this.activePhone.imageDimensions;
            const filepath = this.activePhone.filepath;
            const width = imageDimensions[0];// * this.scale;
            const height = imageDimensions[1];// * this.scale;
            style = `width: ${width}px; height: ${height}px; `;
            // @ts-ignore
            style += `background-image: url('${require('../../assets/simulators/' + filepath)}'); `;
            style += `transform: scale(${this.scale});transform-origin: 0 0;`
            if (this.$vuetify.breakpoint.md) {
                style += 'left: -60px;'
            }
        }

        return style;
    }

    get activePhone() {
        return phones[this.phone];
    }

    get phoneSimulatorStyles() {
        let style = '';
        if (this.activePhone) {
            const imageDimensions = this.activePhone.imageDimensions;
            const width = imageDimensions[0] * this.scale;
            const height = imageDimensions[1] * this.scale;
            style = `width: ${width}px; height: ${height}px; `;
            style += 'position: relative;';
        }
        return style;
    }

    get phoneScreenStyles() {
        if (this.activePhone) {
            const screen = this.activePhone.screen;
            return `left: ${screen.left}px; top: ${screen.top}px; width: ${screen.width}px; height: ${screen.height}px; position: absolute; background: white; border-radius: 30px;`;
        }
        return '';
    }

    @Watch('swapView')
    private onSwapViewChanged() {
        this.onLoad();
    }

    onLoad() {
        const cssHelper = document.createElement('style');
        let css = '.wheel_holder {position: relative !important; top: 0 !important; }';
        css += '.wlo_panel {margin-bottom: 0 !important; } .scr-content-holder { padding-bottom: 200px !important;}';
        css += '.scratchy_logo { background-size: 30% !important; background-position: center !important; min-height: 120px !important;}';
        css += '.content_holder, .scr-content-holder, .scr_holder, .wheelio_holder {scrollbar-width: none !important} .scr_holder::-webkit-scrollbar, .wheelio_holder::-webkit-scrollbar, .content_holder::-webkit-scrollbar, .scr-content-holder::-webkit-scrollbar, .scr_content::-webkit-scrollbar, .wlo_pulltab_holder { display: none !important;}'
        css += '.wlo-content-holder {width: 90% !important;} .wlo-content .wlo-before > div {opacity: 1 !important;} #wlo-submit-btn {background-image: unset !important} .wlo-checkbox .checkbox-label .checkbox-custom {top: 15px !important;}';
        css += '.wlo-wheel-holder .wlo-wheel.wlo-animate-in {transform: scale(0.5) !important;} .wlo-panel .wlo-content-holder {top: 230px !important;}';
        css += '.wlo-panel .wlo-wheel-holder #wlo-pointer {right: -190px !important;}';
        // css += '.wlo-content .wlo-form__group {height: 40px !important} .input-title {min-height: 40px !important}'
        css += '.wlo-panel { overflow-y: hidden !important;}';
        css += '.wlo-holder .wlo-panel .wlo-content-holder { left: 20px !important }';
        css += '.wlo-holder .wlo-content-holder { padding: 0 !important }';
        css += '.trigger-button-holder { display: none; }'
        css += '.wlo-holder .wlo-panel .wlo-theme-top {max-width: 100% !important;}'
        css += '.wlo_content {margin-bottom:unset !important;}'
        cssHelper.appendChild(document.createTextNode(css));

        let link = document.createElement('link');
        const iframe = document.getElementById('phone-simulator');
        // @ts-ignore
        const doc = iframe.contentWindow.document;
        doc.head.appendChild(cssHelper);

        link.href = `https://wheelioapp.azureedge.net/app/${this.appType}/${this.appType}.css`;
        // link.href = `../../assets/${this.appType}.css`;
        link.rel = 'stylesheet';
        link.type = 'text/css';
        doc.head.appendChild(link);

        // load wheelio css for other app types
        if (this.appType === AppType.Rollie || this.appType === AppType.Scratchy) {
            link = document.createElement('link');
            link.href = `https://wheelioapp.azureedge.net/app/wheelio/wheelio.css`;
            // link.href = `../../assets/${this.appType}.css`;
            link.rel = 'stylesheet';
            link.type = 'text/css';
            doc.head.appendChild(link);
        }

        const children = this.$slots.default;

        // @ts-ignore
        const body = this.$el.firstChild?.firstChild.contentDocument.body;
        const el = document.createElement('div');
        body.innerHTML = '';
        body.appendChild(el);
        body.style = 'margin: 0;';

        const iApp = new Vue({
            name: 'iApp',
            data: { children: Object.freeze(children) },
            render(h) {
                return h('div', children);
            }
        });

        iApp.$mount(el);
        this.iApp = iApp;
    }
}