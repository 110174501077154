
import {
    Component,
    Vue,
    Prop,
    Watch,
    Model,
} from 'vue-property-decorator';
@Component({})
export default class ConfirmDialog extends Vue {
    @Prop(String) public headlineText!: string;
    @Prop(String) public mainText!: string;
    @Prop(String) public additionalText!: string;
    @Prop(String) public proceedText!: string;
    @Prop(Boolean) public showConfirmDialog!: boolean;
    @Prop(Boolean) public confirmDialogLoading!: boolean;
    private clientInstance: object = {};

    private closeDialog() {
        this.$emit('update:showConfirmDialog', false);
        if (this.clientInstance !== null && this.clientInstance.hasOwnProperty('teardown')) {
            // @ts-ignore
            this.clientInstance.teardown();
        }
    }
    private confirm() {
        this.$emit('confirm');
    }
}
