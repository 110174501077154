import { IPricigPlan } from './pricingPlan';

export class PricingPlanValidation {
    public CurrencySymbol = '$';

    public Basic: IPricigPlan = {
        name: '',
        type: 'Basic',
        price: 33,
        limit: 10000,
    };

    public Normal: IPricigPlan = {
        name: '',
        type: 'Normal',
        price: 113,
        limit: 50000,
    };

    public Pro: IPricigPlan = {
        name: '',
        type: 'Pro',
        price: 199,
        limit: 100000,
    };
}
