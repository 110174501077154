
// before you will make any import
Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate',
]);

import { Component,  Vue, Watch } from 'vue-property-decorator';
import { BaseClass } from '../models/baseClass';
import { ErrorHandler } from '../helpers/errorHandler';
import { Integration, IntegrationList } from '../models/Integration';
import { IntegrationType } from '../helpers/enums';
import { Credentials } from '../models/Credentials';
import i18n from '../plugins/i18n';
import PricingPlanValidationDialog from '@/components/dialogs/PricingPlanValidationDialog.vue';
import Tooltip from '@/components/Tooltip.vue';
import store from '../store/index';
import UnsavedChangesDialog from '@/components/dialogs/UnsavedChangesDialog.vue';
import { Route } from 'vue-router';

@Component({
    components: {
        PricingPlanValidationDialog,
        Tooltip,
        UnsavedChangesDialog
    }
})
export default class Integrations extends BaseClass<Integration> {
    private enableActivation: boolean = false;
    private actionSaveLoading: boolean = false;
    private actionRemoveLoading: boolean = false;
    private actionSyncLoading: boolean = false;
    private toolbarSticky: boolean = false;
    private mainview;
    private apiKey: string = '';
    private secretKey: string = '';
    private shopId: string = '';
    private credentials: Credentials = {
        username : '',
        password: '',
    };
    private apiKeyVisible: boolean = false;
    private showValidationDialog: boolean = false;
    private integrationType: IntegrationType = IntegrationType.None;    
    private activeCampaignDialogVisible: boolean = false;
    private pushowlDialog: boolean = false;
    private error: boolean = false;
    private showPassword: boolean = false;
    public newPath: any = '';
    private rules: any = {
        required: (value) => !!value || i18n.t('validation.required'),
    };
    private firepushCollect = [
        { text: 'Emails', value: 'email'},
        { text: 'SMS', value: 'sms'}
    ];
    private firepushTOSLangs = [
        { text: 'English', value: 'english'},
        { text: 'French', value: 'french'},
        { text: 'German', value: 'german'},
        { text: 'Spanish', value: 'spanish'},
        { text: 'Italian', value: 'italian'},
        { text: 'Portuguese', value: 'portuguese'},
        { text: 'Japanese', value: 'japanese'},
        { text: 'Dutch', value: 'dutch'},
        { text: 'Norwegian', value: 'norwegian'}
    ];
    private integrations: Integration[] = [];
    private integration: Integration = {
        id: '',
        type: IntegrationType.None,
        settings: [],
        active: false,
        enabled: false,
        shopId: '',
        doubleOptIn: false
    };
    private integrationSMS: Integration = {
        id: '',
        type: IntegrationType.None,
        settings: [],
        active: false,
        enabled: false,
        shopId: '',
        doubleOptIn: false
    };
    private integrationEmailValid: Integration = {
        id: '',
        type: IntegrationType.None,
        settings: [],
        active: false,
        enabled: false,
        shopId: '',
        doubleOptIn: false
    };
    private integrationPushowl: Integration = {
        id: '',
        type: IntegrationType.None,
        settings: [],
        active: false,
        enabled: false,
        shopId: '',
        doubleOptIn: false
    };
    private integrationFirepush: Integration = {
        id: '',
        type: IntegrationType.None,
        settings: [],
        active: false,
        enabled: false,
        shopId: '',
        doubleOptIn: false
    };
    private integrationTrans: Integration = {
        id: '',
        type: IntegrationType.None,
        settings: [],
        active: false,
        enabled: false,
        shopId: '',
        doubleOptIn: false
    };
    private selectedIntegration?: IntegrationList = {
        id: '',
        name: '',
        selected: false,
    };

    private selectedIntegrationSMS?: IntegrationList = {
        id: '',
        name: '',
        selected: false,
    };

    private created() {
        return this.getIntegrations();
    }

    private mounted () {
        window.addEventListener('scroll', this.handleScroll);
    }

    private beforeDestroy () {
        window.removeEventListener('scroll', this.handleScroll);
    }

    private async getIntegrations() {
        this.loading = true;
        try {
            const response = await this.axios.get(`/api/integration/getintegrations`);
            this.integrations = response.data.data;
            this.shopId = this.integrations[0].shopId;

            this.integration = this.findIntegrationSetup(IntegrationType.None);
            this.integrationSMS = this.findIntegrationSetup(IntegrationType.SMSBump);

            if (this.integrationSMS.type === IntegrationType.None ) {
                this.integrationSMS = this.findIntegrationSetup(IntegrationType.Mobiniti);
                if (this.integrationSMS.type === IntegrationType.None ) {
                    this.integrationSMS = this.findIntegrationSetup(IntegrationType.Postscript);
                }
                if (this.integrationSMS.type === IntegrationType.None ) {
                    this.integrationSMS = this.findIntegrationSetup(IntegrationType.Attentive);
                }
                if (this.integrationSMS.type === IntegrationType.None ) {
                    this.integrationSMS = this.findIntegrationSetup(IntegrationType.Emotive);
                }
                if (this.integrationSMS.type === IntegrationType.None ) {
                    this.integrationSMS = this.findIntegrationSetup(IntegrationType.RecartSMS);
                }
            }

            // this.integrationEmailValid = this.findIntegrationSetup(IntegrationType.EmailListVerify);
            this.integrationEmailValid = this.findIntegrationSetup(IntegrationType.Kickbox);

            if (this.integration && this.integration.settings != null && this.integration.settings.length > 0) {
                this.selectedIntegration = this.integration.settings.findWith('selected', true);
            } else {
                this.selectedIntegration = undefined;
            }

            if (this.integrationSMS && this.integrationSMS.settings != null && this.integrationSMS.settings.length > 0) {
                this.selectedIntegrationSMS = this.integrationSMS.settings.findWith('selected', true);
            } else {
                this.selectedIntegrationSMS = undefined;
            }

            this.integrationPushowl = this.findIntegrationSetup(IntegrationType.PushOwl);
            this.integrationTrans = this.findIntegrationSetup(IntegrationType.Weglot);
            this.integrationFirepush = this.findIntegrationSetup(IntegrationType.Firepush);

        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.loading = false;
        }
    }

    private async integrationLogin(provider: IntegrationType) {
        // window.open(`signin/${this.integration.shopId}/${provider}/${this.apiKey}`, '_blank');
        if (provider === IntegrationType.Kickbox || provider === IntegrationType.Klaviyo || provider === IntegrationType.GetResponse
            || provider === IntegrationType.Drip || provider === IntegrationType.ConvertKit || provider === IntegrationType.MailerLite
            || provider === IntegrationType.Omnisend || provider === IntegrationType.Postscript || provider === IntegrationType.PushOwl
            || provider === IntegrationType.SMSBump || provider === IntegrationType.Sendinblue || provider === IntegrationType.Mailjet
            || provider === IntegrationType.Weglot || provider === IntegrationType.Firepush || provider === IntegrationType.Attentive
            || provider === IntegrationType.SmartrMail || provider === IntegrationType.Emotive || provider === IntegrationType.OnVoard
            || provider === IntegrationType.NewsMAN || provider === IntegrationType.RecartSMS || provider === IntegrationType.Sendlane) {
                // @ts-ignore
                if ((this.$refs.form !== undefined && this.$refs.form.validate()) || provider === IntegrationType.Kickbox || provider === IntegrationType.PushOwl || provider === IntegrationType.Firepush) {
                    try {
                        this.actionLoading = true;
                        let apiUrl = `/successful/${this.shopId}/${provider}?apiKey=${this.apiKey}`;
                        if (provider === IntegrationType.Mailjet || provider === IntegrationType.Attentive || provider === IntegrationType.Emotive
                            || provider === IntegrationType.NewsMAN) {
                            apiUrl += `&secretKey=${this.secretKey}`;
                        }
                        await this.axios.get(apiUrl);
                        // @ts-ignore
                        // TODO: find another soution
                        // this.$refs.form.reset();
                        this.apiKeyVisible = false;
                        await this.getIntegrations();
                    } catch (error: any) {
                        new ErrorHandler().Server(error);
                        if (provider === IntegrationType.PushOwl && error.response.data.code === 'AppNotInstalled') {
                            this.pushowlDialog = true;
                        } else if (provider === IntegrationType.Firepush && error.response.data.code === 'AppNotInstalled') {
                            this.pushowlDialog = true;
                        }
                    } finally {
                        this.actionLoading = false;
                    }
                }
        } else if (provider === IntegrationType.ActiveCampaign)  {
            // @ts-ignore
            if (this.$refs.formActiveCampaign.validate()) {
                this.actionLoading = true;
                const userDetails = {
                    apiUrl: this.credentials.username,
                    apiKey: this.credentials.password,
                };
                try {
                    const response = await this.axios.post(`/activecampaignlogin/${this.shopId}`, userDetails);
                    this.actionLoading = false;
                    this.activeCampaignDialogVisible = this.actionLoading = false;
                    await this.getIntegrations();
                } catch (error: any) {
                     new ErrorHandler().Server(error);
                } finally {
                    this.actionLoading = false;
                }
            }
        } else {
            window.open(`signin/${this.integration.shopId}/${provider}`, '_blank');
        }
    }

    private async integrationSave() {
        this.actionSaveLoading = true;
        try {
            await this.axios.put<Integration[]>(`/api/integration/saveintegration`, this.integrations);
            this.hasChanged = false;
        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.actionSaveLoading = false;
        }
    }

    private async integratioRemove(id: string) {
        this.actionRemoveLoading = true;

        try {
            // @ts-ignore
            await this.axios.delete(`/api/integration/removeintegration/${id}`);
            await this.getIntegrations();
        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.actionRemoveLoading = false;
        }
    }

    private async integratioSync(id: string) {
        this.actionSyncLoading = true;

        try {
            // @ts-ignore
            await this.axios.post(`/api/services/syncintegration/${id}`);
            store.commit('showSuccessSnackbar', i18n.t('general.sync').toString())
        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.actionSyncLoading = false;
        }
    }

    get enableEmailLogin() {
        return this.integration.type === IntegrationType.None;
    }

    get enableSMSLogin() {
        return this.integrationSMS.type === IntegrationType.None;
    }

    get enablePushOwl() {
        return this.integrationPushowl.type === IntegrationType.None;
    }

    get enableEmailValidLogin() {
        return this.integrationEmailValid.type === IntegrationType.None;
    }

    get enableFirepush() {
        return this.integrationFirepush.type === IntegrationType.None && this.$isShopify;
    }

    private listChaged(id: string, type: IntegrationType) {
        this.integrations.forEach((integra: Integration) => {
            if (integra.type === type) {
                // @ts-ignore
                integra.settings.forEach((integ: any) => integ.selected = false);
                // @ts-ignore
                integra.settings.forEach((integ: any) => {
                    if (integ.id === id) {
                        integ.selected = true;
                    }
                });
            }
        });
    }

    private switchChange() {
        if (!this.hasChanged) {
            this.hasChanged = !this.hasChanged;
        } else {
            this.hasChanged = true;
        }
    }

    private integrationLoginChoose(provider: string) {
        this.integrationType = provider as IntegrationType;
        switch (this.integrationType) {
            case IntegrationType.AWeber:
            case IntegrationType.CampaignMonitor:
            case IntegrationType.MailChimp:
            case IntegrationType.Firepush:
            case IntegrationType.PushOwl:
                this.integrationLogin(this.integrationType);
                break
            case IntegrationType.ActiveCampaign:
                this.activeCampaignDialogVisible = true;
                break;           
            default:
                this.apiKeyVisible = true;
                break;
        }
    }

    private closeLoginDialog(provider: string) {
         this.integrationType = provider as IntegrationType;
         switch (this.integrationType) {
            case IntegrationType.ActiveCampaign:
                // @ts-ignore
                this.$refs.formActiveCampaign.reset();
                this.activeCampaignDialogVisible = false;
                break;           
             default:
                // @ts-ignore
                this.$refs.form.reset();
                this.apiKeyVisible = false;
                break;
         }
    }

    private apiKeyHeadline() {
        if (this.integrationType === IntegrationType.Attentive) {
            return i18n.t('integration.attentive_headline');
        } else if (this.integrationType === IntegrationType.ConvertKit) {
            return i18n.t('integration.convertkit_headline');
        } else if (this.integrationType === IntegrationType.Drip) {
            return i18n.t('integration.drip_headline');
        } else if (this.integrationType === IntegrationType.EmailListVerify) {
            return i18n.t('integration.emailListVerify_headline');
        } else if (this.integrationType === IntegrationType.Emotive) {
            return i18n.t('integration.emotive_headline');
        } else if (this.integrationType === IntegrationType.GetResponse) {
            return i18n.t('integration.getresponse_headline');
        } else if (this.integrationType === IntegrationType.Kickbox) {
            return i18n.t('integration.kickbox_headline');
        } else if (this.integrationType === IntegrationType.Klaviyo) {
            return i18n.t('integration.klaviyo_headline');
        } else if (this.integrationType === IntegrationType.MailerLite) {
            return i18n.t('integration.mailerlite_headline');
        } else if (this.integrationType === IntegrationType.Mailjet) {
            return i18n.t('integration.mailjet_headline');
        } else if (this.integrationType === IntegrationType.NewsMAN) {
            return i18n.t('integration.newsman_headline');
        } else if (this.integrationType === IntegrationType.Omnisend) {
            return i18n.t('integration.omnisend_headline');
        } else if (this.integrationType === IntegrationType.OnVoard) {
            return i18n.t('integration.onvoard_headline');
        } else if (this.integrationType === IntegrationType.Postscript) {
            return i18n.t('integration.postscript_headline');
        } else if (this.integrationType === IntegrationType.RecartSMS) {
            return i18n.t('integration.recartsms_headline');
        } else if (this.integrationType === IntegrationType.Sendinblue) {
            return i18n.t('integration.sendinblue_headline');
        } else if (this.integrationType === IntegrationType.Sendlane) {
            return i18n.t('integration.sendlane_headline');
        } else if (this.integrationType === IntegrationType.SMSBump) {
            return i18n.t('integration.smsbump_headline');
        } else if (this.integrationType === IntegrationType.SmartrMail) {
            return i18n.t('integration.smartrmail_headline');
        }
         else if (this.integrationType === IntegrationType.Weglot) {
            return i18n.t('integration.translate_headline');
        } else {
            return 'Headline: Missing Translation';
        }
    }

    private apiKeyHelperLink() {
        if (this.integrationType === IntegrationType.Attentive) {
            return 'https://ui.attentivemobile.com/integrations/app/management';
        } else if (this.integrationType === IntegrationType.ConvertKit) {
            return 'https://app.convertkit.com/account/edit';
        } else if (this.integrationType === IntegrationType.Drip) {
            return '#';
        } else if (this.integrationType === IntegrationType.GetResponse) {
            return '#';
        } else if (this.integrationType === IntegrationType.EmailListVerify ||this.integrationType === IntegrationType.Kickbox) {
            return '#';
        } else if (this.integrationType === IntegrationType.Emotive) {
            return 'https://www.emotiveapp.co/signups';
        } else if (this.integrationType === IntegrationType.Klaviyo) {
            return 'https://www.klaviyo.com/account#api-keys-tab';
        } else if (this.integrationType === IntegrationType.MailerLite) {
            return 'https://app.mailerlite.com/integrations/api';
        } else if (this.integrationType === IntegrationType.Mailjet) {
            return 'https://app.mailjet.com/account/api_keys';
        } else if (this.integrationType === IntegrationType.NewsMAN) {
            return 'https://newsman.app/manager/account?tab=api&is_api=1#api';
        } else if (this.integrationType === IntegrationType.Omnisend) {
            return 'https://app.omnisend.com/#/my-account/integrations/api-keys';
        } else if (this.integrationType === IntegrationType.OnVoard) {
            return 'https://console.onvoard.com/api-details';
        } else if (this.integrationType === IntegrationType.Postscript) {
            return 'https://app.postscript.io/account/api';
        } else if (this.integrationType === IntegrationType.RecartSMS) {
            return 'https://app.recart.com/settings/general';
        } else if (this.integrationType === IntegrationType.Sendinblue) {
            return 'https://app.brevo.com/settings/keys/api';
        } else if (this.integrationType === IntegrationType.Sendlane) {
            return 'https://app.sendlane.com/api';
        } else if (this.integrationType === IntegrationType.SMSBump) {
            return 'https://smsbump.com/apps/integration';
        } else if (this.integrationType === IntegrationType.SmartrMail) {
            return 'https://go.smartrmail.com/en/new_frontend#/settings/integrations';
        } else if (this.integrationType === IntegrationType.Weglot) {
            return 'https://dashboard.weglot.com/settings/setup';
        } else {
            return '#';
        }
    }

    private findIntegrationSetup(type: IntegrationType): Integration {
        let integration = this.integrations.find((integ: Integration) => {
            if (type === IntegrationType.SMSBump || type === IntegrationType.Mobiniti || type === IntegrationType.Postscript || type === IntegrationType.Attentive 
                || type === IntegrationType.Emotive || type === IntegrationType.RecartSMS) {
                return integ.type === IntegrationType.SMSBump || integ.type === IntegrationType.Mobiniti || integ.type === IntegrationType.Postscript || integ.type === IntegrationType.Attentive
                    || integ.type === IntegrationType.Emotive || integ.type === IntegrationType.RecartSMS;
            } else if (type === IntegrationType.Kickbox) {
                return integ.type === IntegrationType.Kickbox;
            } else if (type === IntegrationType.EmailListVerify) {
                return integ.type === IntegrationType.EmailListVerify;
            } else if (type === IntegrationType.PushOwl) {
                return integ.type === IntegrationType.PushOwl;
            } else if (type === IntegrationType.Weglot) {
                return integ.type === IntegrationType.Weglot;
            } else if (type === IntegrationType.Firepush) {
                return integ.type === IntegrationType.Firepush;
            }
            else {
                return (integ.type !== IntegrationType.SMSBump && integ.type !== IntegrationType.Mobiniti
                    && integ.type !== IntegrationType.EmailListVerify && integ.type !== IntegrationType.Kickbox
                    && integ.type !== IntegrationType.PushOwl && integ.type !== IntegrationType.Postscript
                    && integ.type !== IntegrationType.Firepush && integ.type !== IntegrationType.Weglot
                    && integ.type !== IntegrationType.Attentive && integ.type !== IntegrationType.Emotive
                    && integ.type !== IntegrationType.RecartSMS);
            }
        });
        if (integration === undefined) {
            integration = {
                id: '',
                type: IntegrationType.None,
                settings: [],
                enabled: false,
                active: false,
                shopId: this.shopId,
                doubleOptIn: false
            };

            if (type === IntegrationType.Kickbox || type === IntegrationType.EmailListVerify) {
                integration.type = type;
                integration.kickboxIntegration = {
                    verifications: 0
                };
            }
        }
        return integration;
    }

    get enableDoubleOptInt() {
        return this.integration.type !== IntegrationType.ConvertKit && this.integration.type !== IntegrationType.Sendinblue
            && this.integration.type !== IntegrationType.Sendlane && this.integration.type !== IntegrationType.Mailjet
            && this.integration.type !== IntegrationType.Omnisend && this.integration.type !== IntegrationType.SmartrMail
            && this.integration.type !== IntegrationType.Klaviyo&& this.integration.type !== IntegrationType.Emotive;
    }

    get enableSMSDoubleOptInt() {
        return this.integrationSMS.type !== IntegrationType.SMSBump && this.integrationSMS.type !== IntegrationType.Attentive && this.integrationSMS.type !== IntegrationType.Emotive;
    }

    get appInstallDialogTransKey() {
        if (this.integrationType === IntegrationType.None) {
            return IntegrationType.PushOwl.toLowerCase();
        }
        return this.integrationType.toLowerCase();
    }

    get appInstallUrl() {
        return `https://apps.shopify.com/${this.appInstallDialogTransKey}?utm_source=wheelio&utm_medium=in-app&utm_campaign=enable-integration`;
    }

    private installPushOwl() {
        window.open(this.appInstallUrl);
    }

    get firepushTOS() {
        // @ts-ignore
        const text = this.$t(`integration.firepush_legal_copy_lang.${this.integrationFirepush.firepushIntegration.settings.key}`).toString().replace('[BRAND_NAME]', this.integrationFirepush.firepushIntegration?.brandName);
        // @ts-ignore
        this.integrationFirepush.firepushIntegration.settings.value = text;
        return text;
    }

    private handleScroll() {
        this.mainview = document.getElementById('mainview');
        if (this.mainview != null) {
            if (window.pageYOffset > this.mainview.offsetTop) {
                if(this.toolbarSticky === false) {
                    this.toolbarSticky = true;
                }
            }
            else {
                if(this.toolbarSticky === true) {
                    this.toolbarSticky = false;
                }
            }
        }
    }

    private beforeRouteLeave(to: Route, _from: Route, next: any) {
        if (this.hasChanged) {
            next(false);
            this.newPath = to.name;
            this.showCancelChangesDialog = true;
        } else {
            next();
        }
    }

    @Watch('cancelChangesDialogResponse', { deep: true })
    private onResponseChanged(value: boolean, _oldValue: boolean) {
        if (value === true) {
            this.hasChanged = false;
            this.$router.push({
                name: this.newPath,
            });
        }
    }
}
