
import { Component, Vue, Prop, Watch  } from 'vue-property-decorator';
import i18n from '../plugins/i18n';
import { ICampaign } from '../models/Campaign';
import { Theme, ThemeImagesPosition, Color, Images } from '../models/App';
import ProgressBar from '@/components/ProgressBar.vue';
import Recart from '@/components/integrations/Recart.vue';
import { TemplatesHelpers } from '../helpers/templatesHelpers';
import store from '../store/index';

interface ISlice {
    id: number;
    gravity: number;
    text: string;
    code: string;
    email: string;
    fill: string;
}

@Component({
    components: {
        ProgressBar,
        Recart
    }
})
export default class WheelioTemplate extends Vue {
    @Prop(Object) public campaign!: ICampaign;
    @Prop(Object) public themeImages!: Images;
    @Prop(Number) public swapView!: number;

    private selectedTheme: Theme = { text: '', value: '', selected: false, images: { background: '', top: '', bottom: '', pulltab: '', wheel: '' }};
    private selectedColors: Color = {
        theme: '',
        backgroundColor: { hex: '' },
        wheelFontColor: { hex: '' },
        fontColor: { hex: '' },
        buttonColor: { hex: '' },
        sliceColorSet1: { hex: '' },
        sliceColorSet2: { hex: '' },
        sliceColorSet3: { hex: '' },
        sliceColorSet4: { hex: '' },
        pointerColor: { hex: ''},
        buttonFontColor: { hex: ''}
    };

    private visible = true;
    private previewText = i18n.t('label.hide');
    private gdprCheckbox = false;
    private WheelioContainer = document.getElementById('wheelio');

    private created() {
        this.$nextTick(function() {
            // load app html from iframe for phone simulator
            if (this.isPhoneView) {
                // @ts-ignore
                this.WheelioContainer = document.getElementById('phone-simulator').contentWindow.document.body as HTMLElement;
            } else {
                this.WheelioContainer = document.getElementById('wheelio');
            }

            this.applyTheme();

            // hide pull tab for phone simulatior view
            if (this.isPhoneView) {
                // @ts-ignore
                const pullTab = this.WheelioContainer.getElementsByClassName('wlo_pulltab_holder')[0] as HTMLElement;
                pullTab.style.display = 'none';
            }
        })
    }

    private StartSpin() {
        const temlatesHelpers = new TemplatesHelpers(this.WheelioContainer ?? new HTMLElement(), this.campaign);
        temlatesHelpers.StartSpin(this.gdprCheckbox);
    }

    private Collect() {
        // @ts-ignore
        store.commit('showSuccessSnackbar', this.campaign.app.texts.copiedMessage);
    }

    @Watch('themeImages', { deep: true })
    private onAppSelectedImagesChanged() {
        this.applyTheme();
    }

    get invertColor() {
        const hex = this.selectedColors.backgroundColor.hex.replace('#', '');
        const C_R = parseInt(hex.substr(0, 2), 16);
        const C_G = parseInt(hex.substr(2, 2), 16);
        const C_B = parseInt(hex.substr(4, 2), 16);
        const brightness = ((C_R * 299) + (C_G * 587) + (C_B * 114)) / 1000;
        return brightness < 200;
    }

    private applyTheme() {
        // @ts-ignore
        this.selectedTheme = this.campaign.app.themes.findWith('selected', true);
        const selectedThemeValue = this.selectedTheme.origin != null && this.selectedTheme.origin !== '' ? this.selectedTheme.origin : this.selectedTheme.value;
        // @ts-ignore
        this.selectedColors = this.campaign.app.colors.findWith('theme', this.selectedTheme.value);
        // @ts-ignore
        const wheelio = this.WheelioContainer.querySelector('#panel');
        // @ts-ignore
        const wheelHolder = this.WheelioContainer.querySelector('.wheel_holder');
        // @ts-ignore
        const logo = this.WheelioContainer.querySelector('.wlo_logo');

        if (wheelio !== null) {
            // remove existing elements
            // @ts-ignore
            const themeItems = Array.from(this.WheelioContainer.querySelectorAll('.theme-item'));
            themeItems.forEach((el) => {
                el.remove();
            });
            wheelio.classList.remove('wlo_christmas_snow');
            if (logo != null && logo !== undefined) {
                logo.classList.remove('wlo_stpatric');
            }

            // add new elements
            const theme: ThemeImagesPosition[] = [
                { type: 'bg', value: this.themeImages.background },
                { type: 'bottom', value: this.themeImages.bottom },
                { type: 'pulltab', value: this.themeImages.pulltab },
                { type: 'top', value: this.themeImages.top },
                { type: 'wheel', value: this.themeImages.wheel },
            ];

            theme.forEach((el) => {
                if (el.value !== '' && el.value !== null) {
                    const div = document.createElement('img');
                    div.classList.add('wlo_' + selectedThemeValue + '_' + el.type, 'theme-item');
                    div.setAttribute('src', el.value);
                    switch (el.type) {
                        case 'bg': {
                            const bg = document.createElement('div');
                            bg.classList.add('wlo_' + selectedThemeValue + '_' + el.type, 'theme-item');
                            bg.setAttribute('style', 'background-image: url(' + el.value + ');');
                            wheelio.prepend(bg);
                            break;
                        }
                        case 'bottom': {
                            wheelio.prepend(div);
                            break;
                        }
                        case 'pulltab': {
                            if (selectedThemeValue === 'christmas') {
                                wheelio.prepend(div);
                                wheelio.classList.add('wlo_christmas_snow');

                            } else {
                                wheelio.prepend(div);
                            }
                            break;
                        }
                        case 'top': {
                            wheelio.prepend(div);
                            break;
                        }
                        case 'wheel': {
                            if (wheelHolder !== null) {
                                wheelHolder.prepend(div);
                            }
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                }
            });

            if (selectedThemeValue === 'stpatric' && logo != null && logo !== undefined) {
                logo.classList.add('wlo_stpatric');
            }
        }
    }

    private getInputClass(type: string) {
        if (type === 'text') {
            return 'wlo_email_input';
        } else if (type === 'checkbox') {
            return 'wlo_checkbox wlo_hidden';
        } else {
            return '';
        }
    }

    get isPhoneView() {
        return document.getElementById('phone-simulator') != null;
    }

    get gdprEnable() {
        return this.campaign.app?.gdprEnable;
    }
}
