/* tslint:disable:no-console */

import { register } from 'register-service-worker';
import store from './store/index';
import i18n from './plugins/i18n';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log('App is being served from cache by a service worker.\n' + 'For more details, visit https://goo.gl/AFskqB');
    },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated(registration: ServiceWorkerRegistration) {
      console.log('New content is available; please refresh.');
      store.commit('showServiceWorkerSnackbar',  {text: i18n.t(`errors.server.NewContentFound`), onClickText: i18n.t(`errors.server.Reload`), onClick: () => {onConfirm()}});
      const worker = registration.waiting || new ServiceWorker();
      function onConfirm() {
        worker.postMessage({ action: 'skipWaiting' });
      }
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    }
  });

  let refreshing: boolean;
  navigator?.serviceWorker?.addEventListener('controllerchange', () => {
    if (refreshing) {
      return;
    }
    window.location.reload();
    refreshing = true;
  });
}
