
import { Component, Vue } from 'vue-property-decorator';
import { ForgotPass } from '../../models/ForgotPass';
import { ErrorHandler } from '../../helpers/errorHandler';
import i18n from '../../plugins/i18n';

@Component({})
export default class ActivateAccount extends Vue {
    private show: boolean = true;
    private actionLoading: boolean = false;
    private inProgress: boolean = true;
    private forgotPass: ForgotPass = {
        email: '',
    };
    private error: boolean = false;
    private rules: any = {
        emailRules: [
            (value) => !!value || i18n.t('validation.required'),
            (value) => /.+@.+/.test(value) || i18n.t('validation.email'),
        ],
    };
    private async reset() {
        // @ts-ignore
        if (this.$refs.form.validate()) {
            this.actionLoading = true;
            try {
                await this.axios.post('api/account/sendactivation', this.forgotPass);
                this.inProgress = false;
            } catch (error: any) {
                new ErrorHandler().Server(error);
            } finally {
                this.actionLoading = false;
            }
        }
    }

    private login() {
        this.$router.push({ name: 'login' });
    }
}

