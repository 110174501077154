
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Sketch, Chrome } from 'vue-color';
import { App, Color, Theme, Images } from '../models/App';
import { ErrorHandler } from '../helpers/errorHandler';
import { Helpers} from '../helpers/helpers';
import i18n from '../plugins/i18n';
import Tooltip from '@/components/Tooltip.vue';
import store from '../store/index';

@Component({
    components: {
        'sketch-picker': Sketch,
        'chrome-picker': Chrome,
        Tooltip,
  },
})
export default class AppColors extends Vue {
    @Prop(Boolean) public hasChanged!: boolean;
    @Prop(Object) public app!: App;
    @Prop(String) public appType!: string;
    @Prop(Object) public themeImages!: Images;

    private helpers = new Helpers();
    private newTheme: Theme = { text: '', value: '', selected: false, images: { background: '', top: '', bottom: '', pulltab: '', wheel: '' }};
    private selectedTheme: Theme = { text: '', value: '', selected: false, images: { background: '', top: '', bottom: '', pulltab: '', wheel: '' }};
    private selectedColors: Color = {
        theme: '',
        backgroundColor: { hex: '' },
        wheelFontColor: { hex: '' },
        fontColor: { hex: '' },
        buttonColor: { hex: '' },
        sliceColorSet1: { hex: '' },
        sliceColorSet2: { hex: '' },
        sliceColorSet3: { hex: '' },
        sliceColorSet4: { hex: '' },
        pointerColor: { hex: ''},
        buttonFontColor: { hex: ''}
    };
    private selectedThemeIndex: number = 0;
    private imageFile: object = {};
    private imageType: string = '';

    private backgroundColorVisible: boolean = false;
    private wheelFontColorVisible: boolean = false;
    private fontColorVisible: boolean = false;
    private buttonColorVisible: boolean = false;
    private color1Visible: boolean = false;
    private color2Visible: boolean = false;
    private color3Visible: boolean = false;
    private color4Visible: boolean = false;
    private pointerColorVisible: boolean = false;
    private buttonFontColorVisible: boolean = false;

    private newThemeTitle: string = '';
    private dialog: boolean = false;

    private error: boolean = false;
    private rules: any = {
        required: (value) => !!value || i18n.t('validation.required'),
        uploadLimit: (value) => !value || value.size < 50000 || i18n.t('validation.uploadLimit'),
        uniquieName: (value) => !value || this.checkThemeName() || i18n.t('validation.themeNameExists')
    };

    private uploadBigProgress: boolean = false;
    private uploadSmallProgress: boolean = false;

    private themeChaged(value: any) {
        this.app.themes.forEach((theme: Theme) => theme.selected = false);
        this.app.themes.forEach((theme: Theme) => {
            if (theme.value === value) {
                theme.selected = true;
            }
        });

        this.selectedTheme = Object.assign({}, this.app.themes.findWith('selected', true));
        this.selectedColors = this.app.colors.findWith('theme', value);
        this.$emit('update:themeImages', JSON.parse(JSON.stringify(this.selectedTheme.images)));
    }

    private created() {
        this.selectedTheme = this.app.themes.findWith('selected', true);
        this.selectedColors = this.app.colors.findWith('theme', this.selectedTheme.value);
    }

    private pickFile(type: string) {
        this.imageType = type;
    }

    private async onFilePicked(e: any) {
        const file = e;
        if (file != null && file.size <= 50000) {
            if (file.name.lastIndexOf('.') <= 0) {
                return;
            }
            await this.uploadeFile(file);
        }
    }

    private async uploadeFile(file: any) {
        if (this.imageType === 'big') {
            this.uploadBigProgress = true;
        } else {
            this.uploadSmallProgress = true;
        }

        const data = new FormData();
        // @ts-ignore
        data.append('file', file, file.name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, ''));

        try {
            const response = await this.axios.post(`/api/campaign/uploadfile/${this.app.id}/${this.imageType}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (this.imageType === 'big') {
                this.app.bigLogo = response.data.data;
            } else if (this.imageType === 'small') {
                this.app.smallLogo = response.data.data;
            } else {
                this.themeImages[this.imageType] = response.data.data;
                this.$emit('update:themeImages', this.themeImages);
            }
            this.$store.commit('showSuccessSnackbar', i18n.t('campaign.colors.image_uploaded'));

        } catch (error: any) {
            new ErrorHandler().Server(error);

        } finally {
            this.uploadBigProgress = this.uploadSmallProgress = false;
        }
    }

    private checkThemeName() {
        let result = true;
        this.app.themes.forEach((theme: Theme) => {
            if (theme.text.toLowerCase() === this.newThemeTitle.toLowerCase()) {
                result = false;
            }
        });
        return result;
    }

    private toggleColorPicker(name: string) {
        this.backgroundColorVisible = this.wheelFontColorVisible = this.buttonColorVisible = this.fontColorVisible = this.color1Visible =
        this.color2Visible = this.color3Visible = this.color4Visible = this.pointerColorVisible = this.buttonFontColorVisible = false;
        if (name === 'backgroundColor') {
            this.backgroundColorVisible = !this.backgroundColorVisible;
        } else if (name === 'wheelFontColor') {
            this.wheelFontColorVisible = !this.wheelFontColorVisible;
        } else if (name === 'fontColor') {
            this.fontColorVisible = !this.fontColorVisible;
        } else if (name === 'buttonColor') {
            this.buttonColorVisible = !this.buttonColorVisible;
        } else if (name === 'sliceColorSet1') {
            this.color1Visible = !this.color1Visible;
        } else if (name === 'sliceColorSet2') {
            this.color2Visible = !this.color2Visible;
        } else if (name === 'sliceColorSet3') {
            this.color3Visible = !this.color3Visible;
        } else if (name === 'sliceColorSet4') {
            this.color4Visible = !this.color4Visible;
        } else if (name === 'pointerColor') {
            this.pointerColorVisible = !this.pointerColorVisible;
        } else if (name === 'buttonFontColor') {
            this.buttonFontColorVisible = !this.buttonFontColorVisible;
        }
    }

    private isPickerVisible() {
        if (this.backgroundColorVisible || this.wheelFontColorVisible ||
            this.fontColorVisible || this.buttonColorVisible ||
            this.color1Visible || this.color2Visible ||
            this.color3Visible || this.color4Visible ||
            this.pointerColorVisible || this.buttonFontColorVisible) {
            return true;
        } else {
            return false;
        }
    }

    private async removeImage(imageType: string) {
        if (imageType === 'big') {
            this.uploadBigProgress = true;
        } else {
            this.uploadSmallProgress = true;
        }

        try {
            await this.axios.delete(`/api/campaign/removefile/${this.app.id}/${imageType}`);
            if (imageType === 'big') {
                this.app.bigLogo = null;
            } else if (imageType === 'small') {
                this.app.smallLogo = null;
            } else {
                this.themeImages[imageType] = '';
                this.$emit('update:themeImages', this.themeImages);
            }
        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.uploadBigProgress = this.uploadSmallProgress = false;
        }
    }

    private newThemeChaged(value: string) {
        this.newTheme = this.app.themes.findWith('value', value);
        this.newTheme.origin = value;
    }

    private newThemeAction() {
        this.newTheme = Object.assign({}, this.selectedTheme);
        this.newTheme.text = '';
        this.newTheme.origin = this.selectedTheme.origin ?? this.selectedTheme.value;
        this.dialog = true;
    }

    private saveTheme() {
        // @ts-ignore
        if (this.$refs.form.validate()) {
            const newTheme = Object.assign({}, this.newTheme);
            newTheme.text = this.newThemeTitle;
            newTheme.value = this.newThemeTitle.replace(/ /g, '').toLowerCase();
            this.app.themes.push(newTheme);

            let selectedColors = this.app.colors.findWith('theme', this.newTheme.value);
            selectedColors = Object.assign({}, selectedColors);
            selectedColors.theme = newTheme.value;
            this.app.colors.push(selectedColors);

            this.$emit('update:selectedColors', selectedColors);
            // this.$emit('update:themeImages', newTheme.images);

            this.themeChaged(newTheme.value);
            this.close();

            store.commit('showSuccessSnackbar', i18n.t('campaign.colors.save_theme_msg').toString());
        }
    }

    private removeTheme() {
        this.app.themes = this.app.themes.filter((theme: Theme) => {
            return theme.value !== this.selectedTheme.value;
        });
        this.app.colors = this.app.colors.filterWithDiff('theme', this.selectedTheme.value);

        this.themeChaged('default');
        this.$emit('update:themeImages', this.selectedTheme.images);
    }

    private close() {
        // @ts-ignore
        this.$refs.form.resetValidation();
        // @ts-ignore
        // TODO: find another soution
        // this.$refs.form.reset();
        this.dialog = false;
    }

    get disableRemoveTheme() {
        return this.selectedTheme.value === 'default'
            || this.selectedTheme.value === 'valentines'
            || this.selectedTheme.value === 'stpatric'
            || this.selectedTheme.value === 'easter'
            || this.selectedTheme.value === '4thofjuly'
            || this.selectedTheme.value === 'halloween'
            || this.selectedTheme.value === 'blackfriday'
            || this.selectedTheme.value === 'christmas';
    }

    get uploadSmallDisabled() {
        return this.app.smallLogo == null;
    }

    get uploadBigDisabled() {
        return this.app.bigLogo == null;
    }

    private uploadDisabled(type: string) {
        return (this.themeImages[type] == null || this.themeImages[type] === '') || this.hasChanged;
    }

    private updateSelection(app: App) {
        this.selectedTheme = app.themes.findWith('selected', true);
        this.selectedColors = app.colors.findWith('theme', this.selectedTheme.value);
        this.$emit('update:themeImages', this.selectedTheme.images);
    }

    get mainThemes() {
        return this.app.themes.filter(theme => theme.origin === null || theme.origin === theme.value);
    }
}
