
import { Component, Vue, Prop, Watch, Model} from 'vue-property-decorator';
import { App } from '../../models/App';
import {Helpers} from '../../helpers/helpers';
import {Line} from 'vue-chartjs';
import vueMoment from 'vue-moment';
import * as moment from 'moment';
import i18n from '../../plugins/i18n';

interface IStats {
    createdDate: string;
    collect: number;
    spin: number;
    show: number;
    reject: number;
    deviceType: string;
}
// @ts-ignore
@Component({
    // @ts-ignore
    extends: Line, // this is important to add the functionality to your component
})
export default class ResultsChart extends Vue<Line> {
    @Prop(Array) public stats!: [IStats];
    @Prop(String) public title!: string;
    private createdDate: string[] = [];
    private collect: number[] = [];
    private spin: number[] = [];
    private show: number[] = [];
    private reject: number[] = [];
    private mounted() {
        this.stats.forEach((el) => {
            // @ts-ignore
            this.createdDate.push(el.createdDate);
            this.show.push(el.show);
            this.spin.push(el.spin);
            this.collect.push(el.collect);
            this.reject.push(el.reject);
        });
        this.renderChart({
            // scaleStartValue: 0,
            labels: this.createdDate,
            datasets: [{
                    label: i18n.t('campaign.statistics.displayed').toString(),
                    backgroundColor: '#5f9aba',
                    borderColor: '#5f9aba',
                    data: this.show,
                    fill: false,
                },
                {
                    label: i18n.t('campaign.statistics.spinned').toString(),
                    backgroundColor: '#f87979',
                    borderColor: '#f87979',
                    data: this.spin,
                    fill: false,
                },
                {
                    label: i18n.t('campaign.statistics.rejected').toString(),
                    backgroundColor: '#c7dbcd',
                    borderColor: '#c7dbcd',
                    data: this.reject,
                    fill: false,
                    hidden: true,
                },
            ],
        }, {
            responsive: true,
            maintainAspectRatio: false,
            title: {
                display: true,
                text: this.title,
            },
            scales: {
                yAxes: [{
                    ticks: {
                        suggestedMin: 0,
                    },
                }],
            },
            // @ts-ignore
            style: 'background-color: green;'
        });
    }
}
