
import {
    Component,
    Vue,
    Prop,
    Watch,
    Model
} from 'vue-property-decorator';
import {
    PricingPlans
} from '../../helpers/pricingPlan';
import { Trans } from '../../plugins/translation';
@Component({
    components: {
    }
})
export default class ReviewDialog2 extends Vue {
    @Prop(Boolean) public showDialog!: boolean;
    @Prop(String) public currency!: string;
    private rating: number = 5;
    private pricingPlans = new PricingPlans();

    private clientInstance: object = {};

    private closeDialog() {
        this.$emit('update:showDialog', false);
        if (this.clientInstance !== null && this.clientInstance.hasOwnProperty('teardown')) {
            // @ts-ignore
            this.clientInstance.teardown();
        }
    }

    private onClickReview() {
        Trans.setCookie('wheelio-review-cookie', 'true', 365)
        // window.open('https://apps.shopify.com/wheelio-first-interactive-exit-intent-pop-up/reviews', '_blank');
        window.open('https://apps.shopify.com/wheelio-first-interactive-exit-intent-pop-up?#modal-show=ReviewListingModal', '_blank')
        this.closeDialog();
    }
}
