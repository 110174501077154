
import Component from 'vue-class-component';
import { Vue, Watch, Prop } from 'vue-property-decorator';
import * as moment from 'moment';
import { ErrorHandler } from '../helpers/errorHandler';
import { Helpers } from '../helpers/helpers';
import i18n from '../plugins/i18n';
import { Trans } from '../plugins/translation';
import subMonths from 'date-fns/subMonths';

@Component({
    components: {}
})

export default class Setup extends Vue {
    @Prop(String) public someData!: string;
    private expanded: [] = [];
    private menuStart:boolean = false;
    private menuEnd:boolean = false;
    private dateStart: string = subMonths(new Date(), 1).toISOString().substr(0, 10);
    private dateEnd: string = new Date().toISOString().substr(0, 10);
    private headers = [{
            text: i18n.t('report.created_date'),
            sortable: true,
            value: 'createdDate',
        },
        {
            text: i18n.t('report.type'),
            sortable: true,
            value: 'type',
        },
        {
            text: i18n.t('report.size'),
            sortable: false,
            value: 'length',
        },
        {
            text: i18n.t('report.status'),
            sortable: true,
            value: 'status',
        },
        {
            value: 'action',
        },
    ];
    private noDataText = i18n.t('export_emails.no_data');
    private loading: boolean = false;
    private loadingReports: boolean = false;
    private loadingTable: boolean = false;
    private actionExportCsv: boolean = false;
    private helpers = new Helpers();
    private userID: string = '';
    private shops: object[] = [];
    private campaigns: object[] = [];
    private selectedShops: object[] = [];
    private selectedCampaigns: object[] = [];
    private reports: object[] = [];
    private interval;
    private async created() {
        this.getReportFilters();
    }

    private async getReportFilters() {
        this.loading = true;
        try {
            const response = await this.axios.get('/api/services/getreportfilters');
            const data = response.data.data;
            this.shops = data.shops;
            this.campaigns = data.campaigns;
        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.loading = false;
        }
    }

    private async generateCSV() {
        const csvData: object = {
            shops: this.selectedShops,
            campaigns: this.selectedCampaigns,
            startDate: this.dateStart,
            endDate: this.dateEnd
        };
        this.loadingTable = true;
        try {
            const response = await this.axios.post(`/api/services/generatecsv`, csvData);
            this.reports.push(response.data.data);
        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.loadingTable = false;
            this.$store.commit('showSuccessSnackbar', i18n.t('export_emails.generate_tooltip2'));
            this.interval = setInterval(this.getReports, 10000);
        }
    }

    private async getReports() {       
        this.loadingReports = true;
        try {
            const response = await this.axios.get(`/api/services/getreports`);
            this.reports = response.data.data;
            if (this.reports.length <= 0) {
                this.noDataText =  i18n.t('export_emails.no_data_found');
            }
        } catch (error: any) {
            clearInterval(this.interval);
            new ErrorHandler().Server(error);
        } finally {
            this.loadingReports = false;
            let reportsGenerating: boolean = false;
            this.reports.forEach(report => {
                // @ts-ignore
                if(report.status === 'Generating') {
                    reportsGenerating = true;
                }
            });
            if (!reportsGenerating) {
                clearInterval(this.interval);
            }
        }
    }

    private async downloadReport(report) {
        if (report.status !== 'Completed') {
            return;
        }

        try {
            this.loadingTable = this.actionExportCsv = true;

            const response = await this.axios.get(`/api/services/downloadreport/${report.id}`, { responseType: 'blob' });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            const name = `email_export_${new Date(report.createdDate).getTime()}.csv`;

            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.loadingTable = this.actionExportCsv = false;
        }
    }

    private formatBytes(bytes, decimals = 0) {
        if (bytes === 0) {
            return '0';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals || 2;
        const sizes = ['B', 'KB', 'MB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    private destroyed() {
        clearInterval(this.interval);
    }

    private getUserLang() {
        return Trans.getUserLang().lang;
    }
}

