
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import ResultsChart from '@/components/stats/ResultsChart.vue';
import PieChart from '@/components/stats/PieChart.vue';
import BarChart from '@/components/stats/BarChart.vue';
import QuickStats from '@/components/stats/QuickStats.vue';
import { AppStats } from '../models/AppStats';
import { ErrorHandler } from '../helpers/errorHandler';
import { Helpers } from '../helpers/helpers';
import i18n from '../plugins/i18n';
import { Trans } from '../plugins/translation';
import VCalendar from 'v-calendar';
// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});
interface IStats {
    createdDate: string;
    collect: number;
    spin: number;
    show: number;
    reject: number;
    revenue: number;
    deviceType: string;
}
interface IStatsPie {
    desktop: number;
    mobile: number;
    tablet: number;
}

// @ts-ignore
@Component({
    // @ts-ignore
    components: {
        ResultsChart,
        PieChart,
        QuickStats,
        BarChart
    },
})

export default class AppStatistics extends Vue {
    @Prop(Boolean) public freeSpin!: boolean;
    @Prop(String) public shopType!:  string;
    private tab: number = 0;
    private loading: boolean = true;
    private grafStats: IStats[] = [];
    private pieSats: IStats[] = [];
    private conversionRate: number = 0;
    private currentDate: Date = new Date();
    private menu: boolean = false;
    private range = {
        start: this.subtractDays(this.currentDate, 30),
        end: this.currentDate
    }
    private parsedStatsAll: IStats[] = [];
    private parsedStatsDesktop: IStats[] = [];
    private parsedStatsMobile: IStats[] = [];
    private parsedStatsTablet: IStats[] = [];
    private chartKeys: number[] = [1, 11, 111, 1111];
    private pieKeys: number[] = [1, 11, 111, 1111];
    private helpers = new Helpers();

    private created() {
        this.getStats();
    }

    private async getStats() {
        const reportsData: object = {
            campaignId: this.$route.params.id,
            startDate: this.range.start,
            endDate: this.range.end,
        };

        try {
            this.loading = true;
            const response = await this.axios.post(`/api/campaign/getstats`, reportsData);
            const data = response.data.data;
            if (data.length <= 0) {
                this.grafStats = [];
            } else {
                this.grafStats = data[0].grafStats;
            }

            this.parsedStatsAll = this.parseStats('All');
            this.parsedStatsDesktop = this.parseStats('Desktop');
            this.parsedStatsMobile = this.parseStats('Mobile');
            this.parsedStatsTablet = this.parseStats('Tablet');
            this.updateCharts();
        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.loading = false;
        }
    }

    private parseStats(device: string) {
        let prevObject: IStats = {createdDate: '', collect: 0, show: 0, spin: 0, reject: 0, revenue: 0, deviceType: ''};
        const result: IStats[] = [];
        let i = 0;
        this.setNewObjects(result);

        if (result.length > 0) {
            while (i < result.length) {
                const el = result[i];
                // @ts-ignore
                el.createdDate = (el.createdDate != null ? this.helpers.formatToDate(el.createdDate) : '');
                if (device !== 'All') {
                    if (el.deviceType !== device) {
                        el.collect = 0;
                        el.spin = 0;
                        el.show = 0;
                        el.reject = 0;
                        el.revenue = 0;
                    }
                }
                if (prevObject.createdDate === el.createdDate) {
                    el.collect = prevObject.collect + el.collect;
                    el.spin = prevObject.spin + el.spin;
                    el.show = prevObject.show + el.show;
                    el.reject = prevObject.reject + el.reject;
                    el.revenue = prevObject.revenue + el.revenue;
                    result.splice(i - 1, 2, el);
                } else {
                    i++;
                }
                prevObject = Object.assign({}, el);
            }
        }
        return result;
    }

    private setNewObjects(newObject: IStats[]) {
        this.grafStats.forEach((el) => {
            newObject.push(Object.assign({}, el));
        });
    }

    private parseStatsPie(type: string) {
        let data: number = 0;
        const result: IStatsPie = {desktop: 0, mobile: 0, tablet: 0};
        this.grafStats.forEach((el) => {
            if (type === 'Show') {
                data = el.show;
            } else if (type === 'Spin') {
                data = el.spin;
            } else if (type === 'Collect') {
                data = el.collect;
            } else if (type === 'Reject') {
                data = el.reject;
            }

            if (el.deviceType === 'Desktop') {
                result.desktop += data;
            } else if (el.deviceType === 'Mobile') {
                result.mobile += data;
            } else if (el.deviceType === 'Tablet') {
                result.tablet += data;
            }
        });
        return(result);
    }

    private parseStatsConversion() {
        const result: IStatsPie = {desktop: 0, mobile: 0, tablet: 0};
        const dataMobile = {spin: 0, show: 0};
        const dataDesktop = {spin: 0, show: 0};
        const dataTablet = {spin: 0, show: 0};
        this.grafStats.forEach((el) => {
            if (el.deviceType === 'Desktop') {
                dataDesktop.spin += el.spin;
                dataDesktop.show += el.show;
                result.desktop =  Math.round(dataDesktop.spin / dataDesktop.show * 100);
            } else if (el.deviceType === 'Mobile') {
                dataMobile.spin += el.spin;
                dataMobile.show += el.show;
                result.mobile =  Math.round(dataMobile.spin / dataMobile.show * 100);
            } else if (el.deviceType === 'Tablet') {
                dataTablet.spin += el.spin;
                dataTablet.show += el.show;
                result.tablet =  Math.round(dataTablet.spin / dataTablet.show * 100);
            }
        });
        return(result);
    }

    private showPie(data: IStatsPie) {
        let result: boolean = false;
        for (const key in data) {
            if(data[key] > 0) {
                result = true;
            }
        }
        return result;
    }

    private updateCharts() {
        this.chartKeys.forEach((_el, i) => {
            this.chartKeys[i] += 1;
        });
        this.pieKeys.forEach((_el, i) => {
            this.pieKeys[i] += 1;
        });
    }

    @Watch('range')
    private onPropertyChanged() {
        this.getStats();
    }

    private getUserLang() {
        return Trans.getUserLang().lang;
    }

    private subtractDays(date, days) {
        const result = new Date(date);
        result.setDate(result.getDate() - days);
        return result;
    }
}
