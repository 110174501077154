
import Vue from 'vue';
import moment from 'moment-timezone'
import vueMoment from 'vue-moment';

moment.locale(sessionStorage.locale || 'en');

Vue.use(vueMoment, {
  moment,
});
