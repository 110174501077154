
import { Component,  Vue, Watch } from 'vue-property-decorator';
import { ErrorHandler } from '../helpers/errorHandler';
import { ShopStats } from '../models/ShopStats';
import { ICampaign } from '../models/Campaign';
import { Route } from 'vue-router';
import { Helpers } from '../helpers/helpers';

@Component({})
export default class Admin extends Vue {
    private loading: boolean = false
    private campaignLoading: boolean = false;
    private actionLoading: boolean = false;
    private model: object = {};
    private searchValue: string = '';
    private searchItems: ShopStats[] = [];
    private pagination = {
        rowsPerPage: 50,
        page: 1,
        totalItems: 0,
        rowsPerPageItems: [10, 20, 50, 100, -1],
    };
    private shops: ShopStats[] = [];
    private currentShopName: string = '';
    private campaignList: ICampaign[] = [];
    private helpers = new Helpers();

    private headers = [
        {
            text: 'Name',
            sortable: true,
            value: 'name'
        },
        {
            text: 'Email',
            sortable: true,
            value: 'email'
        },
        {
            text: 'Type',
            sortable: true,
            value: 'type'
        },
        {
            text: 'Plan',
            sortable: true,
            value: 'planName'
        },
        {
            text: 'Domain',
            sortable: true,
            value: 'domain'
        },
        {
            text: 'Show',
            sortable: true,
            value: 'show'
        },
        {
            text: 'Spin',
            sortable: true,
            value: 'spin'
        },
        {
            text: 'Reject',
            sortable: true,
            value: 'reject'
        },
        {
            text: 'Conversion(%)',
            sortable: true,
            value: 'conversionRate'
        },
        {
            text: 'Current Show',
            sortable: true,
            value: 'currentShow'
        },
        {
            text: 'Revenue',
            sortable: true,
            value: 'revenue'
        },
        { text: '', value: 'actions', sortable: false }
    ];

    private campaignHeaders = [
        {
            text: 'Name',
            sortable: true,
            value: 'name'
        },
        {
            text: 'Created',
            sortable: true,
            value: 'createdDate'
        },
        {
            text: 'Modified',
            sortable: true,
            value: 'modifiedDate'
        },
        {
            text: 'Active',
            sortable: true,
            value: 'active'
        },
        {
            text: 'AppType',
            sortable: true,
            value: 'appType'
        },
        {
            text: 'Show',
            sortable: true,
            value: 'show'
        },
        {
            text: 'Spin',
            sortable: true,
            value: 'spin'
        },
        {
            text: 'Conversion Rate(%)',
            sortable: true,
            value: 'conversionRate'
        },
        { text: '', value: 'actions', sortable: false }
    ];

    @Watch('searchValue')
    private onSearchValueChanged(value: string) {
        if (this.actionLoading === false && value != null && value.length >= 3) {
            this.actionLoading = true;
            this.searchShops(value);
        }
    }

    private async created() {
        if (this.$route.params.id !== undefined) {
            await this.getCampaigns(this.$route.params.id);
        } else {
            await this.getShops();
        }
    }

    private async getShops() {
        try {
            this.loading = true;
            const response = await this.axios.get(`/api/admin/getshops`);
            this.shops = response.data.data;
        } catch (error: any) {
            new ErrorHandler().Server(error);
        }
        finally {
            this.loading = false;
        }
    }

    private async getCampaigns(id: string) {
        this.campaignLoading = true;
        try {
            const response = await this.axios.get(`/api/admin/getcampaigns/${id}/${30}`);
            this.campaignList = response.data.data;
            this.currentShopName = this.shops.length > 0 ? this.shops.filterWithEquals('id', id)[0].name : '';
        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.campaignLoading = false;
        }
    }

    private async showShopCampaigns(id: string) {
        this.$router.push({
            name: 'admin',
            params: {
                id,
            },
        });

        await this.getCampaigns(id);
    }

    private async searchShops(searchValue: string) {
        const find: ShopStats[] = this.shops.filter((eml) => {
            return eml.name.toLowerCase().includes(searchValue.toLowerCase())
                || eml.domain.toLowerCase().includes(searchValue.toLowerCase())
                || eml.email.toLowerCase().includes(searchValue.toLowerCase())
        });

        if (find != null && find.length > 0) {
            this.searchItems = find;
            this.actionLoading = false;
        } else {
            try {
                const response = await this.axios.get(`/api/admin/getshops/${searchValue}`);

                this.searchItems = response.data.data;
                this.shops.push.apply(this.shops, this.searchItems);
                this.pagination.totalItems = this.shops.length;
            } catch (error: any) {
                new ErrorHandler().Server(error);
            } finally {
                this.actionLoading = false;
            }
        }
    }

    private editCampaign(id: string) {
        this.$router.push({
            name: 'campaign',
            params: {
                id,
                shopId: this.$route.params.id
            },
        });
    }

    get showCampaignList() {
        return this.$route.params.id !== undefined;
    }

    private async beforeRouteUpdate (_to: Route, _from: Route, next: any) {
        if (this.shops.length === 0) {
            await this.getShops();
            next();
        } else {
            next();
        }
    }

    private formatRevenue(item: ShopStats) {
        if (item.moneyWithCurrencyFormat != null) {
            const patt = /{{\s*[a-zA-Z0-9_]+?\s*}}/g;
            return item.moneyWithCurrencyFormat.replace(patt,item.revenue.toString());
        }

        const currency = item.currency ?? '';
        return `${currency} ${item.revenue}`;
    }

    private storeUrl(item: ShopStats) {
        if (item.domain != null && (item.domain.indexOf('https') !== -1 || item.domain.indexOf('http') !== -1)) {
            return item.domain;
        }
        return `https://${item.domain}`;
    }

    private async clearCache(id: string) {
        try {
            this.actionLoading = true;
            await this.axios.post(`/api/admin/clearcache/${id}`);
            this.$store.commit('showSuccessSnackbar','Cache cleared!');
        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.actionLoading = false;
        }
    }
}
