
import { Component, Vue, Prop, Watch, Model  } from 'vue-property-decorator';
import { ErrorHandler } from '../../helpers/errorHandler';
import { PricingPlanValidation } from '../../helpers/pricingPlanValidation';
import { Integration } from '../../models/Integration';
import { IPricigPlan } from '../../helpers/pricingPlan';

@Component({})
export default class PricingPlanValidationDialog extends Vue {
    @Prop(Boolean) public showDialog!: boolean;
    @Prop(Object) public integrationEmailValid!: Integration;
    @Prop(Function) public getIntegrations!: () => void;

    private pricingPlans = new PricingPlanValidation();
    private actionLoading: boolean = false;
    private closeDialog() {
        this.$emit('update:showDialog', false);
    }

    private async buyEmailValidations(pricingPlan: IPricigPlan) {
        try {
            this.actionLoading = true;
            const plan = {
                planName: pricingPlan.type
            };
            const response = await this.axios.post(`/api/integration/buyemailvalidations`, plan);
            if (response.data.data === 'PaymentSuccessfully') {
                // @ts-ignore
                this.integrationEmailValid.kickboxIntegration.verifications += pricingPlan.limit;
                this.$emit('update:showDialog', false);
                this.getIntegrations();
            } else {
                // @ts-ignore
                top.window.location = response.data.data;
            }
        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.actionLoading = false;
        }

    }
}
