import { ICampaign } from '../models/Campaign';
import i18n from '../plugins/i18n';
import store from '../store/index';
import { AppType } from '../helpers/enums';

interface SimulationParams {
    currentAngle: number;
    currentSpeed: number;
    pointerAngle: number;
    slicesCount: number;
    done: boolean;
}

export class TemplatesHelpers {
    private AppContainer: HTMLElement;
    private campaign: ICampaign;
    private CurrentAnimation: number = 0;
    private CurrentSpeed: number = 0;
    private CurrentAngle: number = 0;
    // private WinningIndex: number = -1;
    private InitSpeed: number = 700 / 60;
    private SimulationParams: SimulationParams = {
        currentAngle: this.CurrentAngle,
        currentSpeed: 0,
        pointerAngle: 0,
        slicesCount: 12,
        done: false,
    };

    constructor (template: HTMLElement, campaign: ICampaign) {
        this.AppContainer = template;
        this.campaign = campaign;
    }

    public StartSpin(gdprCheckbox: boolean) {
        // @ts-ignore
        if ((this.campaign.app.gdprEnable && gdprCheckbox) || !this.campaign.app.gdprEnable) {
            let totalPercents = 0;

            // @ts-ignore
            this.campaign.app.coupons.forEach((slice) => {
                totalPercents += parseInt(slice.gravity.toString(), 10);
            });

            const diceResult = Math.random() * totalPercents;
            let index = -1;
            let diceSum = 0;

            while (diceSum < diceResult) {
                index++;
                // @ts-ignore
                const slice = this.campaign.app.coupons[index];
                if (!slice) {
                    break;
                }

                if (!slice.gravity) {
                    continue;
                }

                diceSum += parseInt(slice.gravity.toString(), 10);
            }

            if (index === -1) {
                index = 0;
            }

            let speed = (this.InitSpeed) + (Math.random() * 2 - 1) * this.InitSpeed / 3;
            let currentIndex = this.PredictSpeed(speed);

            while (currentIndex !== index) {
                speed += 0.1;
                currentIndex = this.PredictSpeed(speed);
            }

            // this.WinningIndex = index;
            this.CurrentSpeed = speed;
            this.SimulationParams.currentSpeed = speed;
            this.SimulationParams.currentAngle = 0;
            this.CurrentAnimation = window.requestAnimationFrame(() => this.CalculateFrame(this.SimulationParams, false));
        } else {
            store.commit('showErrorSnackbar', i18n.t('errors.app.check_gdpr'));
        }
    }

    private PredictSpeed(speed: number): number {
        let index: number = 0;
        const simulationParams = this.SimulationParams;
        simulationParams.currentSpeed = speed;
        simulationParams.done = false;
        simulationParams.currentAngle = this.CurrentAngle;

        while (!simulationParams.done) {
            this.CalculateFrame(simulationParams, true);
        }

        index = this.ItemFromAngle(simulationParams.currentAngle, simulationParams.pointerAngle);
        return index;
    }

    private CalculateFrame(simulationParams: SimulationParams, simulation: boolean): void {
        const wheelMin: number = 11;
        const wheelMax: number = 16;
        const pointerMax: number = -44; // deg
        const returnSpeed: number = 480; // deg/s

        const factor = simulationParams.currentSpeed < 1.5 ? 0.97 : 0.99;

        this.CurrentSpeed = simulationParams.currentSpeed * factor;
        simulationParams.currentSpeed = this.CurrentSpeed;

        if (this.CurrentSpeed <= 0.001) {
            if (!simulation) {
                // cancel animation
                window.cancelAnimationFrame(this.CurrentAnimation);
                // this.RotationFinished();
            } else {
                simulationParams.done = true;
            }

            return;
        }

        simulationParams.currentAngle += this.CurrentSpeed;
        if (!simulation) {
            const addAngle = this.campaign.appType === AppType.Wheelio2 ? 9 : 0;
            // @ts-ignore
            (this.AppContainer.querySelector('#wlo-rotor') as HTMLElement).style.setProperty('transform', `rotate(${simulationParams.currentAngle + addAngle}deg)`);
        }

        let declination = (simulationParams.currentAngle % (360 / simulationParams.slicesCount) - wheelMin) / wheelMax;
        if (declination > 0 && declination <= 1) {
            declination = -1 * declination * (declination - 2); // easing
            simulationParams.pointerAngle = declination * pointerMax;
        } else {
            simulationParams.pointerAngle = Math.min(simulationParams.pointerAngle + returnSpeed / 60, 0);
        }

        if (!simulation) {
            this.CurrentAnimation = window.requestAnimationFrame(() => this.CalculateFrame(this.SimulationParams, false));
            const rotate = `rotate(${simulationParams.pointerAngle}deg)`;
            // @ts-ignore
            (this.AppContainer.querySelector('#wlo-pointer') as HTMLElement).style.setProperty('transform', rotate);

            if (this.campaign.appType === AppType.Wheelio) {
                // @ts-ignore
                (this.AppContainer.querySelector('.wlo_pointer_shadow') as HTMLElement).style.setProperty('transform', rotate);
            } else if (this.campaign.appType === AppType.Wheelio2) {
                // @ts-ignore
                (this.AppContainer.querySelector('#pins') as HTMLElement).style.setProperty('transform', `rotate(${simulationParams.currentAngle + 9}deg)`);
            }
        }
    }

    private ItemFromAngle(currentAngle: number, pointerAngle: number): number {
        const angle = 360 / this.SimulationParams.slicesCount;
        currentAngle = currentAngle % 360;

        const isFirstSliceUnder = currentAngle >= (360 - angle / 2);
        let index = isFirstSliceUnder ? 0 : Math.round(currentAngle / angle);

        if (pointerAngle && currentAngle % angle > (angle / 2)) {
            if (!isFirstSliceUnder) {
                index--;
            } else {
                index = this.SimulationParams.slicesCount - 1;
            }
        }

        return index;
    }
}