import router from '../router';
import i18n from '../plugins/i18n';
import store from '../store/index';

export class ErrorHandler {
    public errorMessage: string = '';
    private appRouter = router;
    public Server(error: Error) {
        // @ts-ignore
        const statusCode = error.response.status;
        // @ts-ignore
        let message = i18n.t(`errors.server.${error.response.data.code}`).toString();
        const type = sessionStorage.getItem('type');

        // Unauthorized
        if (statusCode === 401) {
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('type');
            if (type === 'Shopify' || sessionStorage.getItem('myshopify_domain') !== null) {
                store.commit('showSessionSnackbar', i18n.t(`errors.server.SessionExpired`));
                // @ts-ignore
                document.getElementById('error-overlay').style.display = 'flex';
                // @ts-ignore
                if (window.$crisp !== undefined) {
                    // @ts-ignore
                    window.$crisp.push(['do', 'session:reset']);
                }
            } else {
                if (this.appRouter.app.$route.path !== '/login') {
                    // @ts-ignore
                    if (window.$crisp !== undefined) {
                        // @ts-ignore
                        window.$crisp.push(['do', 'session:reset']);
                    }
                    store.commit('showErrorSnackbar', i18n.t(`errors.server.SessionExpired`))
                    this.appRouter.push({ name: 'login' });
                }
            }
        } else if (statusCode === 403) { // Forbiden
            // @ts-ignore
            store.commit('showErrorSnackbar',  i18n.t(`errors.server.${error.response.data.code}`));
            let type;
            // @ts-ignore
            if (error.response.data.code === 'CreditCardRegistration') {
                type = 'register';
                // @ts-ignore
            } else if (error.response.data.code === 'NoActiveSubscription') {
                type = 'activate';
            }
            if (this.appRouter.app.$route.name !== 'activatesubscription') {
                this.appRouter.push({ name: 'activatesubscription', params: { type } });
            }
        } else { // Validation error
            // @ts-ignore
            if (error.response.data.hasOwnProperty('validationErrors') && error.response.data.validationErrors != null) {
                // @ts-ignore
                error.response.data.validationErrors.forEach((err: any) => {
                    this.errorMessage += i18n.t(`errors.server.${err.code}`);
                });
            }
            if (this.errorMessage !== '') {
                message += ': ' + this.errorMessage;
            }
            store.commit('showErrorSnackbar',  message);
        }
    }
}
