
import {
    Component,
    Vue,
    Prop,
    Watch,
    Model,
} from 'vue-property-decorator';
@Component({})
export default class UnsavedChangesDialog extends Vue {
    @Prop(Boolean) public showCancelChangesDialog!: boolean;
    @Prop(Boolean) public cancelChangesDialogResponse!: boolean;
    private clientInstance: object = {};

    private closeDialog() {
        this.$emit('update:showCancelChangesDialog', false);
        if (this.clientInstance !== null && this.clientInstance.hasOwnProperty('teardown')) {
            // @ts-ignore
            this.clientInstance.teardown();
        }
    }
    private confirm() {
        this.$emit('update:cancelChangesDialogResponse', true);
    }
}
