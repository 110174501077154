import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify, {
  VAlert,
  VApp,
  VNavigationDrawer,
  VFooter,
  VList,
  VBtn,
  VIcon,
  VToolbar,
  VDataTable,
  VProgressLinear,
  VTextField,
  VCard,
  VMenu,
  VDatePicker,
  VForm,
  VSelect,
  VDivider,
  VDialog,
  VSwitch,
  VSubheader,
  VHover,
  VProgressCircular,
  VTextarea,
  VTooltip,
  VStepper,
  VRadioGroup,
  VTimePicker,
  VPagination,
  VImg,
  VSlider,
  VAutocomplete,
  VCombobox,
  VChip,
  VTabs,
  VCheckbox,
  VSkeletonLoader
} from 'vuetify/lib';

// vue-cli a-la-carte installation
Vue.use(Vuetify, {
  components: {
    VAlert,
    VApp,
    VNavigationDrawer,
    VFooter,
    VList,
    VBtn,
    VIcon,
    VToolbar,
    VDataTable,
    VProgressLinear,
    VTextField,
    VCard,
    VMenu,
    VDatePicker,
    VForm,
    VSelect,
    VDivider,
    VDialog,
    VSwitch,
    VSubheader,
    VHover,
    VProgressCircular,
    VTextarea,
    VTooltip,
    VStepper,
    VRadioGroup,
    VTimePicker,
    VPagination,
    VImg,
    VSlider,
    VAutocomplete,
    VCombobox,
    VChip,
    VTabs,
    VCheckbox,
    VSkeletonLoader
  },
});

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#462567',
        secondary: '#8488e3',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    }
  },
};

export default new Vuetify(opts);
