
import Component from 'vue-class-component';
import { Vue, Watch } from 'vue-property-decorator';

import { Site } from '../models/Site';
import { ErrorHandler } from '../helpers/errorHandler';

import BigCommerce from '@/components/setup/BigCommerce.vue';
import ClickFunnels from '@/components/setup/ClickFunnels.vue';
import Magento from '@/components/setup/Magento.vue';
import PrestaShop from '@/components/setup/PrestaShop.vue';
import Wix from '@/components/setup/Wix.vue';
import Wordpress from '@/components/setup/Wordpress.vue';
import Jumpseller from '@/components/setup/Jumpseller.vue';
import Custom from '@/components/setup/Custom.vue';
import i18n from '../plugins/i18n';

@Component({
    // @ts-ignore
    components: {
        BigCommerce,
        ClickFunnels,
        Magento,
        PrestaShop,
        Wix,
        Wordpress,
        Jumpseller,
        Custom,
  },
})

export default class Setup extends Vue {
    private loading: boolean = true;
    private tab = null;
    private codeSnippet: string = '';
    private hash = '';
    private site: Site = {
        id: '',
        name: '',
        domain: '',
        activatedOn: '',
        billingOn: '',
        trialEndsOn: '',
        cancelledOn: '',
        currentPlan: {
            planName: '',
            status: '',
            subscriptionPrice: '',
            firstBillingDate: '',
            nextBillingDate: '',
            cancelledOn: '',
            trialDuration: 0,
        },
        shopId: '',
        appUrl: '',
        payments: [],
        emails: [],
        customDomain: ''
    };

    private async created() {
        await this.getWebSite();
    }

    @Watch('$route', {
        immediate: true,
        deep: true,
    })
    private onUrlChange(newVal: any) {
        this.hash = newVal.hash;
    }

    private async getWebSite() {
        this.loading = true;
        try {
            const response = await this.axios.get(`/api/profile/getwebsite`);
            this.site = response.data.data;
            this.codeSnippet = '\r\n<!-- Wheelio App -->';
            this.codeSnippet += '\r\n<script type="text/javascript">\r\n';
            this.codeSnippet += `\tvar shopID = "${this.site.id}";\r\n`;
            this.codeSnippet += `\tvar url = "${this.site.appUrl}?v=" + new Date().getTime();\r\n`;
            this.codeSnippet += `\tvar wheelio_script = document.createElement("script");\t\r\n`;
            this.codeSnippet += `\twheelio_script.setAttribute("src", url);\t\r\n`;
            this.codeSnippet += `\tdocument.body.appendChild(wheelio_script);\t\r\n`;
            this.codeSnippet += '<\/script>';
        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.loading = false;
        }
    }

    private showCopiedToast() {
        this.$store.commit('showSuccessSnackbar',i18n.t('setup_instructions.copied').toString())
    }

    private copyCode() {
        const el = document.createElement('textarea');
        el.value = this.codeSnippet;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.showCopiedToast();
    }
}

