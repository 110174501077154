
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Helpers} from '../helpers/helpers';
import { ErrorHandler } from '../helpers/errorHandler';
import { ICampaign } from '../models/Campaign';
import { Trans } from '../plugins/translation';
import { IntegrationType, ScheduleType } from '../helpers/enums';
import { Integration } from '../models/Integration';
import Tooltip from '@/components/Tooltip.vue';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';
import i18n from '../plugins/i18n';

@Component({
    components: {
        Tooltip,
        ConfirmDialog
    }
})

export default class AppGeneral extends Vue {
    @Prop(Boolean) public hasChanged!: boolean;
    @Prop(Object) public campaign!: ICampaign;
    private helpers = new Helpers();
    private actionLoading: boolean = false;
    private menuStartDate: boolean = false;
    private startDate: string = new Date().toISOString().substr(0, 10);
    private endDate: string = new Date().toISOString().substr(0, 10);
    private startTime: string = '00:00';
    private endTime: string = '23:59';
    private menuEndDate: boolean = false;
    private on: object = {};
    private showConfirmDialog: boolean = false;
    private timeZone: string = '';

    private integration: Integration = {
        id: '',
        type: IntegrationType.None,
        settings: [],
        enabled: false,
        active: false,
        shopId: '',
        doubleOptIn: false
    };
    private expirationTypes: object[] = [
        { text: this.$t('campaign.general.days'),  value: 'days'},
        { text: this.$t('campaign.general.hours'), value: 'hours'},
        { text: this.$t('campaign.general.minutes'), value: 'minutes'}
    ];
    private rules: any = {
        required: (value) => !!value || i18n.t('validation.required'),
        greater0: (value) => !value || i18n.t('validation.greater0'),
        greater1000: (value) => !value || i18n.t('validation.greater1000')
    };
    private expirationType: string = 'days';
    private expirationValue: number = 0;

    @Watch('expirationValue')
    private onexpirationValue() {
        this.setExpirationValue(this.expirationType);
    }

    private created() {
        // @ts-ignore
        this.integration = this.campaign.integrations.findWith('type', IntegrationType.Kickbox);
        if (this.integration === undefined) {
            this.integration = {
                id: '',
                type: IntegrationType.None,
                settings: [],
                enabled: false,
                active: false,
                shopId: '',
                doubleOptIn: false
            };
        }

        if (this.campaign.scheduleType === ScheduleType.Fixed) {
            // @ts-ignore
            this.startDate = new Date(this.helpers.formatToTimeZone(this.campaign.startDate, this.timeZone)).toISOString().substr(0, 10);
            this.startTime = this.helpers.formatToTimeZone(this.campaign.startDate, this.timeZone).format('HH:mm');
            // @ts-ignore
            this.endDate = new Date(this.helpers.formatToTimeZone(this.campaign.endDate, this.timeZone)).toISOString().substr(0, 10);
            this.endTime = this.helpers.formatToTimeZone(this.campaign.endDate, this.timeZone).format('HH:mm');
        }
        this.timeZone = this.campaign.timeZone != null ?  this.campaign.timeZone : 'Europe/London';

        // @ts-ignore
        if(this.campaign.triggers?.cookieExpiresHours > 0) {
            this.expirationType = 'hours';
            // @ts-ignore
            this.expirationValue = this.campaign.triggers.cookieExpiresHours;
            // @ts-ignore
        } else if(this.campaign.triggers?.cookieExpiresMinutes > 0) {
            this.expirationType = 'minutes';
            // @ts-ignore
            this.expirationValue = this.campaign.triggers.cookieExpiresMinutes;
        } else {
            this.expirationType = 'days';
            // @ts-ignore
            this.expirationValue = this.campaign.triggers.cookieExpiresDays;
        }
        this.$emit('update:hasChanged', false);
    }

    private async resetCookie() {
        this.actionLoading = true;
        try {
            await this.axios.put(`/api/campaign/resetcookie/${this.campaign.id}`);
        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.actionLoading = false;
        }
    }

    get formatStartDate() {
        return this.helpers.formatToDateTime(this.helpers.formatToTimeZone(this.campaign.startDate, this.timeZone));
    }

    get formatEndDate() {
        return this.helpers.formatToDateTime(this.helpers.formatToTimeZone(this.campaign.endDate, this.timeZone));
    }

    get expirationValueRules() {
        if (this.expirationValue.toString() === '') {
            return [this.rules.required];
        } else if (this.expirationValue.toString() === ''|| (this.expirationValue === 0 || this.expirationValue.toString() === '0') && (this.expirationType === 'hours' || this.expirationType === 'minutes')) {
            return [this.rules.greater0];
        } else if (this.expirationValue > 1000) {
             return [this.rules.greater1000];
        } else {
            return [];
        }
    }

    private getUserLang() {
        return Trans.getUserLang().lang;
    }

    get disableEmailValidation() {
        return this.integration.type === IntegrationType.Kickbox ? false : true;
    }

    private changeSchedule(type: ScheduleType) {
        this.campaign.scheduleType = type;
        if(type === 'Fixed') {
            if(this.campaign.startDate === null) {
                this.campaign.startDate = this.helpers.formatToTimeZone(new Date(this.startDate + ' ' + this.startTime), this.timeZone).toDate();
            }
            if(this.campaign.endDate === null) {
                this.campaign.endDate = this.helpers.formatToTimeZone(new Date(this.endDate + ' ' + this.endTime), this.timeZone).toDate();
            }
        }
    }

    private async archive() {
        this.actionLoading = true;
        try {
            await this.axios.delete(`/api/campaign/deletecampaign/${this.campaign.id}`);
        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.actionLoading = this.showConfirmDialog = false;
            this.$router.push({
                name: 'campaignlist',
            });
        }
    }

    private updateStartDate() {
        const startDate = this.helpers.formatToUtc(this.startDate + ' ' + this.startTime, this.timeZone);
        Vue.set(this.campaign, 'startDate', startDate.format());
    }

    private updateEndDate() {
        const endDate = this.helpers.formatToUtc(this.endDate + ' ' + this.endTime, this.timeZone);
        Vue.set(this.campaign, 'endDate', endDate.format());
    }

    private expirationTypesChange(expiration: string): void {
        this.setExpirationValue(expiration);
        this.$emit('update:hasChanged', true);
    }

    private setExpirationValue(expirationType: string) {
        if (expirationType === 'days') {
            // @ts-ignore
            this.campaign.triggers.cookieExpiresDays = this.expirationValue;
            // @ts-ignore
            this.campaign.triggers.cookieExpiresHours = this.campaign.triggers.cookieExpiresMinutes = 0;
        } else if (expirationType === 'hours') {
            // @ts-ignore
            this.campaign.triggers.cookieExpiresHours = this.expirationValue;
            // @ts-ignore
            this.campaign.triggers.cookieExpiresDays = this.campaign.triggers.cookieExpiresMinutes = 0;
        } else if (expirationType === 'minutes') {
            // @ts-ignore
            this.campaign.triggers.cookieExpiresMinutes = this.expirationValue;
            // @ts-ignore
            this.campaign.triggers.cookieExpiresDays = this.campaign.triggers.cookieExpiresHours = 0;
        }
    }

    private updateSelection(campaign: ICampaign) {
        // @ts-ignore
        if(campaign.triggers?.cookieExpiresHours > 0) {
            this.expirationType = 'hours';
            // @ts-ignore
            this.expirationValue = campaign.triggers.cookieExpiresHours;
            // @ts-ignore
        } else if(campaign.triggers?.cookieExpiresMinutes > 0) {
            this.expirationType = 'minutes';
            // @ts-ignore
            this.expirationValue = campaign.triggers.cookieExpiresMinutes;
        } else {
            this.expirationType = 'days';
            // @ts-ignore
            this.expirationValue = campaign.triggers.cookieExpiresDays;
            // @ts-ignore
        }
        this.timeZone = campaign.timeZone;
    }

    private onItemsChange(value: string) {
        Vue.set(this.campaign, 'timeZone', value);
    }

    get startTimeMax() {
        return this.startDate !== this.endDate ? '23:59' : this.endTime;
    }

    get endTimeMin() {
        return this.startDate !== this.endDate ? '00:00' : this.startTime;
    }
}
