import axios from 'axios';
import { SUPPORTED_LANGUAGES, DEFAULT_LANGUAGE } from '../constants/trans';
import i18n from './i18n';
import { ILanguage } from '../models/ILanguage';
import { LocaleMessages } from 'vue-i18n';

const Trans = {
    get defaultLanguage(): ILanguage {
        return DEFAULT_LANGUAGE;
    },
    get supportedLanguages(): ILanguage[] {
        return SUPPORTED_LANGUAGES;
    },
    get currentLanguage(): string {
        return i18n.locale;
    },
    set currentLanguage(lang) {
        i18n.locale = lang;
    },
    /**
     * Gets the first supported language that matches the user's
     * @return {String}
     */
    get getUserSupportedLang(): string {
        const userPreferredLang = Trans.getUserLang();

        // Check if user preferred browser lang is supported
        if (Trans.isLangSupported(userPreferredLang.lang)) {
            return userPreferredLang.lang;
        }
        // Check if user preferred lang without the ISO is supported
        if (Trans.isLangSupported(userPreferredLang.langNoISO)) {
            return userPreferredLang.langNoISO;
        }
        return Trans.defaultLanguage.lang;
    },
    /**
     * Returns the users preferred language
     */
    getUserLang() {
        // @ts-ignore
        const lang = this.getCookie('wheelio-lang') || window.navigator.language || window.navigator.userLanguage || Trans.defaultLanguage.lang;
        return {
          lang,
          langNoISO: lang.split('-')[0],
        };
    },
    /**
     * Sets the language to various services (axios, the html tag etc)
     * @param {String} lang
     * @return {String} lang
     */
    setI18nLanguageInServices(lang: string): string {
        Trans.currentLanguage = lang;
        axios.defaults.headers.common['Accept-Language'] = lang;
        // @ts-ignore
        document.querySelector('html').setAttribute('lang', lang);
        return lang;
    },
    /**
     * Loads new translation messages and changes the language when finished
     * @param lang
     * @return {Promise<any>}
     */
    changeLanguage(lang: string) {
        if (!Trans.isLangSupported(lang)) {
            // return Promise.reject(new Error('Language not supported'));
            lang = DEFAULT_LANGUAGE.lang;
        }
        if (i18n.locale === lang) {
            return Promise.resolve(lang); // has been loaded prior
        }

        const messages = this.loadLanguageFile(lang);
        i18n.setLocaleMessage(lang, messages);
        this.setI18nLanguageInServices(lang);
        return Promise.resolve(lang);
    },
    /**
     * Checks if a lang is supported
     * @param {String} lang
     * @return {boolean}
     */
    isLangSupported(lang: string): boolean {
        return Trans.supportedLanguages.map((a) => a.lang).includes(lang);
    },
    /**
     * Async loads a translation file
     * @param lang
     * @return {Promise<*>|*}
     */
    loadLanguageFile(lang: string) {
        const locales = require.context(
          '../locales',
          true,
          /[A-Za-z0-9-_,\s]+\.json$/i,
        );
        const messages: LocaleMessages = {};
        locales.keys().forEach((key) => {
          const matched = key.match(/([A-Za-z0-9-_]+)\./i);
          if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
          }
        });

        return messages[lang];
    },
    getCookie(cname: string) {
        const name = cname + '=';
        const ca = document.cookie.split(';');
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    },
    setCookie(cname: string, cvalue: string, exdays: number) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        const expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    },
};

export { Trans };
