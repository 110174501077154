
import Component from 'vue-class-component';
import { Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class Wordpress extends Vue {
    @Prop(String) public codeSnippet!: string;
    @Prop(Function) public copyCode!: () => void;
}

