
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Helpers} from '../helpers/helpers';
import { UrlFilter, GeoLocationFilter } from '../models/Campaign';
import { UrlFilterOperator, UrlFilterRule } from '../helpers/enums';
import i18n from '../plugins/i18n';
import Tooltip from '@/components/Tooltip.vue';

@Component({
    components: {
        Tooltip
    }
})
export default class AppUrlFiltering extends Vue {
    @Prop(Boolean) public hasChanged!: boolean;
    @Prop(Array) public urlFilters!: UrlFilter[];
    @Prop(String) public domainUrl!: string;
    @Prop(String) public appType!: string;
    @Prop(Array) public countries!: [];
    @Prop(Array) public states!: [];

    private urlFilteringRule = UrlFilterRule;
    private helpers = new Helpers();
    private selectedOperators: string = '';
    private selectedRules: string = '';
    private selectedText: string = '';
    private cacheObject;
    private isLoading = false;
    private search = '';
    private urlFilter: UrlFilter = {
        id: -1,
        operator: UrlFilterOperator.Does,
        rule: UrlFilterRule.Match,
        text: '',
        advanced: false,
    };
    private geoLocation: UrlFilter = {
        id: -1,
        operator: UrlFilterOperator.GeoLocation,
        rule: UrlFilterRule.Contains,
        text: '',
        advanced: true
    };
    private geoLocationFilter: GeoLocationFilter = {
        countries : [],
        states: []
    };
    private operators: object[] = [];
    private rules: object[] = [];

    private created() {
        for (const item in UrlFilterOperator) {
            if (isNaN(Number(item)) && item !== UrlFilterOperator.GeoLocation) {
                const operator = {
                    value: item,
                    text: i18n.t(`campaign.url_filters.operators.${UrlFilterOperator[item]}`),
                };
                this.operators.push(operator);
            }
        }

        for (const item in UrlFilterRule) {
            if (isNaN(Number(item))) {
                const rule = {
                    value: item,
                    text: i18n.t(`campaign.url_filters.rules.${UrlFilterRule[item]}`),
                };
                this.rules.push(rule);
            }
        }
        const geoFilter = this.urlFilters.find((filter) => filter.operator === UrlFilterOperator.GeoLocation);
        if (geoFilter &&  geoFilter.text !== '') {
            this.geoLocationFilter = JSON.parse(geoFilter.text);
        }
    }

    private removeItem(id: number) {
        const urlFilters = this.urlFilters.filter((item) => {
            return item.id !== id;
        });

        this.$emit('update:urlFilters', urlFilters);
        this.$emit('update:hasChanged', true);
    }

    private addFilter(advanced: boolean) {
        const obj: UrlFilter = {
            id: this.urlFilters.length,
            operator: UrlFilterOperator.Does,
            rule: UrlFilterRule.Contains,
            text: '',
            advanced: advanced ? true : false,
        };

        this.urlFilters.push(obj);
        this.selectedOperators = '';
        this.selectedRules = '';
        this.selectedText = '';
        this.$emit('update:hasChanged', true);
    }

    private simpleFilters(urls: UrlFilter[]) {
        const result: UrlFilter[] = [];
        urls.forEach((e) => {
            if (!e.advanced) {
                result.push(e);
            }
        });
        return result;
    }

    private advancedFilters(urls: UrlFilter[]) {
        const result: UrlFilter[] = [];
        urls.forEach((e) => {
            if (e.advanced && e.operator !== UrlFilterOperator.GeoLocation) {
                result.push(e);
            }
        });
        return result;
    }

    private ruleChange(id, rule) {
        if (rule === UrlFilterRule.BeginWith) {
            this.urlFilters[id].text = this.urlFilters[id].text.split('/')[0];
        } else if (rule === UrlFilterRule.EndWith) {
            this.urlFilters[id].text = this.urlFilters[id].text.split('/')[this.urlFilters[id].text.split('/').length - 1];
        }
    }

    private preventSlash(e, rule) {
        if (e.key === '/' && rule === UrlFilterRule.BeginWith) {
            e.preventDefault();
        }
    }

    get domainPrefix() {
        const domainSplit = this.domainUrl.split('.');
        return '.'+domainSplit[domainSplit.length-1];
    }

    get ShowStates(): boolean {
        return this.geoLocationFilter.countries.indexOf('United States') !== -1;
    }

    private onItemsChange() {
        this.geoLocation.text = JSON.stringify(this.geoLocationFilter);
        this.geoLocation.id = this.urlFilters.length;

        if (this.urlFilters.length > 0 && this.getGeoFilterIndex > -1) {
            this.urlFilters[this.getGeoFilterIndex].text = this.geoLocation.text;
        } else {
            if (this.geoLocationFilter.countries.length > 0) {
               this.urlFilters.push(this.geoLocation);
            }
        }
    }

    private updateSelection(urlFilters: UrlFilter[]) {
        const geoFilter = urlFilters.find((filter) => filter.operator === UrlFilterOperator.GeoLocation);
        if (geoFilter &&  geoFilter.text !== '') {
            this.geoLocationFilter = JSON.parse(geoFilter.text);
        }
    }

    get getGeoFilterIndex(): number {
        return this.urlFilters.findIndex((filter) => filter.operator === UrlFilterOperator.GeoLocation);
    }
}
