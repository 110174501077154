
import {
    Component,
    Vue,
    Prop,
} from 'vue-property-decorator';

@Component({})
export default class Tooltip extends Vue {
    @Prop(Array) public paragraphs!: string[];
}
