
import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import { ErrorHandler } from '../../helpers/errorHandler';
import { User } from '../../models/User';
import i18n from '../../plugins/i18n';
import { Trans } from '../../plugins/translation';
import { VueReCaptcha } from 'vue-recaptcha-v3';

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY });


@Component({
  components:{
    VueReCaptcha
  }
})

export default class Register2 extends Vue {
    private show = true;
    private registrationSuccess: boolean = false;
    private showPassword: boolean = false;
    private actionLoading: boolean = false;
    private user: User = {
        siteName: '',
        email: '',
        domain: '',
        password: ''        
    };
    private error: boolean = false;
    private rules: any = {
        required (value) {
            if (value && value.trim() === '') {
                return i18n.t('validation.required');
            }
            return !!value || i18n.t('validation.required');
        },
        emailRules: [
            (value) => !!value || i18n.t('validation.required'),
            (value) => /.+@.+/.test(value) || i18n.t('validation.email'),
        ],
        password: value => {
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
          return (
            pattern.test(value) ||
                i18n.t('validation.password')
          );
        }
    };

    private async mounted() {    
        await this.$recaptchaLoaded();
    }

    private async register() {
        // @ts-ignore
        if (this.$refs.form.validate()) {
            this.actionLoading = true;
            
            const token = await this.$recaptcha('register');
            this.user.token = token;
            
            try {
                const response = await axios.post('api/account/register', this.user);
                if (response.status === 200) {
                    const data = response.data.data;
                    sessionStorage.clear();
                    sessionStorage.setItem('token', data.token);
                    sessionStorage.setItem('username', this.user.email);
                    sessionStorage.setItem('locale', data.locale);
                    sessionStorage.setItem('session', data.session);
                    Trans.changeLanguage(Trans.getCookie('wheelio-lang'));
                    // @ts-ignore
                    this.$moment.locale(data.locale);
                    this.axios.defaults.headers.common.Authorization  = 'Bearer ' + data.token;
                    this.$router.push({
                        name: 'activatesubscription',
                        params: { type: 'register' },
                    });
                }
            } catch (error: any) {
                new ErrorHandler().Server(error);

            } finally {
                this.actionLoading = false;
            }
        }
    }

    private login() {
        this.$router.push({ name: 'login' });
    }
}

