
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ICampaign } from '../models/Campaign';
import { IntegrationType } from '../helpers/enums';
import { Integration, IntegrationList } from '../models/Integration';
import { Helpers} from '../helpers/helpers';
import Tooltip from '@/components/Tooltip.vue';
import { CustomField } from '@/models/App';

@Component({
    components: {
        Tooltip
    }
})

export default class AppIntegrations extends Vue {
    @Prop(Boolean) public hasChanged!: boolean;
    @Prop(Object) public campaign!: ICampaign;
    @Prop(Boolean) public integrationsMailEnabled!: boolean;
    @Prop(Boolean) public integrationsSMSEnabled!: boolean;
    private openPanel: number = -1;    
    private enableEmail: boolean = false;
    private enableSMS: boolean = false;
    private shopId: string = '';
    private helpers = new Helpers();
    private cacheIntegration;
    private cacheintegrationSMS;
    private loading: boolean = false;
    private showShopWalletFeedback: boolean = false;
    private feedback: string = '';
    private firepushCollect = [
        { text: 'Emails', value: 'email'},
        { text: 'SMS', value: 'sms'}
    ];
    private firepushTOSLangs = [
        { text: 'English', value: 'english'},
        { text: 'French', value: 'french'},
        { text: 'German', value: 'german'},
        { text: 'Spanish', value: 'spanish'},
        { text: 'Italian', value: 'italian'},
        { text: 'Portuguese', value: 'portuguese'},
        { text: 'Japanese', value: 'japanese'},
        { text: 'Dutch', value: 'dutch'},
        { text: 'Norwegian', value: 'norwegian'}
    ];
    private integration: Integration = {
        id: '',
        type: IntegrationType.None,
        settings: [],
        active: false,
        enabled: false,
        shopId: '',
        doubleOptIn: false
    };
    private integrationSMS: Integration = {
        id: '',
        type: IntegrationType.None,
        settings: [],
        active: false,
        enabled: false,
        shopId: '',
        doubleOptIn: false
    };
    private integrationPushowl: Integration = {
        id: '',
        type: IntegrationType.None,
        settings: [],
        active: false,
        enabled: false,
        shopId: '',
        doubleOptIn: false
    };
    private integrationFirepush: Integration = {
        id: '',
        type: IntegrationType.None,
        settings: [],
        active: false,
        enabled: false,
        shopId: '',
        doubleOptIn: false
    };

    private selectedIntegration: IntegrationList = {
        id: '',
        name: '',
        selected: false,
    };

    private selectedIntegrationSMS: IntegrationList = {
        id: '',
        name: '',
        selected: false,
    };

    @Watch('integration', { deep: true })
    private onPropertyChangedIntegration(value: Integration) {
        this.$emit('update:integrationsMailEnabled', value.enabled);
        if (!this.helpers.isEqual(value, this.cacheIntegration)) {
            this.$emit('update:hasChanged', true);
        } else {
            this.$emit('update:hasChanged', false);
        }
    }

    @Watch('integrationSMS', { deep: true })
    private onPropertyChangedSms(value: Integration) {
        this.$emit('update:integrationsSMSEnabled', value.enabled);
        if (!this.helpers.isEqual(value, this.cacheintegrationSMS)) {
            this.$emit('update:hasChanged', true);
        } else {
            this.$emit('update:hasChanged', false);
        }
    }

    private created() {
        this.setData();
        this.$crispUserEvent('user:openIntegrations');
    }

    private setData() {
        this.integration = this.findIntegrationSetup(IntegrationType.None);
        this.integrationSMS = this.findIntegrationSetup(IntegrationType.SMSBump);
        if (this.integrationSMS.type === IntegrationType.None ) {
            this.integrationSMS = this.findIntegrationSetup(IntegrationType.Mobiniti);
        }
        if (this.integrationSMS.type === IntegrationType.None ) {
            this.integrationSMS = this.findIntegrationSetup(IntegrationType.Postscript);
        }
        if (this.integrationSMS.type === IntegrationType.None ) {
            this.integrationSMS = this.findIntegrationSetup(IntegrationType.Attentive);
        }
        if (this.integrationSMS.type === IntegrationType.None ) {
            this.integrationSMS = this.findIntegrationSetup(IntegrationType.Emotive);
        }
        if (this.integrationSMS.type === IntegrationType.None ) {
            this.integrationSMS = this.findIntegrationSetup(IntegrationType.RecartSMS);
        }

        if (this.integration !== undefined && this.integration.type !== IntegrationType.None && this.integration.settings.length > 0) {
            this.selectedIntegration = this.integration.settings.findWith('selected', true);
        }

        if (this.integrationSMS !== undefined && this.integrationSMS.type !== IntegrationType.None && this.integrationSMS.settings.length > 0) {
            this.selectedIntegrationSMS = this.integrationSMS.settings.findWith('selected', true);
        }

        this.integrationPushowl = this.findIntegrationSetup(IntegrationType.PushOwl);
        this.integrationFirepush = this.findIntegrationSetup(IntegrationType.Firepush);

        // open expansion panel        
        if (this.campaign.messengerSettings?.shopMessage.subscriptionMode || this.campaign.messengerSettings?.shopMessage.messengerOnlyMode || this.campaign.messengerSettings?.shopMessage.smartMode) {
            this.openPanel = 1;
        }
        // @ts-ignore
        this.shopId = this.campaign.shop.id;
        this.updateCache();        
    }

    private listChaged(id: string, type: IntegrationType) {
        // @ts-ignore
        this.campaign.integrations.forEach((integra: Integration) => {
            if (integra.type === type) {
                // @ts-ignore
                integra.settings.forEach((integ: any) => integ.selected = false);
                // @ts-ignore
                integra.settings.forEach((integ: any) => {
                    if (integ.id === id) {
                        integ.selected = true;
                    }
                });
                this.$emit('update:hasChanged', true);
            }
        });
    }

    private findIntegrationSetup(type: IntegrationType): Integration {
        // @ts-ignore
        let integration = this.campaign.integrations.find((integ: Integration) => {
                if (type === IntegrationType.SMSBump || type === IntegrationType.Mobiniti || type === IntegrationType.Postscript || type === IntegrationType.Attentive 
                || type === IntegrationType.Emotive || type === IntegrationType.RecartSMS) {
                    return integ.type === IntegrationType.SMSBump || integ.type === IntegrationType.Mobiniti || integ.type === IntegrationType.Postscript || integ.type === IntegrationType.Attentive
                    || integ.type === IntegrationType.Emotive || integ.type === IntegrationType.RecartSMS;
                } else if (type === IntegrationType.PushOwl) {
                    return integ.type === IntegrationType.PushOwl;
                } else if (type === IntegrationType.Firepush) {
                    return integ.type === IntegrationType.Firepush;
                } else {
                    return (integ.type !== IntegrationType.SMSBump && integ.type !== IntegrationType.Mobiniti && integ.type !== IntegrationType.Postscript
                        && integ.type !== IntegrationType.PushOwl && integ.type !== IntegrationType.Firepush && integ.type !== IntegrationType.Weglot
                        && integ.type !== IntegrationType.Attentive && integ.type !== IntegrationType.Emotive && integ.type !== IntegrationType.RecartSMS);
                }
            });
        if (integration === undefined) {
            integration = {
                id: '',
                type: IntegrationType.None,
                settings: [],
                active: false,
                enabled: false,
                shopId: this.shopId,
                doubleOptIn: false
            };

            if (type === IntegrationType.PushOwl) {
                integration.pushowlIntegration = {
                    domain: '',
                    custom: '',
                    pushOnly: false
                };
            }
        }
        return integration;
    }

    private updateCache() {
        this.cacheIntegration = JSON.parse(JSON.stringify(this.integration));
        this.cacheintegrationSMS = JSON.parse(JSON.stringify(this.integrationSMS));
    }

    private cancelChanges() {
        this.integration = (Object.assign({},  JSON.parse(JSON.stringify(this.cacheIntegration))));
        this.integrationSMS = (Object.assign({},  JSON.parse(JSON.stringify(this.cacheintegrationSMS))));
    }

    get disableEmail() {
        return this.integration === undefined || this.integration.type === IntegrationType.None || (this.integration.settings.length < 1 && this.integration.type !== IntegrationType.Omnisend) || !this.campaign.integrationsEnabled || !this.integration.active;
    }

    get disableSMS() {
        return (this.integrationSMS === undefined
            || this.integrationSMS.type === IntegrationType.None
            || this.integrationSMS.settings.length < 1
            || !this.campaign.integrationsEnabled
            || !this.integrationSMS.active)
            && (this.integrationSMS.type !== IntegrationType.Attentive
            && this.integrationSMS.type !== IntegrationType.Emotive
            && this.integrationSMS.type !== IntegrationType.RecartSMS);
    }

    get disablePushowl() {
        if (this.integrationPushowl !== undefined && this.integrationPushowl.type !== IntegrationType.None) {
            return false;
        } else {
            return true;
        }
    }

    get disableFirepush() {
        if (this.integrationFirepush !== undefined && this.integrationFirepush.type !== IntegrationType.None) {
            return false;
        } else {
            return true;
        }
    }    

    private messengerChange(value: boolean, messengerMode: string, type: string): void {
        if (!value) {
            return;
        }

        if(type === 'recart') {
            // @ts-ignore
            this.campaign.messengerSettings.shopMessage.subscriptionMode = this.campaign.messengerSettings.shopMessage.smartMode = this.campaign.messengerSettings.shopMessage.messengerOnlyMode = false;
        } else {
            // @ts-ignore
            this.campaign.messengerSettings.recart.subscriptionMode = this.campaign.messengerSettings.recart.smartMode = this.campaign.messengerSettings.recart.messengerOnlyMode = false;
        }

        if (messengerMode === 'subscriptionMode') {
            if (type === 'recart') {
                // @ts-ignore
                this.campaign.messengerSettings.recart.messengerOnlyMode = this.campaign.messengerSettings.recart.smartMode = false;
            } else if (type === 'shopMessage') {
                // @ts-ignore
                this.campaign.messengerSettings.shopMessage.messengerOnlyMode = this.campaign.messengerSettings.shopMessage.smartMode = false;
            }
        } else if (messengerMode === 'messengerOnlyMode') {
            if (type === 'recart') {
                // @ts-ignore
                this.campaign.messengerSettings.recart.subscriptionMode = this.campaign.messengerSettings.recart.smartMode = false;
            } else if (type === 'shopMessage') {
                // @ts-ignore
                this.campaign.messengerSettings.shopMessage.subscriptionMode = this.campaign.messengerSettings.shopMessage.smartMode = false;
            }
        } else if (messengerMode === 'smartMode') {
            if (type === 'recart') {
                // @ts-ignore
                this.campaign.messengerSettings.recart.messengerOnlyMode = this.campaign.messengerSettings.recart.subscriptionMode = false;
            } else if (type === 'shopMessage') {
                // @ts-ignore
                this.campaign.messengerSettings.shopMessage.messengerOnlyMode = this.campaign.messengerSettings.shopMessage.subscriptionMode = false;
            }
        }
    }

    private toIntegrations() {
        this.$router.push({
            name: 'integration',
        });
    }

    get isSuperAdmin() {
        const username = sessionStorage.getItem('username') || '';
        // @ts-ignore
        if (process.env.VUE_APP_SUPER_ADMINS.includes(username)) {
            return true;
        }
        return false;
    }

    get showRecart() {
        // @ts-ignore
        return this.campaign.messengerSettings.recart.messengerOnlyMode || this.campaign.messengerSettings.recart.subscriptionMode || this.campaign.messengerSettings.recart.smartMode;
    }

    get enableDoubleOptInt() {
        return this.integration != null && this.integration.type !== IntegrationType.ConvertKit && this.integration.type !== IntegrationType.Sendinblue
            && this.integration.type !== IntegrationType.Sendlane && this.integration.type !== IntegrationType.Mailjet && this.integration.type !== IntegrationType.Omnisend
            && this.integration.type !== IntegrationType.SmartrMail && this.integration.type !== IntegrationType.Klaviyo;
    }

    get firepushTOS() {
        // @ts-ignore
        const text = this.$t(`integration.firepush_legal_copy_lang.${this.integrationFirepush.firepushIntegration.settings.key}`).toString().replace('[BRAND_NAME]', this.integrationFirepush.firepushIntegration?.brandName);
        // @ts-ignore
        this.integrationFirepush.firepushIntegration.settings.value = text;
        return text;
    }

    private changeFirepushCollectType(value) {
        // @ts-ignore
        if (value === 'sms' && this.integrationFirepush.enabled) {
            this.$emit('update:integrationsSMSEnabled', true);
        } else if (value === 'email') {
            this.$emit('update:integrationsSMSEnabled', false);
        }
    }

    private firepushEnableChange(changeObj) {
        if (changeObj.value) {
            // @ts-ignore
            this.changeFirepushCollectType(this.integrationFirepush.firepushIntegration?.collectType);
        } else if (this.integrationsSMSEnabled) {
            this.$emit('update:integrationsSMSEnabled', false);
        } else {
            const phoneField = this.campaign.app?.customFields.find((element: CustomField) => element.id === 'phoneNumber');
            const emailField = this.campaign.app?.customFields.find((element: CustomField) => element.id === 'email');
            if (phoneField !== undefined && phoneField.main && emailField !== undefined && !emailField.main) {
                phoneField.main = false;
                phoneField.required = false;
                emailField.main = true;
                // @ts-ignore
                Vue.set(this.campaign.app?.customFields, this.campaign.app?.customFields.indexOf(phoneField), phoneField);
                // @ts-ignore
                Vue.set(this.campaign.app?.customFields, this.campaign.app?.customFields.indexOf(emailField), emailField);
            }
        }
    }  

    private async sendFeedback(){
        this.loading = true;
        const data = {
            content: this.feedback
        }
        try {
            await this.axios.post('/api/campaign/sendfeedback', this.feedback,  {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            this.showShopWalletFeedback = false;
        }
        catch(error: any) {}
        finally {
            this.loading = false;
        }
    }
    private changeShopLogin(value) {        
        if (value.value == false) {
            this.showShopWalletFeedback = true;
        }        
    }
}
