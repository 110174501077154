

import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { Trans } from '../plugins/translation';
import CountryFlag from 'vue-country-flag';
import { ErrorHandler } from '../helpers/errorHandler';

@Component({
  components: {
        CountryFlag,
    },
})
export default class LanguageSwitcher extends Vue {
    @Prop(Function) public changeMenuLang!: () => void;
    private loading: boolean = false;
    get currentLanguage() {
      return Trans.currentLanguage;
    }

    get supportedLanguages() {
        return Trans.supportedLanguages;
    }

    private async changeLanguage(lang: string) {
        this.loading = true;
        Trans.setCookie('wheelio-lang', lang, 365);
        Trans.changeLanguage(lang);
        this.changeMenuLang();
        try {
          await this.axios.put(`/api/services/updateshoplang/${lang}`);
        } catch(error: any) {
          new ErrorHandler().Server(error);
        } finally {
          this.loading = false;
        }
    }
}
