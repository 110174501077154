
import {
    Component,
    Vue,
    Prop,
} from 'vue-property-decorator';
import {
    Pie,
} from 'vue-chartjs';
interface IStats {
    createdDate: string;
    collect: number;
    spin: number;
    show: number;
    reject: number;
    deviceType: string;
}
interface IStatsPie {
    desktop: number;
    mobile: number;
    tablet: number;
}
// @ts-ignore
@Component({
    // @ts-ignore
    extends: Pie, // this is important to add the functionality to your component
})
export default class PieChart extends Vue<Pie> {
    @Prop(Object) public stats!: IStatsPie;
    @Prop(String) public title!: string;
    private devices: string[] = ['Desktop', 'Mobile', 'Tablet'];
    private mounted() {
        if (this.stats.desktop > 0 || this.stats.mobile > 0 || this.stats.tablet > 0 ) {
            this.renderChart({
                // scaleStartValue: 0,
                labels: this.devices,
                datasets: [{
                    data: [
                        this.stats.desktop,
                        this.stats.mobile,
                        this.stats.tablet,
                    ],
                    backgroundColor: [
                        '#4465C1',
                        '#2DA6A6',
                        '#D97791',
                    ],
                    label: 'Dataset 1',
                }],
            }, {
                responsive: true,
                maintainAspectRatio: false,
                title: {
                    display: true,
                    text: this.title,
                },
            });
        }
    }
}
