import { render, staticRenderFns } from "./Register2.vue?vue&type=template&id=296e17de"
import script from "./Register2.vue?vue&type=script&lang=ts"
export * from "./Register2.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports