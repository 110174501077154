
import { Component, Vue, Prop, Watch, Model  } from 'vue-property-decorator';
import { ErrorHandler } from '../../helpers/errorHandler';
import { Transaction } from '../../models/Transaction';
import { PricingPlans } from '../../helpers/pricingPlan';

@Component({ })
export default class ChangeCreditCardDialog extends Vue {
    @Prop(Boolean) public showDialog!: boolean;
    private showAmex: boolean = true;
    private isLoading: boolean = true;
    private actionLoading: boolean = false;
    private token: string = '';
    private changeStatus: string = '';
    private error: boolean = false;
    private rules: any = {
        required: (value) => !!value || 'Required.',
    };

    private errorCreditCardNumber: boolean = false;
    private errorCardholderName: boolean = false;
    private errorExpirationDate: boolean = false;
    private errorCvv: boolean = false;
    private errorMessageCreditCardNumber: string = '';
    private errorMessageCardholderName: string = '';
    private errorMessageExpirationDate: string = '';
    private errorMessageCvv: string = '';

    private clientInstance: object = {};
    private pricingPlans = new PricingPlans();
    private transaction: Transaction = {
        cardholderName: '',
        pricingPlan: this.pricingPlans.Basic.type.toLowerCase(),
        paymentMethodNonce: '',
        creditCardNumber: '',
        expirationDate: '',
        cvv: '',
        newCard: true,
        imageUrl: '',
        trialDuration: 0
    };

    private async created() {
        this.changeStatus = '';
        await this.generateToken();
        try {
            this.axios.get(`https://ipinfo.io/json?token=81a40b334a509c`).then(response => {
                if (response.data.country === 'US' || response.data.country === 'USA') {
                    this.showAmex = false;
                }
            });
        } catch(error: any) {
            new ErrorHandler().Server(error);
        }
    }

    private closeDialog() {
        // @ts-ignore
        if (this.$refs.form) {
            // @ts-ignore
            this.$refs.form.reset();
        }
        this.$emit('update:showDialog', false);
    }

    private async generateToken() {
        try {
            const response = await this.axios.get(`/api/braintree/GenerateToken`);
            this.token = response.data.data;
            this.createBraintreeInstance();

        } catch (error: any) {
            new ErrorHandler().Server(error);
        }
    }

    private createBraintreeInstance() {
        // @ts-ignore
        const braintreeObj = require('braintree-web');
        braintreeObj.client.create({
                authorization: this.token,
            })
            .then((instance: object) => {
                this.clientInstance = instance;
                this.isLoading = false;
                return braintreeObj.dataCollector.create({
                    client: instance
                }).then((dataCollectorInstance: object) => {
                    // @ts-ignore
                    this.transaction.deviceData = dataCollectorInstance.deviceData;
                });
            })
            .catch((error: any) => {
                alert(error);
            });
    }

    private changeCreditCard() {
         // @ts-ignore
        if (this.$refs.form.validate() === false) {
            return;
        }

        this.actionLoading = true;

        let expirationDate = this.transaction.expirationDate;
        if (this.transaction.expirationDate.indexOf('/') === -1) {
            expirationDate = this.transaction.expirationDate.substring(0, 2) + '/'
                + this.transaction.expirationDate.substring(2, 4);
        }

        const data = {
            creditCard: {
                number: this.transaction.creditCardNumber,
                expirationDate,
                cvv: this.transaction.cvv,
            },
        };

        // @ts-ignore
        this.clientInstance.request({
            endpoint: 'payment_methods/credit_cards',
            method: 'post',
            data,
        })
        .then((payload: any) => {
            this.createPayment(payload);
        })
        .catch((error: any) => {
            this.actionLoading = false;
            let errorMessage = '';
            error.details.originalError.fieldErrors.forEach((err: any) => {
                err.fieldErrors.forEach((fieldError: any) => {
                    errorMessage += 'Code: ' + fieldError.code + '<br /> Message: ' + fieldError.message + '<br />';
                    switch (fieldError.field) {
                        case 'number': {
                            this.errorCreditCardNumber = true;
                            this.errorMessageCreditCardNumber = fieldError.message;
                            break;
                        }
                        case 'expirationMonth': {
                            this.errorExpirationDate = true;
                            this.errorMessageExpirationDate = fieldError.message;
                            break;
                        }
                        case 'cvv': {
                            this.errorCvv = true;
                            this.errorMessageCvv = fieldError.message;
                            break;
                        }
                    }
                });
            });
        });
    }

    private async createPayment(payload: any) {
        const payment: object = {
            paymentMethodNonce:  payload.creditCards[0].nonce,
            newCard: this.transaction.newCard,
            pricingPlan: this.transaction.pricingPlan,
            cardholderName: this.transaction.cardholderName,
            creditCardNumber: this.transaction.creditCardNumber,
            deviceData: this.transaction.deviceData
        };
        try {
            await this.axios.post<Transaction>(`/api/braintree/changecreditcard`, payment);

            this.changeStatus = 'success';
            // @ts-ignore
            this.clientInstance.teardown();
            // @ts-ignore
            // TODO: find another soution
            // this.$refs.form.reset();
        } catch (error: any) {
            new ErrorHandler().Server(error);
            this.errorCreditCardNumber = true;
            this.errorMessageCreditCardNumber = new ErrorHandler().errorMessage;
        } finally {
            this.actionLoading = false;
        }
    }
}
