import _axios from 'axios';
import i18n from './i18n';
import store from '../store/index';
const axios = _axios.create();
axios.defaults.headers.common.Authorization = 'Bearer ' + sessionStorage.token || '';

// Add a response interceptor
axios.interceptors.response.use((response) => {
    responseHandler(response);
    return response;
}, (error) => {
    return Promise.reject(error);
});

const responseHandler = (response) => {
    const method = response.config.method;
    switch (response.status) {
        case 204: // The server successfully processed the request and is not returning any content
          if ((method === 'post' || method === 'put' || method === 'patch')) {
            store.commit('showSuccessSnackbar', i18n.t('general.saved').toString())

          } else if (method === 'delete') {
            store.commit('showSuccessSnackbar', i18n.t('general.deleted').toString())
          }
          break;
        default:
          break;
      }
};

export { axios };
