
import { Component, Vue } from 'vue-property-decorator';
import { ErrorHandler } from '../../helpers/errorHandler';
import { Credentials } from '../../models/Credentials';
import * as moment from 'moment';
import { Trans } from '../../plugins/translation';
import i18n from '../../plugins/i18n';
import store from '../../store/index';
import { VueReCaptcha } from 'vue-recaptcha-v3';

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY });

@Component({
  components:{
    VueReCaptcha
  }
})
export default class Login2 extends Vue {
  private show: boolean = true;
  private actionLoading: boolean = false;
  private showPassword: boolean = false;
  
  private credentials: Credentials = {
    username : '',
    password: '',
  };
  private error: boolean = false;
  private rules: any = {
    required: (value) => !!value || i18n.t('validation.required'),
  };

  private async mounted() {    
    await this.$recaptchaLoaded();
    
    if (sessionStorage.getItem('type') === 'Shopify' || sessionStorage.getItem('myshopify_domain') !== null) {
      this.show = false;
      store.commit('showSessionSnackbar', i18n.t(`errors.server.SessionExpired`));
      // @ts-ignore
      document.getElementById('error-overlay').style.display = 'flex';
      // @ts-ignore
      if (window.$crisp !== undefined) {
        // @ts-ignore
        window.$crisp.push(['do', 'session:reset']);
      }
    }
  }

  private async login() {
    // @ts-ignore
    if (this.$refs.form.validate()) {      
      this.actionLoading = true;      
   
      const token = await this.$recaptcha('login');      
      this.credentials.token = token;

      try {
        const response = await this.axios.post(`/api/account/login`, this.credentials);
        const data = response.data.data;
        sessionStorage.clear();
        sessionStorage.setItem('token', data.token);
        sessionStorage.setItem('username', this.credentials.username);
        sessionStorage.setItem('locale', data.locale);
        sessionStorage.setItem('session', data.session);
        Trans.changeLanguage(Trans.getCookie('wheelio-lang'));

        // @ts-ignore
        this.$moment.locale(data.locale);
        this.axios.defaults.headers.common.Authorization  = 'Bearer ' + data.token;

        const errorCode = data.hasOwnProperty('error') ? data.error.code : '';
        if (errorCode !== '') {
            let type = '';

            if (errorCode === 'CreditCardRegistration') {
              type = 'register';
            } else if (errorCode === 'NoActiveSubscription') {
              type = 'activate';
            }

            this.$router.push({
              name: 'activatesubscription',
              params: { type },
            });
        } else {
          // crisp set up
          this.$configureCrisp();

          this.$router.push({
            name: 'campaignlist',
          });
        }
      } catch (error: any) {
          new ErrorHandler().Server(error);
          this.actionLoading = false;
          this.error = true;
      }
    }
  }

  private createAccount() {
    this.$router.push({ name: 'register' });
  }

  private resetPassword() {
    this.$router.push({ name: 'forgotpassword' });
  }

  private sendActivation() {
    this.$router.push({ name: 'activateAccount' });
  }
}
