
import {
    Component,
    Vue,
    Prop,
    Watch,
    Model
} from 'vue-property-decorator';
import {
    ErrorHandler
} from '../helpers/errorHandler';

import {
    ICampaign
} from '../models/Campaign';
import {
    EmailCollect
} from '../models/EmailCollect';
import {
    Helpers
} from '../helpers/helpers';
import i18n from '../plugins/i18n';

@Component({})
export default class EmailCollects extends Vue {
    @Prop(Object) public campaign!: ICampaign;

    private loading: boolean = true;
    private actionLoading: boolean = false;
    private actionLoadingEmail: boolean = false;
    private actionExportCsv: boolean = false;
    private disableLoadEmail: boolean = false;
    private noEmailsCollected: boolean = false;
    private emails: EmailCollect[] = [];
    private skip: number = 1;
    private take: number = 200;
    private helpers = new Helpers();
    private pagination = {
        rowsPerPage: 20,
        page: 1,
        totalItems: 0,
        rowsPerPageItems: [10, 20, 50, 100],
    };
    private model: object = {};
    private searchValue: string = '';
    private searchItems: EmailCollect[] = [];
    private headersEmail = [{
            text: i18n.t('label.email'),
            sortable: true,
            value: 'email',
        },
        {
            text: i18n.t('campaign.collected_emails.coupon_code'),
            sortable: false,
            value: 'couponCode',
        },
        {
            text: i18n.t('campaign.collected_emails.coupon_name'),
            sortable: true,
            value: 'couponName',
        },
        {
            text: 'Ip',
            sortable: false,
            value: 'ip',
        },
        {
            text: i18n.t('campaign.collected_emails.collected_date'),
            sortable: true,
            value: 'createdDate',
        }
    ];

    @Watch('searchValue')
    private onSearchValueChanged(value: string) {
        if (this.actionLoading === false && value != null && value.length >= 3) {
            this.actionLoading = true;
            this.searchEmail(value);
        }
    }

    private async created() {
        await this.loadMoreEmails();

        if (this.emails.length === 0) {
            this.noEmailsCollected = true;
        }

        if (this.emails.length < 20) {
            this.disableLoadEmail = true;
        }
    }

    private async loadMoreEmails() {
        this.actionLoading = true;
        this.actionLoadingEmail = true;

        try {
            const response = await this.axios.get(`/api/campaign/getEmails/${this.campaign.id}/${this.skip}/${this.take}`);

            this.emails.push.apply(this.emails, response.data.data);
            this.disableLoadEmail = response.data.data.length < 100 ? true : false;
            this.skip ++;
            this.pagination.totalItems = this.emails.length;
        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.actionLoading = false;
            this.actionLoadingEmail = false;
        }
    }

    private async exportToCsv() {
        this.actionExportCsv = true;

        try {
            const response = await this.axios.get(`/api/campaign/exportcsv/${this.campaign.id}`, {
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Export.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.actionExportCsv = false;
        }
    }

    private async searchEmail(searchValue) {
        const find: EmailCollect[] = this.emails.filter((eml) => eml.email.includes(searchValue));

        if (find != null && find.length > 0) {
            this.searchItems = find;
            this.actionLoading = false;
        } else {
            try {
                const response = await this.axios.get(`/api/campaign/getEmails/${this.campaign.id}/${0}/${20}/${searchValue}`);

                this.searchItems = response.data.data;
                this.emails.push.apply(this.emails, response.data.data);
                this.pagination.totalItems = this.emails.length;
            } catch (error: any) {
                new ErrorHandler().Server(error);
            } finally {
                this.actionLoading = false;
                this.actionLoadingEmail = false;
            }
        }
    }

    get pages() {
        if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
            return 0;
        }

        return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage);
    }
}
