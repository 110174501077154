import { ILanguage } from '../models/ILanguage';

export const SUPPORTED_LANGUAGES: ILanguage[] = [
    { title: 'English', lang: 'gb' },
    { title: 'Spanish', lang: 'es' },
    { title: 'France', lang: 'fr' },
    { title: 'Japan', lang: 'jp' },
    { title: 'Portugal', lang: 'pt' },
    { title: 'German', lang: 'de' },
];
export const DEFAULT_LANGUAGE: ILanguage = SUPPORTED_LANGUAGES[0];
export const FALLBACK_LANGUAGE: ILanguage = SUPPORTED_LANGUAGES[0];
