
import { ErrorHandler } from '../../helpers/errorHandler';
import { Helpers } from '../../helpers/helpers';
import * as moment from 'moment';
import { PricingPlans } from '../../helpers/pricingPlan';
import i18n from '../../plugins/i18n';

import {
    Component,
    Vue,
    Prop,
    Watch,
    Model,
} from 'vue-property-decorator';
@Component({})
export default class InvoiceDialog extends Vue {
    @Prop(Boolean) public showInvoiceDialog!: boolean;
    @Prop(Object) public invoice!: object;
    private actionLoading: boolean = false;
    private clientInstance: object = {};
    private helpers = new Helpers();
    private pricingPlans = new PricingPlans();

    private closeDialog() {
        this.$emit('update:showInvoiceDialog', false);
        if (this.clientInstance !== null && this.clientInstance.hasOwnProperty('teardown')) {
            // @ts-ignore
            this.clientInstance.teardown();
        }
    }
    private async print() {
        this.actionLoading = true;
        try {
            // @ts-ignore
            const response = await this.axios.get(`api/services/generateinvoice/${this.invoice.id}`, { responseType: 'blob'});
            const fileURL = URL.createObjectURL(new Blob([response.data]));
            // window.open(fileURL, '_blank');
            const link = document.createElement('a');
            link.href = fileURL;

            // @ts-ignore
            const fileName = `invoice-${this.$moment(this.invoice.createdAt).format('X')}.pdf`;
            link.setAttribute('download', fileName); // or any other extension
            document.body.appendChild(link);
            link.click();

        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.actionLoading = false;
        }
    }

    private statusText(invoice: object) {
        if (invoice.hasOwnProperty('status') === false) {
            return '';
        }

        // @ts-ignore
        if (invoice.status.toLowerCase() === 'settled') {
            // @ts-ignore
            return i18n.t(`website_settings.${invoice.status.toLowerCase()}`);
        } else {
            // @ts-ignore
            return `${i18n.t(`website_settings.${invoice.status.toLowerCase()}`)} (${invoice.processorResponseText})`;
        }
    }
}
