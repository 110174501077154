
import ToolbarCircle from '@/components/ToolbarCircle.vue';
import {
    Component,
    Vue,
    Prop,
    Watch,
    Model
} from 'vue-property-decorator';
import {
    PricingPlans
} from '../../helpers/pricingPlan';

@Component({
    components:{
        ToolbarCircle
    }
})
export default class PricingTiersDialog extends Vue {
    @Prop(Boolean) public showDialog!: boolean;
    @Prop(Number) public price!: number;
    @Prop(Number) public currentShow!: number;

    private currentPlan: string = '';
    private showChangeCardForm: boolean = false;
    private dialog: boolean = false;
    private clientInstance: object = {};
    private pricingPlans = new PricingPlans();
    private currenShowCalc: number = 0;

    private created() {
        this.currenShowCalc = this.currentShow;
        this.pricingPlans.Plans.forEach(element => {
            if (element.price === this.price) {
                this.currentPlan = element.name;
            }
        });
        this.getTiers();
        this.getPercentages();
    }

    private closeDialog() {
        this.$emit('update:showDialog', false);
        if (this.clientInstance !== null && this.clientInstance.hasOwnProperty('teardown')) {
            // @ts-ignore
            this.clientInstance.teardown();
        }
    }

    private getTiers() {
        for (let i=0; i<this.pricingPlans.Plans.length; i++) {
            if (i > 0) {
               this.pricingPlans.Plans[i].impresions = this.pricingPlans.Plans[i].limit - this.pricingPlans.Plans[i-1].limit;
            } else {
                this.pricingPlans.Plans[i].impresions = this.pricingPlans.Plans[i].limit;
            }
        }
    }

    private getPercentages() {
        this.pricingPlans.Plans.forEach(el => {
            let result;
            // @ts-ignore
            if (this.currenShowCalc > el.impresions) {
                result = 100;
            } else {
                // @ts-ignore
                result =  Math.ceil((this.currenShowCalc / el.impresions)*100);
            }
            // @ts-ignore
            this.currenShowCalc = this.currenShowCalc - el.impresions;

            if (result < 0 ) {
                result = 0;
            }

            // @ts-ignore
            el.percentage = result;
            // @ts-ignore
            if (result > 0 && result <= 100 && (el.limit >= this.currentShow || el.limit < this.currentShow && el.type === 'tier6')) {
                el.active = true;
            }
        });
    }

    private getPercentage(limit) {
        let result = 0;
        if (this.currenShowCalc > limit) {
            result = 100;
        } else {
            result =  Math.round((this.currenShowCalc / limit) * 100);
        }
        return result;
    }

    private isActive(name) {
        return name === this.currentPlan ? true : false;
    }
}
