
import { Component, Vue, Prop } from 'vue-property-decorator';
import { CustomBar, App, Color, Theme } from '../models/App';
import { Helpers} from '../helpers/helpers';
import { Sketch, Chrome } from 'vue-color';
import { CustomBarType } from '../helpers/enums';
import i18n from '../plugins/i18n';
import Tooltip from '@/components/Tooltip.vue';

@Component({
    components: {
        'sketch-picker': Sketch,
        'chrome-picker': Chrome,
        Tooltip
  },
})
export default class AppCustomBars extends Vue {
    @Prop(Boolean) public hasChanged!: boolean;
    @Prop(Array) public customBars!: CustomBar[];
    @Prop(Object) public app!: App;
    @Prop(String) public appType!: string;

    private selectedTheme: Theme = { text: '', value: '', selected: false, images: { background: '', top: '', bottom: '', pulltab: '', wheel: '' }};
    private selectedColors: Color = {
        theme: '',
        backgroundColor: { hex: '' },
        wheelFontColor: { hex: '' },
        fontColor: { hex: '' },
        buttonColor: { hex: '' },
        sliceColorSet1: { hex: '' },
        sliceColorSet2: { hex: '' },
        sliceColorSet3: { hex: '' },
        sliceColorSet4: { hex: '' },
        pointerColor: { hex: ''},
        buttonFontColor: { hex: '' },
    };

    private dialog: boolean = false;
    private helpers = new Helpers();
    private color1Visible: boolean = false;
    private color2Visible: boolean = false;
    private selected: number[] = [];
    private positionDiscountCode: object[] = [
        { text: i18n.t('campaign.custom_bars.top'), position: 'top'},
        { text: i18n.t('campaign.custom_bars.bottom'), position: 'bottom'},
    ];
    private positionProgressBar: object[] = [
        { text: i18n.t('campaign.custom_bars.under_title'), position: 'under_title'},
        { text: i18n.t('campaign.custom_bars.under_text'), position: 'under_text'},
        { text: i18n.t('campaign.custom_bars.under_button'), position: 'under_button'},
    ];

    private created() {
        this.customBars.forEach(element => {
            if (element.color === null) {
                element.color = '#fc8289';
            }
        });
        this.selectedTheme = this.app.themes.findWith('selected', true);
        this.selectedColors = this.app.colors.findWith('theme', this.selectedTheme.value);
    }

    private close() {
        this.dialog = false;
    }

    private toggleColorPicker(key: number) {
        if (this.selected.indexOf(key) !== -1 || key === -1) {
            this.selected = [];
        } else {
            this.selected = [];
            this.selected.push(key);
        }
    }

    private visible(key: number) {
        if (this.selected.indexOf(key) !== -1) {
            return true;
        }
        return false;
    }

    private changePosition(value: string, key: number) {
        Vue.set(this.customBars[key], 'position', value);
    }

    private themeChaged(value: any) {
        this.app.themes.forEach((theme: Theme) => theme.selected = false);
        this.app.themes.forEach((theme: Theme) => {
            if (theme.value === value) {
                theme.selected = true;
            }
        });

        this.selectedTheme = Object.assign({}, this.app.themes.findWith('selected', true));
        this.selectedColors = this.app.colors.findWith('theme', value);
    }
}
