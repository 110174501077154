
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Trigger } from '../models/Campaign';
import { Helpers} from '../helpers/helpers';
import i18n from '../plugins/i18n';
import { Sketch, Chrome } from 'vue-color';
import { ScheduleType } from '../helpers/enums';
import Tooltip from '@/components/Tooltip.vue';
import { Color, Theme, App } from '../models/App';
import '../assets/popup.css';

interface IModel {
    text: string;
}
@Component({
    components: {
        'sketch-picker': Sketch,
        'chrome-picker': Chrome,
        Tooltip,
  },
})

export default class AppTriggers extends Vue {
    @Prop(Boolean) public hasChanged!: boolean;
    @Prop(Object) public appTriggers!: Trigger;
    @Prop(String) public appType!: string;
    @Prop(Object) public app!: App;

    private helpers = new Helpers();
    private cacheObject;
    private test: string[] = [];
    private mobileTriggerKeys: string[] = [];
    private desktopTriggerKeys: string[] = [];
    private items = [
      { header: i18n.t('campaign.triggers.on_event_placeholder') },
    ];
    private rules: any = {
        required: (value) => !!value || i18n.t('validation.required')
    };
    private modelMobile: IModel[] = [];
    private modelMobileInterScroll: IModel[] = [];
    private modelDesktop: IModel[] = [];
    private searchDesktop = null;
    private searchMobile = null;
    private searchMobileInterScroll = null;

    private startColor: boolean = false;
    private startTextColor: boolean = false;
    private endColor: boolean = false;
    private endTextColor: boolean = false;

    private selectedTheme: Theme = { text: '', value: '', selected: false, images: { background: '', top: '', bottom: '', pulltab: '', wheel: '' }};
    private selectedColors: object = {
        theme: '',
        backgroundColor: { hex: '' },
        fontColor: { hex: '' },
    };

    private popupPosition: object[] = [
        { text: i18n.t('campaign.triggers.positions.bottom_left'), position: 'bottom_left'},
        { text: i18n.t('campaign.triggers.positions.bottom_right'), position: 'bottom_right'},
        { text: i18n.t('campaign.triggers.positions.top_left'), position: 'top_left'},
        { text: i18n.t('campaign.triggers.positions.top_right'), position: 'top_right'}
    ];
    private pullTabImages: object[] = [
        { text: 'None',         value: ''},
        { text: 'Free product', value: 'https://wheelioapp.azureedge.net/themes/General/gift.png'},
        { text: 'Discount',     value: 'https://wheelioapp.azureedge.net/themes/General/discount.png'},
        { text: 'Money',        value: 'https://wheelioapp.azureedge.net/themes/General/money.png'},
        { text: 'Star',         value: 'https://wheelioapp.azureedge.net/themes/General/star.png'}
    ];
    private pullTabSizes: object[] = [
        { text: i18n.t('campaign.triggers.pull_tab.size.normal'), value: 1 }, 
        { text: i18n.t('campaign.triggers.pull_tab.size.small'), value: 2 }
    ];
    private pullTabPosition: object[] = [
        { text: i18n.t('campaign.triggers.positions.top_left'), position: 'top_left'},
        { text: i18n.t('campaign.triggers.positions.middle_left'), position: 'middle_left'},
        { text: i18n.t('campaign.triggers.positions.bottom_left'), position: 'bottom_left'},
        { text: i18n.t('campaign.triggers.positions.top_right'), position: 'top_right'},
        { text: i18n.t('campaign.triggers.positions.middle_right'), position: 'middle_right'},
        { text: i18n.t('campaign.triggers.positions.bottom_right'), position: 'bottom_right'}        
    ];
    private created() {
        this.mobileTriggerKeys = Object.keys(this.appTriggers).filter((trigger) => {
            return trigger.includes('mobile') && typeof this.appTriggers[trigger] === 'boolean' && trigger !== 'mobileShow' && trigger !== 'mobilePersist';
        });
        this.desktopTriggerKeys = Object.keys(this.appTriggers).filter((trigger) => {
            return trigger.includes('desktop') && typeof this.appTriggers[trigger] === 'boolean'&& trigger !== 'desktopShow' && trigger !== 'desktopPersist';
        });
        this.setData();
        this.updateCache();
        this.selectedTheme = this.app.themes.findWith('selected', true);
        this.selectedColors = this.app.colors.findWith('theme', this.selectedTheme.value);
        this.disableNotificationPopup(this.appTriggers.mobileInterScroll);
    }

    private updateCache() {
        this.cacheObject = Object.assign({}, this.appTriggers);
    }

    private getSliderLabel(value) {
        return value + '%';
    }

    @Watch('modelDesktop', { deep: true })
    private onModelDesktopChange(val: object[], prev: object[]) {
        if (val.length === prev.length) {
            return;
        }
        // @ts-ignore
        this.modelDesktop = val.map((v) => {
            if (typeof v === 'string') {
                v = {
                    text: v,
                };
                // @ts-ignore
                if (this.validateString(v.text) === false) {
                    return;
                }
            }
            return v;
        });
        // parse
        this.appTriggers.desktopEventValue = '';
        this.modelDesktop.forEach((element) => {
            if (element !== undefined) {
                // @ts-ignore
                this.appTriggers.desktopEventValue += element.text + ',';
            }
        });
    }

    @Watch('modelMobile', { deep: true })
    private onModelMobileChange(val: object[], prev: object[]) {
        if (val.length === prev.length) {
            return;
        }
        // @ts-ignore
        this.modelMobile = val.map((v) => {
            if (typeof v === 'string') {
                v = {
                    text: v,
                };
                // @ts-ignore
                if (this.validateString(v.text) === false) {
                    return;
                }
            }
            return v;
        });
        // parse
        this.appTriggers.mobileEventValue = '';
        this.modelMobile.forEach((element) => {
            if (element !== undefined) {
                // @ts-ignore
                this.appTriggers.mobileEventValue += element.text + ',';
            }
        });
    }

    @Watch('modelMobileInterScroll', { deep: true })
    private onModelMobileInterScrollChange(val: object[], prev: object[]) {
        if (val.length === prev.length) {
            return;
        }
        // @ts-ignore
        this.modelMobileInterScroll = val.map((v) => {
            if (typeof v === 'string') {
                v = {
                    text: v,
                };
                // @ts-ignore
                if (this.validateString(v.text) === false) {
                    return;
                }
            }
            return v;
        });
        // parse
        this.appTriggers.mobileInterScrollValue = '';
        this.modelMobileInterScroll.forEach((element) => {
            if (element !== undefined) {
                // @ts-ignore
                this.appTriggers.mobileInterScrollValue += element.text + ',';
            }
        });
    }

    private setData() {
        this.modelDesktop = [];
        if (this.appTriggers.desktopEventValue != null && this.appTriggers.desktopEventValue !== undefined) {
            this.appTriggers.desktopEventValue.split(',').forEach((element) => {
                if (element !== '') {
                    this.modelDesktop.push({
                        text: element,
                    });
                }
            });
        } else {
            this.appTriggers.desktopEventValue = Object.assign({}, '');
        }

        this.modelMobile = [];
        if (this.appTriggers.mobileEventValue != null && this.appTriggers.mobileEventValue !== undefined) {
            this.appTriggers.mobileEventValue.split(',').forEach((element) => {
                if (element !== '') {
                    this.modelMobile.push({
                        text: element,
                    });
                }
            });
        } else {
            this.appTriggers.mobileEventValue = Object.assign({}, '');
        }
    }

    private validateString(str: string) {
        if (str.length <= 1) {
            return false;
        }
        if (str.charAt(0) !== '.' && str.charAt(0) !== '#') {
            return false;
        }
        let reject: boolean = false;
        str.split('').forEach((element, i) => {
            if (i > 0 && (!(/[a-z0-9_-]/i).test(element))) {
                reject = true;
            }
        });
        if (reject) {
            return false;
        }
        return true;
    }

    private toggleColorPicker(name: string) {
        this.startColor = this.endColor = this.startTextColor = this.endTextColor = false;
        if (name === 'startColor') {
            this.startColor = !this.startColor;
        } else if (name === 'endColor') {
            this.endColor = !this.endColor;
        } else if (name === 'startTextColor') {
            this.startTextColor = !this.startTextColor;
        } else if (name === 'endTextColor') {
            this.endTextColor = !this.endTextColor;
        }
    }

    get isPickerVisible() {
        if (this.startColor || this.endColor || this.startTextColor || this.endTextColor) {
            return true;
        } else {
            return false;
        }
    }

    get popupTextRender() {
        if (!this.appTriggers.popupText) {
            return '';
        }
        return this.appTriggers.popupText.replace('{{Country}}', 'Italy').replace('{{Discount}}', '25%').replace('{{State}}', 'California').replace('{{AU-City}}', 'Sydney').replace('{{SA-State}}', '\u200Eمكة المكرمة')
        .replace('{{DE-City}}', 'Berlin').replace('{{FR-City}}', 'Paris').replace('{{IT-City}}', 'Rome').replace('{{CL-City}}', 'Santiago').replace('{{BR-City}}', 'São Paulo').replace('{{UK-City}}', 'London')
        .replace('{{TR-City}}', 'Istanbul').replace('{{CO-City}}', 'Bogotá').replace('{{CA-City}}', 'Toronto').replace('{{MX-City}}', 'Tijuana').replace('{{IN-City}}', 'Mumbai');
    }

    get popupTextSmallRender() {
        if (!this.appTriggers.popupTextSmall) {
            return '';
        }
        return this.appTriggers.popupTextSmall.replace('{{Minutes}}', '7');
    }

    private disableNotificationPopup(interScroll){
        if (interScroll) {
            this.appTriggers.popupShow = false;
        }
    }

    private canDisableTrigger (type: string, triggerName: string) {
        let triggerKeys: string[] = [];

        if (type === 'desktop') {
            triggerKeys = this.desktopTriggerKeys;
        } else if (type === 'mobile') {
            triggerKeys = this.mobileTriggerKeys;
        }

        const activeTriggers = triggerKeys.filter((trigger) => {
            return this.appTriggers[trigger] === true;
        });

        if (activeTriggers.length === 0) {
            return true;
        }

        if (activeTriggers.length < 1) {
            return false;
        }

        const exits = activeTriggers.find((trigger) =>  {return trigger === triggerName });
        if (activeTriggers.length === 1 && exits) {
            return false;
        }

        return true;
    }
}
