
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ErrorHandler } from '../../helpers/errorHandler';
import i18n from '../../plugins/i18n';
import { PricingPlans } from '../../helpers/pricingPlan';
@Component({})
export default class NewChargeDialog extends Vue {
    @Prop(Boolean) public showNewChargeDialog!: boolean;
    private showPlans = false;
    private pricingPlans = new PricingPlans();
    private clientInstance: object = {};
    private loading = false;
    get getNewChargeDialog() {
        return this.$store.state.newChargeDialog.showing;
    }
    private closeDialog() {
        this.$store.state.newChargeDialog.showing = false;
    }

    private async upgrade() {
       try {
            this.loading = true;
            const response = await this.axios.put(`/shopify/updateplan`);
            // @ts-ignore
            window.top.location = response.data.data;
        } catch(error: any) {
            new ErrorHandler().Server(error);
            this.loading = false;
        }
    }
}
