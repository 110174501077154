import Component from 'vue-class-component';
/// <reference path="types.d.ts"/>

// before you will make any import
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate', // for vue-router 2.2+
]);

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';

/** PLUGINS */
import { axios } from './plugins/axios';
import VueAxios from 'vue-axios';
import vuetify from './plugins/vuetify';
import ToggleButton from 'vue-js-toggle-button'
import VueTheMask from 'vue-the-mask';

import i18n from './plugins/i18n';
// TODO: refactor to TS
import './plugins/moment';

import App from './App.vue';
import router from './router';
import store from '@/store/index';
import './registerServiceWorker';
import './assets/main.css';
import './helpers/globals';
import './assets/wheelio.css';

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
// TODO: create plugin for check of shopify store
Vue.prototype.$isShopify = sessionStorage.type === 'Shopify';
Vue.use(ToggleButton)
Vue.use(VueTheMask);

Vue.prototype.$hasShopPay = false;
Vue.prototype.$setHasShopPay = function(propertyName: boolean) { 
  Vue.prototype.$hasShopPay = propertyName;   
}

// TODO: create plugin file
Vue.prototype.$configureCrisp = () => {
  // @ts-ignore
  if (window.$crisp === undefined) {
      // @ts-ignore
    window.$crisp = [];
  }

  window.CRISP_TOKEN_ID = sessionStorage.getItem('session') || Math.random().toString(36).substring(2);
  window.CRISP_COOKIE_DOMAIN = 'dashboard.wheelio-app.com';
  window.CRISP_WEBSITE_ID = '3c7b1f0a-96ab-4673-8e16-996eaa84edc1';

  const d = document;
  const s = d.createElement('script');
  s.src='https://client.crisp.chat/l.js';
  s.async = true;
  d.getElementsByTagName('head')[0].appendChild(s);

  const username = sessionStorage.getItem('username');
  const nickname = Vue.prototype.$isShopify ? sessionStorage.getItem('myshopify_domain') : sessionStorage.getItem('username');

  // crisp set up
  // @ts-ignore
  window.$crisp.push(['safe', true]);
  // @ts-ignore
  window.$crisp.push(['set', 'user:email', username]);
  // @ts-ignore
  window.$crisp.push(['set', 'user:nickname', nickname]);
  // @ts-ignore
  window.$crisp.push(['set', 'session:data', [[['shop-type', Vue.prototype.$isShopify ? 'Shopify' : 'Standalone']]]]);
}

Vue.prototype.$crispUserEvent = (event: string, data: object = {}) => {
  // @ts-ignore
  window.$crisp.push(['set', 'session:event', [[[event, data]]]]);
}
// Vue.prototype.$signalRConnection = {};

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
