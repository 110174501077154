declare global {
    interface Array<T> {
        filterWithEquals<TKey extends keyof T, TValue extends T[TKey]>(member: TKey, value: TValue): T[];
        filterWithDiff<TKey extends keyof T, TValue extends T[TKey]>(member: TKey, value: TValue): T[];
        findWith<TKey extends keyof T, TValue extends T[TKey]>(member: TKey, value: TValue): T;
        move(from: number, to: number): void;
    }
  }

  Array.prototype.filterWithEquals = function<T, TKey extends keyof T, TValue extends T[TKey]>(this: T[], member: TKey, value: TValue): T[] {
    return this.filter(x => x[member] === value);
  }

  Array.prototype.filterWithDiff = function<T, TKey extends keyof T, TValue extends T[TKey]>(this: T[], member: TKey, value: TValue): T[] {
    return this.filter(x => x[member] !== value);
  }

  Array.prototype.findWith = function<T, TKey extends keyof T, TValue extends T[TKey]>(this: T[], member: TKey, value: TValue): T | undefined {
    return this.find(x => x[member] === value);
  }

  Array.prototype.move = function (from: number, to: number): void {
    this.splice(to, 0, this.splice(from, 1)[0]);
  };

  export { }