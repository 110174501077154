
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ErrorHandler } from '../helpers/errorHandler';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'; // @ is an alias to /src
import { Texts, App } from '../models/App';
import Tooltip from '@/components/Tooltip.vue';
import RichEditor from '@/components/RichEditor.vue';

@Component({
    components: {
        ConfirmDialog,
        Tooltip,
        RichEditor
    },
})
export default class AppGDPR extends Vue {
    @Prop(Object) public app!: App;
    @Prop(Object) public appTexts!: Texts;
    @Prop(String) public campaignId!: string;
    private showConfirmDialog: boolean = false;
    private confirmDialogLoading: boolean = false;
    private deleteGDPRDialog() {
        this.showConfirmDialog = true;
    }

    private async deleteGDPR() {
        this.confirmDialogLoading = true;

        try {
            // @ts-ignore
            await this.axios.delete(`/api/campaign/removeEmails/${this.campaignId}`);
        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.confirmDialogLoading = this.showConfirmDialog = false;
        }
    }

    get gdprTos() {
        return this.app.tos.find(t => t.type == 'gdpr');
    }

    set gdprTos(value) {
        Object.assign(this.app.tos, this.app.tos.map(t => t.type == 'gdpr' ? value : t));
    }
}
