
// before you will make any import
Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate',
]);

import Component from 'vue-class-component';
import { Watch} from 'vue-property-decorator';

// @ is an alias to /src
import UnsavedChangesDialog from '@/components/dialogs/UnsavedChangesDialog.vue';
import InvoiceDialog from '@/components/dialogs/InvoiceDialog.vue';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';
import ChangeCreditCardDialog from '@/components/dialogs/ChangeCreditCardDialog.vue';
import { PricingPlans, IPricigPlan } from '../helpers/pricingPlan';
import * as moment from 'moment';
import { Route } from 'vue-router';
import { ErrorHandler } from '../helpers/errorHandler';

import { BaseClass } from '../models/baseClass';
import { Site } from '../models/Site';
import i18n from '@/plugins/i18n';

@Component({
    components: {
        UnsavedChangesDialog,
        ConfirmDialog,
        InvoiceDialog,
        ChangeCreditCardDialog,
    }
})
export default class WebSite extends BaseClass<Site> {
    private showDialog: boolean = false;
    private showCreditCartDialog: boolean = false;
    private loadingTable: boolean = false;
    private printLoading: boolean = false;
    private cancelPlanDisabled: boolean = false;
    private activatedOn: string = '';
    private billingOn: string = '';
    private trialEndsOn: string = '';
    private cancelledOn: string = '';
    private showInvoiceDialog: boolean = false;
    private showConfirmDialog: boolean = false;
    private confirmDialogLoading: boolean = false;
    private expand: boolean = false;
    private invoice: object = {};
    private transactions: object = {};
    private pricingPlans = new PricingPlans();
     private currencyPlan: IPricigPlan = {
        name:  this.pricingPlans.Basic.name,
        type: this.pricingPlans.Basic.type,
        price: this.pricingPlans.Basic.price,
        currency: this.pricingPlans.Basic.currency,
        limit: this.pricingPlans.Basic.limit
    };
    private error: boolean = false;
    private rules: any = {        
        required (value) {
            if (value && value.trim() === '') {
                return i18n.t('validation.required');
            }
            return !!value || i18n.t('validation.required');
        }
    };
    private expanded: [] = [];
    private site: Site = {
        id: '',
        name: '',
        domain: '',
        activatedOn: '',
        billingOn: '',
        trialEndsOn: '',
        cancelledOn: '',
        currentPlan: {
            planName: '',
            status: '',
            subscriptionPrice: '',
            firstBillingDate: '',
            nextBillingDate: '',
            cancelledOn: '',
            trialDuration: 0,
        },
        shopId: '',
        appUrl: '',
        payments: [],
        emails: [],
        customDomain: ''
    };

    private headersPayment = [{
            text: this.i18n.t('website_settings.plan_name'),
            sortable: false,
            value: 'planName',
        },
        {
            text: this.i18n.t('website_settings.status'),
            sortable: false,
            value: 'status',
        },
        {
            text: this.i18n.t('website_settings.price'),
            sortable: false,
            value: 'subscriptionPrice',
        },
        {
            text: this.i18n.t('website_settings.trial_duration'),
            sortable: false,
            value: 'trialDuration',
        },
        {
            text: this.i18n.t('website_settings.first_billing_date'),
            sortable: false,
            value: 'firstBillingDate',
        },
        {
            text: this.i18n.t('website_settings.next_billing_date'),
            sortable: false,
            value: 'nextBillingDate',
        },
        {
            text: this.i18n.t('website_settings.canceled_on'),
            sortable: false,
            value: 'cancelledOn',
        },
        {
            text: this.i18n.t('website_settings.actions'),
            value: 'expand',
            align: 'end',
        }
    ];

    private headersUsageCharges = [
        {
            text: this.i18n.t('website_settings.billing_on'),
            sortable: false,
            value: 'createdDate',
        },
        {
            text: 'Price',
            sortable: false,
            value: 'price',
        },
        {
            text: this.i18n.t('website_settings.actions'),
            value: 'action',
            align: 'end',
        }
    ];

    private async created() {
        await this.getWebSite();
    }

    private async getWebSite() {
        this.loading = true;
        try {
            const response = await this.axios.get(`/api/profile/getwebsite`);

            this.site = response.data.data;
            this.setCacheObject(Object.assign({}, this.site));

            if (this.site.currentPlan == null) {
                this.cancelPlanDisabled = true;
                this.site.currentPlan = {
                    planName: '',
                    status: '',
                    subscriptionPrice: '',
                    firstBillingDate: '',
                    nextBillingDate: '',
                    cancelledOn: '',
                    trialDuration: 0,
                };
            } else {
                if (this.site.currentPlan.planName === 'Basic') {
                    this.currencyPlan = this.pricingPlans.Basic;
                } else if ((this.site.currentPlan.planName === 'BasicUSD')) {
                    this.currencyPlan = this.pricingPlans.BasicUSD;
                }
            }

            this.activatedOn = this.site.activatedOn != null ? this.helpers.formatToDate(this.site.activatedOn) : '';
            this.billingOn = this.site.billingOn != null ? this.helpers.formatToDate(this.site.billingOn) : '';
            this.trialEndsOn = this.site.trialEndsOn != null ? this.helpers.formatToDate(this.site.trialEndsOn) : '';
            this.cancelledOn = this.site.cancelledOn != null ? this.helpers.formatToDate(this.site.cancelledOn) : '';
        } catch (error: any) {
            this.errorHandler.Server(error);
        } finally {
            this.loading = false;
        }
    }

    private async saveWebSite() {
        // @ts-ignore
        if (this.$refs.formProfile.validate()) {
            this.actionLoading = true;
            try {
                await this.axios.put<Site>(`/api/profile/UpdateShop/${this.site.id}`, this.site);

                this.setCacheObject(Object.assign({}, this.site));
                this.hasChanged = false;
            } catch (error: any) {
                this.errorHandler.Server(error);
            } finally {
                this.actionLoading = false;
            }
        }
    }

    private cancelChanges() {
        this.site = Object.assign({}, this.getCacheObject());
        this.hasChanged = false;
    }

    private changePlan() {
        this.showDialog = true;
    }

    private cancelPlanDialog() {
        this.showConfirmDialog = true;
    }

    private async cancelPlan() {
        this.confirmDialogLoading = true;

        try {
            await this.axios.post(`/api/braintree/CancelPayment`);

            this.$store.commit('showWarningSnackbar','Subscription canceled!')

            sessionStorage.clear();
            this.$router.push({
                name: 'login',
            });
        } catch (error: any) {
            this.errorHandler.Server(error);
        } finally {
            this.confirmDialogLoading = this.showConfirmDialog = false;
        }
    }

    private beforeRouteLeave(to: Route, _from: Route, next: any) {
        if (this.hasChanged) {
            next(false);
            this.newPath = to.name;
            this.showCancelChangesDialog = true;
        } else {
            next();
        }
    }

    @Watch('site', { deep: true })
    private onPropertyChanged(value: Site) {
        this.hasChanges(value);
    }

    @Watch('cancelChangesDialogResponse', { deep: true })
    private onResponseChanged(value: boolean, _oldValue: boolean) {
        if (value === true) {
            this.hasChanged = false;
            this.$router.push({
                name: this.newPath,
            });
        }
    }

    private async getTransactions(payment: any) {
        this.loadingTable = true;
        try {
            // @ts-ignore
            const currentDate: string = moment().format('YYYY-MM-DD');
            const response = await this.axios.get(`/api/braintree/GetTransactions?userId=${payment.brainTreeCustomerId}&subId=${payment.subscriptionId}&startDate=${payment.firstBillingDate}&endDate=${currentDate}`);

            this.transactions = response.data.data;
        } catch (error: any) {
            this.errorHandler.Server(error);
        } finally {
            this.loadingTable = false;
        }
    }

    private openInvoice(invoice: object) {
        this.invoice = invoice;
        this.showInvoiceDialog = true;
    }

    private changeCreditCard() {
        this.showCreditCartDialog = true;
    }

    private translatePricingPlan(planName: string) {
        return this.i18n.t(`website_settings.${planName.toLowerCase()}`);
    }

    private async print(invoice: object, type: string) {
        this.printLoading = true;
        try {
            let url = 'api/services/generateinvoice';
            if (type === 'usage') {
                url = 'api/services/generateinvoiceusagecharge';
            }
            // @ts-ignore
            const response = await this.axios.get(`${url}/${invoice.id}`, { responseType: 'blob'});
            const fileURL = URL.createObjectURL(new Blob([response.data]));
            // window.open(fileURL, '_blank');
            const link = document.createElement('a');
            link.href = fileURL;

            // @ts-ignore
            const fileName = `invoice-${this.$moment(this.invoice.createdAt).format('X')}.pdf`;
            link.setAttribute('download', fileName); // or any other extension
            document.body.appendChild(link);
            link.click();

        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.printLoading = false;
        }
    }
}
