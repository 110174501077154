
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';


@Component({})
export default class ToolbarCircle extends Vue {
    @Prop(Boolean) public visible!: boolean;
    private positionX: number = Math.floor(Math.random() * 90) + 10;
    private positionY: number = Math.floor(Math.random() * 90) + 10;
    private size: number = Math.floor(Math.random() * 20) + 15;
    private colors: string[] = [
        '#8488e3',
        '#3abc85',
        '#eca468',
        '#3a7fbc',
        '#bc3a5f',
        '#3abc93',
        '#ec7568',
        '#ecdd68',
    ];

    private color: number = Math.floor(Math.random() * this.colors.length) + 0;
    private animation: number = Math.floor(Math.random() * 9) + 1;
    private key = this.$vnode.key;
}
