
import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import { ErrorHandler } from '../../helpers/errorHandler';

@Component({})
export default class ConfirmEmail extends Vue {
    private inProgress: boolean = true;
    private show: boolean = true;
    private async created() {
        const userId = this.$route.query.userId;
        const code = this.$route.query.code;
        if (userId === undefined || code === undefined) {
            return;
        }

        const confirmEmail = {
            userId,
            code,
        };

        try {
            await axios.post(`api/account/confirmemail`, confirmEmail);
        } catch (error: any) {
            new ErrorHandler().Server(error);
        } finally {
            this.inProgress = false;
        }
    }

    private login() {
        this.$router.push({ name: 'login' });
    }
}

