
import {
    Component,
    Vue,
    Prop,
    Watch,
} from 'vue-property-decorator';
import { CustomField } from '../models/App';
import {
    Helpers,
} from '../helpers/helpers';
import draggable from 'vuedraggable';
import { ICampaign } from '../models/Campaign';
import AppIntegrations from '@/components/AppIntegrations.vue';
import Tooltip from '@/components/Tooltip.vue';
import i18n from '../plugins/i18n';
import { IntegrationType } from '@/helpers/enums';

@Component({
    components: {
        draggable,
        AppIntegrations,
        Tooltip
    },
})
export default class AppCustomFields extends Vue {
    @Prop(Boolean) public hasChanged!: boolean;
    @Prop(Array) public customFields!: CustomField[];
    @Prop(Boolean) public integrationsMailEnabled!: boolean;
    @Prop(Boolean) public integrationsSMSEnabled!: boolean;
    @Prop(Object) public campaign!: ICampaign;
    private showWarningDialog: boolean = false;
    private showWarningTooltip: boolean = false;
    private dialog: boolean = false;
    private dialogCreateNew: boolean = false;
    private helpers = new Helpers();
    private error: boolean = false;
    private types: string[] = ['text', 'checkbox'];
    private rules: any = {
        required: (value) => !!value || i18n.t('validation.required'),
        unique: (value) => (!!value && this.checkFieldId(value)) || i18n.t('validation.unique')
    };
    private newField:  CustomField = {
        id: '',
        name: '',
        required: false,
        main: false,
        icon: '',
        type: 'text'
    };

    private items: CustomField[] = [
        {
            icon: 'email',
            id: 'email',
            name: 'Email',
            required: true,
            main: true,
            type: 'text'
        },
        {
            icon: 'phone',
            id: 'phoneNumber',
            name: 'Phone number',
            required: false,
            main: false,
            type: 'text'
        },
        {
            icon: 'title',
            id: 'firstName',
            name: 'First Name',
            required: false,
            main: false,
            type: 'text'
        },
        {
            icon: 'title',
            id: 'lastName',
            name: 'Last Name',
            required: false,
            main: false,
            type: 'text'
        },
        {
            icon: 'location_on',
            id: 'address',
            name: 'Address',
            required: false,
            main: false,
            type: 'text'
        },
    ];

    private created() {
        this.showWarningTooltip = this.checkWarningTooltip;
        this.showWarningDialog = this.checkWarningDialog;
    }

    private addNewField(id: string) {
        this.items.forEach(field => {
            if (field.id === id) {
                if (this.customFields.find((fld: CustomField) => fld.id === field.id) === undefined) {                  
                    this.customFields.push(field);                    
                }

                const emailField = this.customFields.find((fld: CustomField) => fld.id === 'email');
                const phoneNumber = this.customFields.find((fld: CustomField) => fld.id === 'phoneNumber');

                if (this.integrationsMailEnabled && !this.klaviyoEnabled) {
                    emailField!.main = true;
                    emailField!.required = true;
                }
                if (this.integrationsSMSEnabled && !this.klaviyoEnabled) {
                    phoneNumber!.main = true;
                    phoneNumber!.required = true;                        
                }
                if (!this.integrationsMailEnabled && !this.integrationsSMSEnabled) {
                    emailField!.main = phoneNumber!.main = false;                        
                }
                // klaviyo exception to allow only phone field
                if (this.klaviyoEnabled && emailField && phoneNumber){
                    emailField!.main = phoneNumber!.main = false;    
                }

                if (field.id === 'phoneNumber') {
                    this.campaign.app!.tos.find((t) => t.type === 'sms')!.enabled = true;
                }
            }
        });
        // if phone number is mandatory and active emails shouldnt be
        // check default settings for email items
        // data from integration isnt loaded until tab is opened
        this.dialog = false;
    }

    private createField() {
        // @ts-ignore
        if (this.$refs.formField.validate()) {
            this.newField.id = this.newField.id.replace(/\s/g,'');
            this.customFields.push(JSON.parse(JSON.stringify(this.newField)));
            this.newField.id = this.newField.name = '';
            this.newField.required = false;

            this.dialogCreateNew = false;
        }
    }

    private removeItem (id: string) {
        const customFields = this.customFields.filter((item) => {
            return item.id !== id;
        });
   
        if (id === 'email') {
            this.customFields.find((element: CustomField) => element.id === 'phoneNumber')!.main = true;
            this.customFields.find((element: CustomField) => element.id === 'phoneNumber')!.required = true;
        } else if (id === 'phoneNumber') {            
            this.campaign.app!.tos.find((t) => t.type === 'sms')!.enabled = false;
            this.customFields.find((element: CustomField) => element.id === 'email')!.main = true;
            this.customFields.find((element: CustomField) => element.id === 'email')!.required = true;
        }
        this.$emit('update:customFields', customFields);
        this.$emit('update:hasChanged', true);
    }

    private close() {
        this.dialog = false;
    }

    private availableItems(items1: CustomField[], items2: CustomField[]) {
        // return difference between two arrays
        return items2.filter((obj) => {
            return !items1.some((obj2) => {
                return obj.id === obj2.id;
            });
        });
    }

    @Watch('integrationsSMSEnabled', {
        deep: true,
    })
    private onSMSEnabledChange(){
        this.onIntegrationsChange()
    }

    @Watch('integrationsMailEnabled', {
        deep: true,
    })
    private onMailEnabledChange(){
        this.onIntegrationsChange()
    }
    
    @Watch('customFields', {
        deep: true,
    })
    private onCustomFieldsChange() {
        this.showWarningTooltip = this.checkWarningTooltip;
        this.showWarningDialog = this.checkWarningDialog;
    }

    private onIntegrationsChange() {        
        if (this.integrationsMailEnabled && !this.klaviyoEnabled) {
            this.addNewField('email');
        }

        if (this.integrationsSMSEnabled) {
            this.addNewField('phoneNumber');
        }

        const phoneField: CustomField = this.customFields.find((element: CustomField) => element.id === 'phoneNumber')!;
        const emailField: CustomField = this.customFields.find((element: CustomField) => element.id === 'email')!;

        if (!this.integrationsMailEnabled) {
            if (emailField !== undefined && phoneField !== undefined) {
                emailField.main = false;
            }
        }

        if (!this.integrationsSMSEnabled && !this.klaviyoEnabled) {
            if (phoneField !== undefined && emailField !== undefined) {
                phoneField.main = false;
            }            
        }
    }

    private checkFieldId(value: string): boolean {
        return this.customFields.findIndex((field) => field.id === value) === -1
    }

    get hasEmailAndPhoneField(): boolean {
        let hasEmail = false;
        let hasPhone  = false;
        this.customFields.forEach((el) => {
            if (el.id === 'email') {
                hasEmail = true;
            }
            if (el.id === 'phoneNumber') {
                hasPhone = true;
            }
        });
        return hasEmail && hasPhone;
    }

    private closeWarning() {
        sessionStorage.setItem('closeEmailPhoneWarning', 'true');
        this.showWarningDialog = false;
        this.showWarningTooltip = true;
    }

    get checkWarningTooltip(): boolean {
       let isCLosed = false;
       if (sessionStorage.getItem('closeEmailPhoneWarning')) {
           isCLosed = true;
       }
       return isCLosed && this.hasEmailAndPhoneField;
    }

    get checkWarningDialog(): boolean {
        let isCLosed = false;
        if (sessionStorage.getItem('closeEmailPhoneWarning')) {
            isCLosed = true;
        }
        return !isCLosed && this.hasEmailAndPhoneField;
    }

    get klaviyoEnabled(): boolean {
        return this.integrationsMailEnabled && this.campaign.integrations?.find((int) => int.type === IntegrationType.Klaviyo) != null;
    }

    get checkRequiredFields(): boolean {
        return this.customFields.find((fld) => fld.required) !== undefined;
    }
}
