

// before you will make any import
Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate',
]);

import { Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import UnsavedChangesDialog from '@/components/dialogs/UnsavedChangesDialog.vue';
import { Route } from 'vue-router';
import { UserProfile, Culture } from '../models/UserProfile';
import { BaseClass } from '../models/baseClass';

@Component({
    components: {
        UnsavedChangesDialog,
    },
})
export default class Profile extends BaseClass<UserProfile> {
    private showDialog: boolean = false;
    private userProfile: UserProfile = {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        userName: '',
        companyName: '',
        country: '',
        stateProvince: '',
        address: '',
        additionalAddress: '',
        city: '',
        phoneNumber: '',
        cultureName: '',
        timeZone: ''
    };

    private currentCulture: Culture = {
        name: '',
        englishName: '',
        nativeName: '',
    };
    private cultures: Culture[] = [];
    private countries: [] = [];

    private async created() {
        await this.getProfile();
    }

    private async getProfile() {
        try {
            const response = await this.axios.get('/api/profile/getprofile');

            this.userProfile = response.data.data;
            this.cultures =  this.userProfile.cultures || [];
            this.countries = this.userProfile.countries || [];

            this.setCacheObject(Object.assign({}, this.userProfile));
            this.currentCulture = this.cultures.findWith('name', this.userProfile.cultureName);

        } catch (error: any) {
           this.errorHandler.Server(error);
        } finally {
            this.loading = false;
        }
    }

    private async saveProfile() {
        this.actionLoading = true;
        try {
            const profile = Object.assign({}, this.userProfile);
            // @ts-ignore
            delete profile.cultures;
            // @ts-ignore
            delete profile.countries;
            // @ts-ignore
            this.$moment.locale(this.userProfile.cultureName);
            sessionStorage.setItem('locale', this.userProfile.cultureName);

            await this.axios.put<UserProfile>(`/api/profile/UpdateProfile/${this.userProfile.id}`, profile);

            this.setCacheObject(Object.assign({}, this.userProfile));
            this.hasChanged = false;
        } catch (error: any) {
            this.errorHandler.Server(error);
        } finally {
            this.actionLoading = false;
        }
    }

    private cancelChanges() {
        this.userProfile = Object.assign({}, this.getCacheObject());
        this.currentCulture = (this.userProfile.cultures as Culture[]).findWith('name', this.userProfile.cultureName);
        this.hasChanged = false;
    }

    @Watch('userProfile', { deep: true })
    private onPropertyChanged(value: UserProfile) {
        this.hasChanges(value);
    }

    @Watch('cancelChangesDialogResponse', {deep: true})
    private onResponseChanged(value: boolean) {
        if (value === true) {
            this.hasChanged = false;
            this.$router.push({
                name: this.newPath,
            });
        }
    }

    private beforeRouteLeave(to: Route, _from: Route, next: any) {
        if (this.hasChanged) {
            next(false);
            this.newPath = to.name;
            this.showCancelChangesDialog = true;
        } else {
            next();
        }
    }

    private cultureChanged(value: string) {
        this.userProfile.cultureName = value;
    }

    private selectText(culture: Culture) {
        return `${culture.englishName} (${culture.nativeName})`;
    }

    private filterCultures(item, queryText) {
        const textOne = item.englishName.toLowerCase();
        const textTwo = item.name.toLowerCase();
        const searchText = queryText.toLowerCase();
        return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    }

    private filterCountries(item, queryText) {
        const textOne = item.toLowerCase();
        const searchText = queryText.toLowerCase();
        return textOne.indexOf(searchText) > -1;
    }
}

