
import {
    Component,
    Vue,
    Prop
} from 'vue-property-decorator';
import {
    Trans
} from '../plugins/translation';
import i18n from '../plugins/i18n';
import ToolbarCircle from '@/components/ToolbarCircle.vue';
import {
    ICampaign
} from '../models/Campaign';
import {
    ScheduleType,
    AppType,
} from '../helpers/enums';
import { AppStats } from '../models/AppStats';
@Component({
    components: {
        ToolbarCircle,
    },
})
export default class DummyApp extends Vue {
    @Prop(Array) public menuItems!: object[];
    private show: boolean = false;
    private clipped: boolean = true;
    private drawer: boolean = true;
    private miniVariant: boolean = false;
    private right: boolean = true;
    private title: string = '';
    private hidden: boolean = false;

    private getSnackbar() {
        return this.$store.state.snackbar
    }

    private closeSnackbar() {
        this.$store.state.snackbar.showing = false;
    }
}
