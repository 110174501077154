import Vue from 'vue';
import Router from 'vue-router';
import Login from './views/new-flow/Login2.vue';
import Register from './views/new-flow/Register2.vue';
import ForgotPassword from './views/new-flow/ForgotPassword2.vue';
import ActivateAccount from './views/new-flow/ActivateAccount2.vue';
import ResetPassword from './views/new-flow/ResetPassword2.vue';
import ConfirmEmail from './views/new-flow/ConfirmEmail2.vue';
import Profile from './views/Profile.vue';
import WebSite from './views/WebSite.vue';
import ActivateSubscription from './views/new-flow/ActivateSubscription2.vue';
import Integrations from './views/Integrations.vue';
import CampaignList from './views/CampaignList.vue';
import Campaign from './views/Campaign.vue';
import Setup from './views/Setup.vue';
import Report from './views/Report.vue';
import KnowledgeBase from './views/KnowledgeBase.vue';
import PrivacyPolicy from './views/PrivacyPolicy.vue';
import Admin from './views/Admin.vue';

Vue.use(Router);

// https://github.com/vuejs/vue-router/blob/dev/examples/scroll-behavior/app.js
const scrollBehavior = (to, _from, _savedPosition) =>  {
  const position = {
    selector: '',
    offset: {
      x: 0,
      y: 0,
    },
  };

  if (to.hash) {
    position.selector  = to.hash;
    position.offset.y = 50;

    if (document.querySelector(to.hash)) {
      return position;
    }
  }
  return position.offset;
};

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: {
        requiresAuth: true,
        type: ['Standalone'],
      },
    },
    {
      path: '/website',
      name: 'website',
      component: WebSite,
      meta: {
        requiresAuth: true,
        type: ['Standalone'],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        guest: true,
        type: ['Standalone'],
      },
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
      meta: {
        guest: true,
        type: ['Standalone'],
      },
    },
    {
      path: '/forgotpassword',
      name: 'forgotpassword',
      component: ForgotPassword,
      meta: {
        guest: true,
        type: ['Standalone'],
      },
    },
    {
      path: '/activateAccount',
      name: 'activateAccount',
      component: ActivateAccount,
      meta: {
        guest: true,
        type: ['Standalone'],
      },
    },
    {
      path: '/resetpassword',
      name: 'resetpassword',
      component: ResetPassword,
      meta: {
        guest: true,
        type: ['Standalone'],
      },
      props: (route) => ({ query: route.query.code }),
    },
    {
      path: '/confirmemail',
      name: 'confirmemail',
      component: ConfirmEmail,
      meta: {
        guest: true,
        type: ['Standalone'],
      },
      props: (route) => ({
        userId: route.query.userId,
        code: route.query.code,
      }),
    },
    {
      path: '/activatesubscription/:type',
      name: 'activatesubscription',
      component: ActivateSubscription,
      meta: {
        requiresAuth: true,
        type: ['Standalone'],
      },
    },
    {
      path: '/integration',
      name: 'integration',
      component: Integrations,
      meta: {
        requiresAuth: true,
        type: ['Standalone', 'Shopify'],
      },
    },
    {
      path: '/campaignlist',
      name: 'campaignlist',
      component: CampaignList,
      meta: {
        requiresAuth: true,
        type: ['Standalone', 'Shopify'],
      },
    },
    {
      path: '/campaign/:id/:shopId?',
      name: 'campaign',
      component: Campaign,
      meta: {
        requiresAuth: true,
        type: ['Standalone', 'Shopify'],
      },
    },
    {
      path: '/setup',
      name: 'setup',
      component: Setup,
      meta: {
        requiresAuth: true,
        type: ['Standalone'],
      },
    },
    {
      path: '/reports',
      name: 'reports',
      component: Report,
      meta: {
        requiresAuth: true,
        type: ['Standalone', 'Shopify'],
      },
    },
    {
      path: '/knowledgebase',
      name: 'knowledgebase',
      component: KnowledgeBase,
      meta: {
        requiresAuth: true,
        type: ['Standalone', 'Shopify'],
      },
    },
    {
      path: '/privacypolicy',
      name: 'privacypolicy',
      component: PrivacyPolicy,
      meta: {
        requiresAuth: false,
        type: ['Standalone', 'Shopify'],
      },
    },
    {
      path: '/admin/:id?',
      name: 'admin',
      component: Admin,
      meta: {
        requiresAuth: true,
        type: ['Standalone'],
      },
    },
    { path: '*', redirect: '/campaignlist' },
  ],
  scrollBehavior,
});

router.beforeEach((to, _from, next) => {
  // check for shop type allowed routes
  if (sessionStorage.type && sessionStorage.type === 'Shopify' && to.matched.some((record) => record.meta.type.indexOf(sessionStorage.type) === -1)) {
    next({
      path: '/campaignlist',
      params: {nextUrl : to.fullPath },
    });
  } else {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (sessionStorage.getItem('token') == null) {
          next({
            path: '/login',
            params: {nextUrl : to.fullPath },
          });
        } else {
          next();
        }
    } else {
      // if (sessionStorage.getItem('token') != null) {
      //   next({
      //     path: '/website',
      //     params: {nextUrl : to.fullPath },
      //   });
      // } else {
        next();
      // }
    }
  }
});
export default router;
