
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Helpers} from '../helpers/helpers';
import { EmailSegmentation } from '../models/EmailSegmentation';
import { UrlFilterOperator, UrlFilterRule } from '../helpers/enums';
import i18n from '../plugins/i18n';
import Tooltip from '@/components/Tooltip.vue';

@Component({
    components: {
        Tooltip
    }
})
export default class AppEmailSegmentation extends Vue {
    @Prop(String) public domainUrl!: string;
    @Prop(Array) public appEmailSegmentations!: EmailSegmentation[];
    @Prop(Boolean) public freeSpin!: boolean;
    private helpers = new Helpers();
    private segmentation: EmailSegmentation = {
        id: -1,
        operator: UrlFilterOperator.Does,
        rule: UrlFilterRule.Match,
        text: '',
        name : '',
    };
    private operators: object[] = [];
    private rules: object[] = [];

    private created() {
        for (const item in UrlFilterOperator) {
            if (isNaN(Number(item))) {
                const operator = {
                    value: item,
                    text: i18n.t('campaign.url_filters.operators.'+item),
                };
                this.operators.push(operator);
            }
        }

        for (const item in UrlFilterRule) {
            if (isNaN(Number(item))) {
                const rule = {
                    value: item,
                    text: i18n.t('campaign.url_filters.rules.'+item),
                };
                this.rules.push(rule);
            }
        }
    }

    private addSegmentation(){
        const obj = {
            id: this.appEmailSegmentations.length,
            operator: UrlFilterOperator.Does,
            rule: UrlFilterRule.Contains,
            text: '',
            name: '',
        };

        this.appEmailSegmentations.push(obj);
        this.$emit('update:hasChanged', true);
        // this.$emit('update:appEmailSegmentations', this.appEmailSegmentations);
    }

    private removeItem(id: number) {
        const segmentations = this.appEmailSegmentations.filter((item) => {
            return item.id !== id;
        });
        this.$emit('update:appEmailSegmentations', segmentations);
        this.$emit('update:hasChanged', true);
    }

    private preventSlash(e) {
        if (e.key === '/') {
            e.preventDefault();
        }
    }

    private ruleChange(id, rule) {
        if (rule === UrlFilterRule.BeginWith) {
            this.appEmailSegmentations[id].text = this.appEmailSegmentations[id].text.split('/')[0];
        } else if (rule === UrlFilterRule.EndWith) {
            this.appEmailSegmentations[id].text = this.appEmailSegmentations[id].text.split('/')[this.appEmailSegmentations[id].text.split('/').length - 1];
        }
    }
}
