import moment, { Moment } from 'moment-timezone';
import Differify, { DIFF_MODES } from '@netilon/differify';
import { AppType } from '../helpers/enums';
import timezones from 'timezones-list';
import { TimeZone } from '@/models/Campaign';

export class Helpers {
    public LocalDateFormat: string = moment.localeData().longDateFormat('L');
    private differify = new Differify({ mode: { object: DIFF_MODES.DIFF, array: DIFF_MODES.DIFF } });

    public isEqual(value: any, other: any): boolean {
        const diff = this.differify.compare(value, other);

        // check file uploads on campaigns and ignore changes
        if (this.campaignIgnoreFileUploads(diff)) {
            // console.log("is equal 1")
            return true;
        }
        // console.log(diff.changes)
        if (diff.changes > 0) {
            // console.log("is not equal")
            return false;
        }
        // console.log("is equal 2")
        return true;
    }

    public formatToDate(date) {
        // @ts-ignore
        return date != null ? moment(date).format('L') : '';
    }

    public formatToDateTime(date) {
        // @ts-ignore
        return date != null ? moment(date).format('L LT') : '';
    }

    public formatToTimeZone(date: Date | undefined, timeZone: string) {
        return moment.tz(date, timeZone);
    }

    public formatToUtc(date: string, timeZone: string): Moment {
        return moment.tz(date, timeZone).utc();
    }

    private campaignIgnoreFileUploads(diff: any) {
        if (diff && diff.changes > 0 && diff._.hasOwnProperty('app') && diff._.app.status === 'MODIFIED') {
            const ogChanges = diff.changes
            if (diff._.app._.smallLogo.status === 'MODIFIED') {
                diff.changes = diff.changes-1;
            }
            if (diff._.app._.bigLogo.status === 'MODIFIED') {
                diff.changes = diff.changes-1;
            }
            return ogChanges < diff.changes
        }
        return false;
    }

    private mustBeNumber(e) {
        if (e.keyCode === 46 || e.keyCode === 8) {
            return;
        }
        if (!Number.isInteger(parseInt(e.key, 10))) {
            e.preventDefault();
        }
    }

    private preventEmpty(val, defaultVal?) {
        if(val.length < 1 || val === '' || val === null) {
            return defaultVal ? defaultVal : 0
        }
        return val;
    }

    public isWheelio(appType: string) {
        return appType === AppType.Wheelio || appType === AppType.Wheelio2;
    }
    public isWheelio2(appType: string) {
        return appType === AppType.Wheelio2;
    }
    public isRollie(appType: string) {
        return appType === AppType.Rollie;
    }

    public isScratchy(appType: string) {
        return appType === AppType.Scratchy;
    }

    public increaseColorBrightness(hex, percent) {
        // strip the leading # if it's there
        hex = hex.replace(/^\s*#|\s*$/g, '');

        // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
        if (hex.length === 3) {
            hex = hex.replace(/(.)/g, '$1$1');
        }

        const r = parseInt(hex.substr(0, 2), 16);
        const g = parseInt(hex.substr(2, 2), 16);
        const b = parseInt(hex.substr(4, 2), 16);

        /* tslint:disable:no-bitwise */
        return '#' +((0 | (1 << 8) + r + (256 - r) * percent / 100).toString(16)).substr(1) + ((0 | (1 << 8) + g + (256 - g) * percent / 100).toString(16)).substr(1) + ((0 | (1 << 8) + b + (256 - b) * percent / 100).toString(16)).substr(1);
        /* tslint:enable:no-bitwise */
    }

    public getAllTimezones() {
        const allTimeZones: TimeZone[] = [];
        timezones.forEach((zone) => {
            allTimeZones.push({ text: zone.name, value: zone.tzCode });
        });
        return allTimeZones;
    }
}

