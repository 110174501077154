
import { Component, Vue, Prop  } from 'vue-property-decorator';
import { Helpers} from '../helpers/helpers';
import { Texts } from '../models/App';
import Tooltip from '@/components/Tooltip.vue';
import RichEditor from '@/components/RichEditor.vue';

@Component({
    components: {
        Tooltip,
        RichEditor
    }
})
export default class AppTexts extends Vue {
    @Prop(Boolean) public hasChanged!: boolean;
    @Prop(Object) public appTexts!: Texts;
    @Prop(String) public appType!: string;
    @Prop(Boolean) public freeSpin!: boolean;

    private helpers = new Helpers();
}
