
import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import { ResetPass } from '../../models/ResetPass';
import { ErrorHandler } from '../../helpers/errorHandler';
import i18n from '../../plugins/i18n';

@Component({})
export default class ResetPassword extends Vue {
    private resetPass: ResetPass = {
        email: '',
        password: '',
        confirmPassword: '',
        code: '',
    };
    private show: boolean = true;
    private actionLoading: boolean = false;
    private resetFinished: boolean = false;
    private showCurrentPassword: boolean = false;
    private showNewPassword: boolean = false;
    private error: boolean = false;
    private rules: any = {
        required: (value) => !!value || i18n.t('validation.required'),
    };

    private async reset() {
        // @ts-ignore
        if (this.$refs.form.validate()) {
            this.actionLoading = true;
            try {
                await axios.post(`/api/account/resetpassword`, this.resetPass);
                this.resetFinished = true;
            } catch (error: any) {
                new ErrorHandler().Server(error);
                this.resetFinished = false;
            } finally {
                this.actionLoading = false;
            }
        }
    }

    private created() {
        this.resetPass.code = this.$route.query.code.toString();
    }

    private login() {
        this.$router.push({ name: 'login' });
    }
}

