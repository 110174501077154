
import {
    Component,
    Vue,
    Prop,
    Watch
} from 'vue-property-decorator';
import { CustomBar } from '../models/App';
import { Helpers } from '../helpers/helpers';
@Component({})
export default class ProgressBar extends Vue {
    @Prop(Object) public appCustomBar!: CustomBar;
    @Prop(String) public appType!: string;
    private helpers = new Helpers();

    get appCustomBarTextRender() {
        return this.appCustomBar.text.replace('{{Percentage}}', this.appCustomBar.value+'%')
    }
}
